import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import React, { Component } from "react";
import { FormRenderProps } from "react-final-form";
import ACLookup from "../../../components/shared/ACFormFields/ACLookup";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import { RAFLayout } from "../../../constants/Common/Constants";
import {
  IDialogProps,
  isNotNullAndUndefined,
  RAFFormComponentProps,
} from "../../../RAFComponents/helpers/utils";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import { NavigateParamsProps } from "../../../router";
import CreateState from "../State/CreateState";
import { StateRow } from "../State/StateRow";
import { CityRow } from "./CityRow";

interface IState {
  isActive?: boolean;
}
class CreateCity extends Component<
  IDialogProps & RAFFormComponentProps & NavigateParamsProps,
  IState
> {
  public rafForm: FormRenderProps | null;
  public submitButton: ButtonComponent;

  constructor(props) {
    super(props);
    this.state = {
      isActive: isNotNullAndUndefined(props.isActive) ? props.isActive : false,
    };
  }

  onSaveCity = (entityId, objectName) => {
    if (this.props.onSave) this.props.onSave(entityId, objectName);
  };

  enableSubmitButton = (g: FormRenderProps | null) => {
    if (g) {
      if (isNotNullAndUndefined(this.submitButton)) {
        this.submitButton.disabled = g.submitting;
      }
    }
  };

  enableCreate = (isActive) => {
    this.setState({ isActive: isActive });
  };

  render() {
    let cityRow: CityRow = new CityRow();
    if (isNotNullAndUndefined(this.props.initialValues)) {
      cityRow = this.props.initialValues as CityRow;
    }

    let isActive = this.state.isActive;
    cityRow.IsActive = true;

    return (
      isActive && (
        <div>
          <RAFForm
            type={CityRow}
            initialValues={cityRow}
            formRef={(g) => {
              this.enableSubmitButton(g);
              return (this.rafForm = g);
            }}
            layout={RAFLayout.TwoColumnLayout}
            onSave={this.onSaveCity}
          >
            <div className="e-dlg-body-content">
              <ACTextBox<CityRow>
                field="CityName"
                label="City Name"
                required
                placeholder="City Name"
              />
              <ACLookup<CityRow>
                field="State"
                label="State"
                placeholder="Select State"
                type={StateRow}
                url="State/Lookup"
                createform={CreateState}
                createformdefaultfield={"StateName"}
              />
            </div>
            <div className="e-dlg-footerContent ">
              <ButtonComponent
                id="btnCreateCitySave"
                type="button"
                className="me-2"
                cssClass="e-flat e-info"
                ref={(g) => (this.submitButton = g)}
                disabled={this.rafForm && this.rafForm.submitting}
                onClick={() => {
                  this.rafForm && this.rafForm.form.submit();
                }}
              >
                Save
              </ButtonComponent>
              <ButtonComponent
                id="btnCreateCityCancel"
                type="button"
                cssClass="e-flat"
                onClick={this.props.onClose}
              >
                Cancel
              </ButtonComponent>
            </div>
          </RAFForm>
        </div>
      )
    );
  }
}

export default CreateCity;
