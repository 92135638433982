import { DialogUtility } from "@syncfusion/ej2-react-popups";
import { Component } from "react";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import RAFEmptyState from "../../../../RAFComponents/Navigation/RAFEmptyState";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  getAllUsers,
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  getSaveRequest,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import { ActivityListServiceRequest } from "../../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import { ContentType } from "../../../../constants/Common/Constants";
import { PermissionConstants } from "../../../../constants/Common/PermissionConstants";
import { getHeaderHeight } from "../../../../helpers/ACutils";
import { RAFActivityAction } from "../ActivityHelper";
import { ConvertActivityToActivityDto } from "../ActivityMappingRegister";
import { ActivityRow, IActivityDto } from "../ActivityRow";
import ActivityView from "../ActivityView";

interface IProps {
  relatedToUID?: string;
  entityName?: string;
  showRelatedTo?: boolean;
  showEmptyState?: boolean;
  showEmptyStateIcon?: boolean;
    displayStyle?: 'ImageOnly' | 'TextOnly' | 'Default';
  secondaryRelatedTo?: string;
  secondaryRelatedToUID?: string;
  secondaryRelatedToType?: string;
  modulesOnclick?: (rowData: Object) => void;
  relatedCommentsUpdated?: (isRelatedCommentsUpdated?: boolean) => void;
  activityItemClassname?: string;
  excludeRelatedFilter?: boolean;
  uiMode?: "table";
  showSecondaryRelatedTo?: boolean;
}

class ActivitySummary extends Component<IProps> {
  state = {
    activities: [],
    activity: {},
    usersList: [],
    activitiesItemsDivKey: Math.random(),
  };

  private deleteDialog: any;

  _isMounted = false;

  componentDidMount = () => {
    this._isMounted = true;
    this.FetchData();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  EditActivity = (activity: ActivityRow) => {
    let activityToCreate: ActivityRow = activity as ActivityRow;
    let activityToCreateto: IActivityDto =
      ConvertActivityToActivityDto(activityToCreate);
    //let progressDiv = showProgress('.activitiesItems');
    let progressDiv = showProgress("body", true);

    activityToCreateto.Action = RAFActivityAction.Modified;

    repositoryActions
      .postDataAndGetResponse(
        "Activity/Save",
        //"Activity/SaveStream",
        getSaveRequest(activityToCreateto, activityToCreateto.UID),
        { ...this.props },
        ContentType.applicationJson
      )
      .then((response) => {
        hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          if (isNotNullAndUndefined(response.data.EntityId)) {
            showSuccessToast("Comment updated", "Success!");
            if (this.props.relatedCommentsUpdated) {
              this.props.relatedCommentsUpdated(true);
            }
            this.FetchData();
            let editDiv = document.querySelector(
              "#editableActivity_" + activity.UID + "" + this.props.relatedToUID
            );
            let summaryDiv = document.querySelector(
              "#summaryActivity_" + activity.UID + "" + this.props.relatedToUID
            );
            let editDeletebtn = document.querySelector(
              "#editDeletebtn_" + activity.UID + "" + this.props.relatedToUID
            );
            if (
              isNotNullAndUndefined(editDiv) &&
              isNotNullAndUndefined(summaryDiv)
            ) {
              editDiv.classList.add("hidden");
              summaryDiv.classList.remove("hidden");
              editDeletebtn.classList.remove("hidden");
            } else {
              editDiv.classList.remove("hidden");
              summaryDiv.classList.add("hidden");
            }
          } else if (
            response.data.Error !== null &&
            response.data.Error !== undefined &&
            response.data.Error !== ""
          ) {
            showWarningToast("Sorry something went wrong !");
          }
        }
      })
      .catch((error) => error);
  };

  DeleteClicked = (id) => {
    if (this._isMounted) {
      this.setState({ hideEditDialog: true });
    }
    this.deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: this.DeleteActivity.bind(this, id) },
      title: " Delete Comment",
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
    });
  };

  DeleteActivity = (id) => {
    let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
    repositoryActions
      .postDataAndGetResponse(
        "Activity/Delete",
        { EntityId: id },
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        hideProgress(progressDiv);
        if (this.props.relatedCommentsUpdated) {
          this.props.relatedCommentsUpdated(true);
        }
        if (isNotNullAndUndefined(response)) {
          this.FetchData();
          this.deleteDialog.hide();
        }
      })
      .catch((error) => error);
  };

  getHeaderHeight = () => {
    getHeaderHeight();
  };

  FetchData() {
    let {
      secondaryRelatedToUID,
      relatedToUID,
      entityName,
      secondaryRelatedToType,
    } = this.props;
    let progressDiv = showProgress(".activitiesItems");
    let url = "Activity/List";

    const listRequest: ActivityListServiceRequest =
      new ActivityListServiceRequest();

    let excludeRelatedFilter = isNotNullAndUndefined(
      this.props.excludeRelatedFilter
    )
      ? true
      : false;

    if (excludeRelatedFilter === false) {
      if (isNotNullAndUndefined(relatedToUID)) {
        listRequest.RelatedToUID = relatedToUID;
      }
      if (isNotNullAndUndefined(entityName)) {
        listRequest.EntityName = entityName;
      }
    }

    if (isNotNullAndUndefined(secondaryRelatedToUID)) {
      listRequest.SecondaryRelatedToUID = secondaryRelatedToUID;
    }

    if (isNotNullAndUndefined(secondaryRelatedToType)) {
      listRequest.SecondaryRelatedToType = secondaryRelatedToType;
    }

    let relatedFilter: RAFCustomFilter = {};
    relatedFilter.Condition = "and";
    relatedFilter.Rules = [];

    let filter1: RAFCustomFilter = {};
    filter1.Operator = RAFCustomOperator.IsNull;
    filter1.Field = propertyOf<ActivityRow>("ParentUID");
    relatedFilter.Rules.push(filter1);

    let filter2: RAFCustomFilter = {};
    let filter2Val: string[] = [];
    filter2.Operator = RAFCustomOperator.NotEqual;
    filter2Val.push("Alert");
    filter2.Value = filter2Val;
    filter2.Field = propertyOf<ActivityRow>("CommentType");
    relatedFilter.Rules.push(filter2);

    listRequest["CustomFilter"] = relatedFilter;

    let sortByDate = {
      field: propertyOf<ActivityRow>("ModifiedDate"),
      order: "descending",
    };
    let sortQuery: string[] = [];
    if (
      isNotNullAndUndefined(sortByDate) &&
      isNotNullAndUndefined(sortByDate.field)
    ) {
      sortQuery.push(
        sortByDate.order === "descending"
          ? `${sortByDate.field} desc`
          : sortByDate.field
      );
    }

    listRequest.Sort = sortQuery;

    getAllUsers()
      .then((usersList) => {
        repositoryActions
          .postDataAndGetResponse(
            url,
            listRequest,
            null,
            ContentType.applicationJson
          )
          .then((response) => {
            hideProgress(progressDiv);
            this.getHeaderHeight();
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data)
            ) {
              if (this._isMounted) {
                this.setState({
                  activities: response.data.Entities,
                  usersList: usersList,
                  activitiesItemsDivKey: Math.random(),
                });
              }
            } else {
              if (this._isMounted) {
                this.setState({
                  activities: [],
                  usersList: usersList,
                  activitiesItemsDivKey: Math.random(),
                });
              }
            }
          })
          .catch((error) => error);
      })
      .catch((error) => error);
  }

  moduleNameClick = (moduleUID) => {
    if (isNotNullAndUndefined(moduleUID)) {
      if (this.props.modulesOnclick) {
        this.props.modulesOnclick(moduleUID);
      }
    }
  };

  render() {
    let { activities, usersList, activitiesItemsDivKey } = this.state;

    let uiMode = this.props.uiMode;

    let activitiesRows: Array<ActivityRow> = new Array<ActivityRow>();

    if (isNotNullAndUndefined(activities) && activities.length > 0) {
      activities.forEach((activity) => {
        let objRow = usersList.find((x) => x.UID === activity.UserUID);
        if (isNotNullAndUndefined(objRow)) {
          activity.UserName = objRow && objRow.Value;
          activitiesRows.push(activity);
        }
      });
    }

    return (
      <div>
        <ul
          className={
            isNotNullAndUndefined(uiMode) && uiMode === "table"
              ? "activitiesItems activitiesTable"
              : "activitiesItems"
          }
          key={activitiesItemsDivKey}
        >
          <RolePermissionsContext.Consumer>
            {({ permissionValue }) => {
              return (
                <RAFPermissionRender
                  permissionName={PermissionConstants.CommentsRead}
                >
                  {/* {activityList} */}
                  {isNotNullAndUndefined(activitiesRows) &&
                  activitiesRows.length > 0
                    ? activitiesRows.map((item, index) => {
                        return (
                          <ActivityView
                            key={index}
                            activity={item}
                            editActivity={(activityRow) =>
                              this.EditActivity(activityRow)
                            }
                            isEidtable
                            deleteActivity={(id) => this.DeleteClicked(id)}
                            cssClassname={this.props.activityItemClassname}
                            uiMode={this.props.uiMode}
                            secondaryRelatedToUID={item.SecondaryRelatedToUID}
                            secondaryRelatedTo={item.SecondaryRelatedTo}
                            secondaryRelatedToType={item.SecondaryRelatedToType}
                            {...(isNotNullAndUndefined(
                              item.SecondaryRelatedToType
                            ) &&
                              isNotNullAndUndefined(
                                item.SecondaryRelatedToUID
                              ) &&
                              isNotNullAndUndefined(
                                item.SecondaryRelatedTo
                              ) && {
                                showSecondaryRelatedTo:
                                  this.props.showSecondaryRelatedTo,
                              })}
                            permissionValue={permissionValue}
                          ></ActivityView>
                        );
                      })
                    : this.props.showEmptyState && (
                      <div className="pb-3">
                          <RAFEmptyState
                            title="You don’t have any notes"
                            displayStyle={this.props.displayStyle}
                            iconClass={
                              this.props.showEmptyStateIcon && "fa fa-Comments"
                            }
                          />
                          </div>
                      )}
                </RAFPermissionRender>
              );
            }}
          </RolePermissionsContext.Consumer>
        </ul>
      </div>
    );
  }
}
export default ActivitySummary;
