import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { Component } from "react";
import { FormRenderProps } from "react-final-form";
import { msalInstance } from "../../..";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFTextArea from "../../../RAFComponents/Inputs/RAFTextArea";
import { showSuccessToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  Guid,
  IDialogProps,
  IsNullOrWhiteSpace,
  deepEqual,
  getSaveRequest,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../RAFComponents/helpers/utils";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { ContentType, RAFLayout } from "../../../constants/Common/Constants";
import { PermissionConstants } from "../../../constants/Common/PermissionConstants";
import { RAFActivityAction } from "./ActivityHelper";
import { ActivityRow, RAFActivityCommentType } from "./ActivityRow";

interface IProps {
  entityName: string;
  relatedToUID?: string;
  relatedTo?: string;
  onUpdate: (objectId?: string) => void;
  secondaryRelatedToUID?: string;
  secondaryRelatedTo?: string;
  secondaryRelatedToType?: string;
  addBtnText?: string;
  // menuContainer?: string,
  saveContextObject?: any;
  contextObject?: any;
  unsetMinWidth?: boolean;
}

interface IState {
  isActive?: boolean;
  activityRow?: ActivityRow;
  contextObject?: Object;
}

class CreateActivity extends Component<IDialogProps & IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      activityRow: null,
      contextObject: null,
    };
  }
  public rafForm: FormRenderProps | null;
  private indexVal: string = Guid.newGuid();
  private _isMounted = false;
  public itemId = Guid.newGuid();

  componentDidMount = () => {
    this._isMounted = true;
    this.refresh();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  componentDidUpdate(prevProps) {
    if (
      !deepEqual(prevProps.entityName, this.props.entityName) ||
      !deepEqual(prevProps.relatedToUID, this.props.relatedToUID) ||
      !deepEqual(prevProps.relatedTo, this.props.relatedTo) ||
      !deepEqual(prevProps.secondaryRelatedTo, this.props.secondaryRelatedTo) ||
      !deepEqual(
        prevProps.secondaryRelatedToType,
        this.props.secondaryRelatedToType
      ) ||
      !deepEqual(
        prevProps.secondaryRelatedToUID,
        this.props.secondaryRelatedToUID
      ) ||
      !deepEqual(prevProps.contextObject, this.props.contextObject)
    ) {
      if (this._isMounted) {
        this.setState({ isActive: false }, () => {
          this.refresh();
        });
      }
    }
    let createActivityRow = document.querySelector(".createActivity");
    if (isNotNullAndUndefined(createActivityRow)) {
      let createActivityCol = createActivityRow.firstChild as HTMLElement;
      if (createActivityRow.clientWidth < createActivityCol.clientWidth) {
        createActivityRow.classList.add("unset-min-width");
      }
    }
  }

  refresh = () => {
    let btnCreateActivitySave = document.querySelector(
      "#btnCreateActivitySave_" + this.indexVal
    ) as HTMLButtonElement;
    if (isNotNullAndUndefined(btnCreateActivitySave)) {
      btnCreateActivitySave.disabled = true;
    }
    let activityRow: ActivityRow = new ActivityRow();
    activityRow.UserUID = msalInstance.currentUserId;
    activityRow.UserName = msalInstance.currentUserName;
    activityRow.CommentType = RAFActivityCommentType.Note;

    activityRow.RelatedToType = this.props.entityName;
    activityRow.Action = RAFActivityAction.Added;
    activityRow.IsSystem = false;

    activityRow.RelatedToUID = isNotNullAndUndefined(this.props.relatedToUID)
      ? this.props.relatedToUID
      : "";
    activityRow.RelatedTo = isNotNullAndUndefined(this.props.relatedTo)
      ? this.props.relatedTo
      : "";

    activityRow.SecondaryRelatedTo = isNotNullAndUndefined(
      this.props.secondaryRelatedTo
    )
      ? this.props.secondaryRelatedTo
      : null;
    activityRow.SecondaryRelatedToType = isNotNullAndUndefined(
      this.props.secondaryRelatedToType
    )
      ? this.props.secondaryRelatedToType
      : null;
    activityRow.SecondaryRelatedToUID = isNotNullAndUndefined(
      this.props.secondaryRelatedToUID
    )
      ? this.props.secondaryRelatedToUID
      : null;

    if (this._isMounted) {
      this.setState({
        isActive: true,
        activityRow,
      });
    }
  };

  onSubmitActivity = (value) => {
    //let progressDiv = showProgress('.activitiesItems');
    let progressDiv = showProgress(
      `#create__notes__outter__${this.itemId}`,
      false
    );
    let activityToCreate: ActivityRow = value as ActivityRow;

    activityToCreate.Action = isNotNullAndUndefined(activityToCreate.UID)
      ? RAFActivityAction.Modified
      : RAFActivityAction.Added;

    let btnCreateActivitySave = document.querySelector(
      "#btnCreateActivitySave_" + this.indexVal
    ) as HTMLButtonElement;
    if (isNotNullAndUndefined(btnCreateActivitySave)) {
      btnCreateActivitySave.disabled = true;
    }

    if (
      isNotNullAndUndefined(this.props.saveContextObject) &&
      isNullOrUndefined(activityToCreate.RelatedTo) &&
      isNullOrUndefined(activityToCreate.RelatedToUID)
    ) {
      this.saveContextObject().then((contextResponse) => {
        //hideProgress(progressDiv);
        if (isNotNullAndUndefined(contextResponse.objectUID)) {
          activityToCreate.RelatedToUID = contextResponse.objectUID;
          activityToCreate.RelatedTo = contextResponse.objectName;

          repositoryActions
            .postDataAndGetResponse(
              "Activity/Save",
              //"Activity/SaveStream",
              getSaveRequest(activityToCreate, null),
              { ...this.props },
              ContentType.applicationJson
            )
            .then((response) => {
              hideProgress(progressDiv);
              if (
                isNotNullAndUndefined(response) &&
                isNotNullAndUndefined(response.data)
              ) {
                if (isNotNullAndUndefined(response.data.EntityId)) {
                  let actionBtnDiv = document.querySelector("#actionBtnDiv");
                  if (isNotNullAndUndefined(actionBtnDiv)) {
                    actionBtnDiv.classList.add("hidden");
                  }
                  showSuccessToast("Comment added", "Success!");
                  if (this.props.onUpdate) {
                    this.props.onUpdate();
                  }
                  if (this._isMounted) {
                    this.setState(
                      {
                        isActive: false,
                      },
                      () => {
                        this.refresh();
                        if (this.props.onUpdate) {
                          this.props.onUpdate(contextResponse.objectUID);
                        }
                      }
                    );
                  }
                } else if (
                  response.data.Error !== null &&
                  response.data.Error !== undefined &&
                  response.data.Error !== ""
                ) {
                  //console.log("Error on save", response.Error);
                }
              }
            })
            .catch((error) => error);
        }
      });
    } else {
      repositoryActions
        .postDataAndGetResponse(
          "Activity/Save",
          //"Activity/SaveStream",
          getSaveRequest(activityToCreate, null),
          { ...this.props },
          ContentType.applicationJson
        )
        .then((response) => {
          hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            if (isNotNullAndUndefined(response.data.EntityId)) {
              let actionBtnDiv = document.querySelector("#actionBtnDiv");
              if (isNotNullAndUndefined(actionBtnDiv)) {
                actionBtnDiv.classList.add("hidden");
              }
              showSuccessToast("Comment added", "Success!");
              if (this.props.onUpdate) {
                this.props.onUpdate();
              }
              if (this._isMounted) {
                this.setState(
                  {
                    isActive: false,
                  },
                  () => {
                    this.refresh();
                  }
                );
              }
            } else if (
              response.data.Error !== null &&
              response.data.Error !== undefined &&
              response.data.Error !== ""
            ) {
              //console.log("Error on save", response.Error);
            }
          }
        })
        .catch((error) => error);
    }
  };

  private saveContextObject = () => {
    let progressDiv = showProgress(
      `#create__notes__outter__${this.itemId}`,
      false
    );
    const rafFormContextValue = this.props.contextObject;
    return new Promise<{ objectUID: string; objectName: string; }>(
      (resolve, reject) => {
        this.props.saveContextObject(rafFormContextValue).then((response) => {
          hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.objectUID)
          ) {
            resolve({
              objectUID: response.objectUID,
              objectName: response.objectName,
            });
          }
        });
      }
    );
  };

  onFocus = () => {
    let actionBtnDiv = document.querySelector("#actionBtnDiv");
    let actionTextAreaDiv = document.querySelector("#actionTextAreaDiv");
    if (isNotNullAndUndefined(actionBtnDiv)) {
      actionBtnDiv.classList.remove("hidden");
    }
    if (isNotNullAndUndefined(actionTextAreaDiv)) {
      actionTextAreaDiv.classList.add("actionTextAreaDiv");
    }
  };

  onInputs = (value) => {
    let btnCreateActivitySave = document.querySelector(
      "#btnCreateActivitySave_" + this.indexVal
    ) as HTMLButtonElement;
    if (isNotNullAndUndefined(btnCreateActivitySave)) {
      if (
        IsNullOrWhiteSpace(value) ||
        (isNotNullAndUndefined(value) && value.length === 0)
      ) {
        btnCreateActivitySave.disabled = true;
      }
      if (isNotNullAndUndefined(value) && value.length > 0) {
        if (value === "<br>") {
          btnCreateActivitySave.disabled = true;
        } else {
          btnCreateActivitySave.disabled = false;
        }
      }
    }
  };

  cancelBtnClicked = () => {
    let actionBtnDiv = document.querySelector("#actionBtnDiv");
    let actionTextAreaDiv = document.querySelector("#actionTextAreaDiv");
    if (isNotNullAndUndefined(actionBtnDiv)) {
      actionBtnDiv.classList.add("hidden");
    }
    if (isNotNullAndUndefined(actionTextAreaDiv)) {
      actionTextAreaDiv.classList.remove("actionTextAreaDiv");
    }
    if (this._isMounted) {
      this.setState(
        {
          isActive: false,
        },
        () => {
          this.refresh();
        }
      );
    }
  };

  render() {
    let { isActive, activityRow } = this.state;

    if (isActive === true) {
      return (
        <div className="" id={`create__notes__outter__${this.itemId}`}>
          <RAFForm
            type={ActivityRow}
            initialValues={activityRow}
            submitOnEnterKey={false}
            formRef={(g) => (this.rafForm = g)}
            layout={RAFLayout.TwoColumnLayout}
            onSubmit={this.onSubmitActivity}
          >
            <div
              className={`row g-0 createActivity singleLine createActivity-border-0 align-items-center ${this.props.unsetMinWidth ? "unset-min-width" : ""
                }`}
            >
              <div
                className="col overflow-auto createActivity-col"
                id="actionTextAreaDiv"
              >
                {/*<RAFTextBox<ActivityRow> field="Message" showLabel={false} required placeholder="Type your comment, press “enter” to create" ></RAFTextBox>*/}
                <RAFTextArea<ActivityRow>
                  field="Message"
                  showLabel={false}
                  required
                  placeholder="Add notes, comments, or @ to mention a person"
                  rows={5}
                  /*onFocus={e => this.onFocus(e)}*/
                  onInputs={(e) => this.onInputs(e)}
                  onChanged={(e) => this.onFocus()}
                  useMentions
                  mentionsField="Mentions"
                  rowClassName="g-0"
                  hideRequiredMessage
                // menuContainer={this.props.menuContainer}
                ></RAFTextArea>
              </div>
              <div className="col-auto ms-auto mt-auto">
                <RAFPermissionRender
                  permissionName={PermissionConstants.CommentsCreate}
                >
                  <ButtonComponent
                    type="button"
                    id={"buttonCreateActivitySave_" + this.indexVal}
                    cssClass="e-info m-1"
                    content="Add"
                    onClick={() => {
                      this.rafForm && this.rafForm.form.submit();
                    }}
                  ></ButtonComponent>
                  {/* <ButtonComponent id={"btnCreateActivitySave_" + this.indexVal} type="button" cssClass='custom-button-md e-flat e-primary createBtn primary-btn' ref={g => this.submitButton = g} onClick={() => { this.rafForm && this.rafForm.form.submit(); }}>{isNotNullAndUndefined(this.props.addBtnText) ? this.props.addBtnText : "Add"}</ButtonComponent> */}
                </RAFPermissionRender>
                {/*<button id="btnCreateActivitySave" type="button" disabled className="e-control e-btn e-lib e-flat e-primary createBtn primary-btn">Save</button>
                                   <ButtonComponent id="btnCreateActivitySave" type="button" disabled cssClass='e-flat e-primary createBtn primary-btn' ref={g => this.submitButton = g} onClick={() => { this.rafForm && this.rafForm.form.submit() }}>Save</ButtonComponent>
                                    <ButtonComponent id="btnCreateActivityCancel" type="button" cssClass='e-flat e-outline ms-2 mb-2' onClick={() => this.cancelBtnClicked()} >Cancel</ButtonComponent>*/}
              </div>
            </div>
          </RAFForm>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default CreateActivity;
