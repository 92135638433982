import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { FormRenderProps } from "react-final-form";
import { useRecoilValue } from "recoil";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  IsNullOrWhiteSpace,
  RAFFormComponentProps,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import ACDropdown from "../../../components/shared/ACFormFields/ACDropdown";
import ACLookup from "../../../components/shared/ACFormFields/ACLookup";
import ACNumber from "../../../components/shared/ACFormFields/ACNumber";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { DealConstants } from "../../../constants/ActiveContact/ACPermissionConstants";
import { Constants, RAFLayout } from "../../../constants/Common/Constants";
//import RAFChoiceOption from '../../../RAFComponents/Inputs/RAFChoiceOption';
import RAFDatePickerMUI from "../../../RAFComponents/Inputs/RAFDatePickerMUI";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import { setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { createInstance } from "../../../RAFComponents/Utility/FormUtility";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import nodata_es from "../../../assets/ES/nodata_es.svg";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { RAFPageType } from "../../../constants/Common/RMConstants";
import { atomEditingRecordId } from "../../Common/List/IndexHelper";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../Common/Providers/RAFObjectContextProvider";
import RAFPageLayoutProvider from "../../Common/Providers/RAFPageLayoutProvider";
import RAFPageLayout from "../../Common/RAFPageLayout";
import CreateAccount from "../Account/CreateAccount";
import ACContactLookup from "../Contact/ACContactLookup";
import { ContactRow } from "../Contact/ContactRow";
import CreateContact from "../Contact/CreateContact";
import { DealRow } from "./DealRow";
import RAFLookUpMUI from "../../../RAFComponents/Inputs/RAFLookUpMUI";

interface IState {
  isActive?: boolean;
  noContent?: boolean;
  //dealRow?: DealRow;
}

interface IProps {
  objectUID?: string;
  deal?: DealRow;
  onProcessStart?: (relatedUID?: string) => void;
}

function UpdateDeal({
  //deal,
  isActive,
  objectUID,
  ...props
}: PropsWithChildren<IDialogProps & RAFFormComponentProps & IProps>) {
  const entityRow = useContext(RAFEntityContext);

  const moduleName: string = createInstance(DealRow).getClassName();
  let rafForm: FormRenderProps | null;

  let dealUID = useRecoilValue(atomEditingRecordId(moduleName));
  objectUID = isNotNullAndUndefined(dealUID)
    ? dealUID
    : isNotNullAndUndefined(objectUID)
    ? objectUID
    : isNotNullAndUndefined(props.deal) && isNotNullAndUndefined(props.deal.UID)
    ? props.deal.UID
    : null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isActive: false,
      noContent: false,
      //dealRow: null,
    }
  );

  useEffect(() => {
    //console.log('UpdateAccount', objectUID);
    refresh(isActive, objectUID, props.deal);
  }, [isActive, objectUID, props.deal]);

  function refresh(isActive: boolean, id?: string, deal?: DealRow) {
    if (isNotNullAndUndefined(deal) && isActive) {
      setState({
        isActive: true,
        noContent: false,
        //dealRow: deal,
      });
    } else if (isNotNullAndUndefined(id) && isActive) {
      setState({
        isActive: true,
        noContent: false,
      });
      //getDeal(id);
    } else {
      setState({
        isActive: false,
      });
    }
  }

  // function getDeal(id) {
  //   const entityUID = isNotNullAndUndefined(entityRow) && isNotNullAndUndefined(entityRow.entity) ? entityRow.entity.UID : null;
  //   console.log('entityRow', entityRow);
  //   RetrieveRecord(id, moduleName, entityUID)
  //     .then((response) => {
  //       if (isNotNullAndUndefined(response)) {
  //         setState({
  //           noContent: false,
  //           dealRow: response,
  //         });
  //       } else {
  //         setState({
  //           noContent: true,
  //           dealRow: null,
  //         });
  //       }
  //     })
  //     .catch((error) => error);
  // }

  if (state.isActive) {
    const onSubmitDeal = (
      value,
      saveObject?: (
        objectData?: any
      ) => Promise<{ objectUID?: string; objectName?: string }>
    ) => {
      // let progressDiv = showProgress(`#dlg_update_${moduleName}.e-popup-open`);
      let objValues: DealRow = value as DealRow;
      let url = "Deal/Save";

      saveObject(objValues)
        .then((response) => {
          // hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            !IsNullOrWhiteSpace(response.objectUID)
          ) {
            showSuccessToast("Saved successfully");
            setState({ isActive: false });
            if (props.onSave)
              props.onSave(response.objectUID, response.objectName);
          } else {
            showWarningToast("Sorry something went wrong !");
          }
        })
        .catch((error) => error);
    };

    const onAccountLookupChanged = (id) => {
      setFormValue(rafForm, "AccountUID", id);
      setFormValue(rafForm, "ContactUID", null);
      setFormValue(rafForm, "Contact", null);
    };

    if (
      !IsNullOrWhiteSpace(objectUID) ||
      (isNotNullAndUndefined(props.deal) &&
        isNotNullAndUndefined(props.deal.UID))
    ) {
      return (
        <div
          className="h-100"
          key={`${objectUID}_${moduleName}`}
          id={`raf_dlg_Outer_Div_${moduleName}`}
        >
          <div className="update-page h-100">
            <RAFEntityProvider moduleName={moduleName}>
              <RAFAttributeRelatedListProvider moduleName={moduleName}>
                <RAFPageLayoutProvider rafPageType={RAFPageType.Edit}>
                  <RAFObjectContextProvider
                    moduleName={moduleName}
                    objectId={objectUID}
                    progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                    //objectData={props.deal}
                  >
                    <RAFObjectContext.Consumer>
                      {({ /*objectId, */ rafObject, error, saveObject }) => {
                        if (
                          isNotNullAndUndefined(rafObject) &&
                          !IsNullOrWhiteSpace(rafObject.UID)
                        ) {
                          return (
                            <RAFForm
                              type={DealRow}
                              initialValues={rafObject}
                              formRef={(g) => {
                                return (rafForm = g);
                              }}
                              layout={RAFLayout.TwoColumnLayout}
                              onSubmit={(value) =>
                                onSubmitDeal(value, saveObject)
                              }
                              primaryKey={rafObject}
                              className="h-100"
                            >
                              <div className="e-dlg-content-outer">
                                <div className="e-dlg-body-content">
                                  <RAFPageLayout
                                    defaultComponent={
                                      <div>
                                        <ACTextBox<DealRow>
                                          field="DealName"
                                          label="Deal Name"
                                          required
                                          placeholder="Deal Name"
                                        />
                                        <RAFDatePickerMUI<DealRow>
                                          field="DealDate"
                                          label="Deal Date"
                                          required
                                          placeholder="Deal Date"
                                        />
                                        <RAFLookUpMUI<DealRow>
                                          field="Account"
                                          label="Company Name"
                                          placeholder="Select Company Name"
                                          required
                                          //type={AccountRow}
                                          showCreateButton
                                          url={"Account/Lookup"}
                                          moduleName={RAFEntityName.Account}
                                          createform={CreateAccount}
                                          onChanged={(value, uid) =>
                                            onAccountLookupChanged(uid)
                                          }
                                          createformdefaultfield={"AccountName"}
                                          selectedField={"AccountName"}
                                          SearchCreateOptionMode={"Footer"}
                                        />
                                        <ACContactLookup<DealRow>
                                          field="Contact"
                                          label="Contact Name"
                                          placeholder="Select Contact Name"
                                          required
                                          type={ContactRow}
                                          url={"Contact/Lookup"}
                                          moduleName={RAFEntityName.Contact}
                                          showCreateButton
                                          //account={dealRow.Account} accountUID={dealRow.AccountUID}
                                          createform={CreateContact}
                                          createformdefaultfield={"FirstName"}
                                          //createformaccountfield={"Account"}
                                          //createformaccountUIDfield={"AccountUID"}
                                          //fieldName="AccountUID"
                                          fieldName="Account"
                                          selectedField={"ContactName"}
                                          //createFormInitialValues={[{ key: "Account", value: dealRow.Account }, { key: "AccountUID", value: dealRow.AccountUID }]}
                                          SearchCreateOptionMode={"Footer"}
                                        />
                                        {/* {(isNullOrUndefined(dealRow.BusinessProcessUID) || (initiateStartProcess)) &&
                                              <ACDropdown<DealRow> field="BusinessProcessUID" label="Process Name" allowAdd={false} showLabel onChanged={(label, value) => onBusinessProcessChange(label, value)}
                                                  placeholder="Select Process" >
                                                  {bpTemplateRows && bpTemplateRows.map(item => {
                                                      return <RAFChoiceOption key={item.UID} label={item.ProcessName} value={item.UID} />;
                                                  })}
                                              </ACDropdown>
                                          } */}
                                        {/*<ACLookup<DealRow> field="Stage" label="Stage" placeholder="Select Stage" required type={BusinessProcessStageRow} showFullList={false} />*/}
                                        <ACDropdown<DealRow>
                                          field="Status"
                                          label="Status"
                                          placeholder="Select Status"
                                          isColorOption
                                          uitype="colorpicker"
                                          createOptionMode={"Footer"}
                                          moduleName={moduleName}
                                          required
                                        />
                                        <RAFDatePickerMUI<DealRow>
                                          field="EstimateClosingDate"
                                          label="Estimate Closing Date"
                                          placeholder="Estimate Closing Date"
                                        />
                                        <ACNumber<DealRow>
                                          field="Probability"
                                          label="Probability"
                                          placeholder="Probability"
                                        />
                                        <RAFDatePickerMUI<DealRow>
                                          field="ActualClosingDate"
                                          label="Actual Closing Date"
                                          placeholder="Actual Closing Date"
                                        />
                                        <ACTextBox<DealRow>
                                          field="Weighted"
                                          label="Weighted"
                                          placeholder="Weighted"
                                        />
                                        <ACNumber<DealRow>
                                          field="DealValue"
                                          label="Deal Value"
                                          placeholder="Deal Value"
                                        />
                                        {/*<RAFExtraInfo<DealRow> field="ExtraInfoJson" label="Extra Info" />*/}
                                      </div>
                                    }
                                  />
                                </div>
                                <div className="e-dlg-footerContent ">
                                  <RAFPermissionRender
                                    permissionName={DealConstants.DealUpdate}
                                  >
                                    <ButtonComponent
                                      className="me-2"
                                      cssClass="e-flat e-info"
                                    >
                                      Save
                                    </ButtonComponent>
                                  </RAFPermissionRender>
                                  <ButtonComponent
                                    type="button"
                                    cssClass="e-flat"
                                    onClick={props.onClose}
                                  >
                                    Cancel
                                  </ButtonComponent>
                                </div>
                              </div>
                            </RAFForm>
                          );
                        } else {
                          if (error === Constants.RAFObject_NoContent) {
                            return (
                              <div className="container-fluid px-0">
                                <RAFDeletedRecordState
                                  image={nodata_es}
                                  title="This item is either deleted or You do not have sufficient privileges to view this item."
                                />
                              </div>
                            );
                          } else {
                            return (
                              <div
                                className="container-fluid px-0"
                                style={{ background: "transparent" }}
                              >
                                <ACLoadingPanel loadingText="Loading..." />
                              </div>
                            );
                          }
                        }
                      }}
                    </RAFObjectContext.Consumer>
                  </RAFObjectContextProvider>
                </RAFPageLayoutProvider>
              </RAFAttributeRelatedListProvider>
            </RAFEntityProvider>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container-fluid px-0">
          <RAFDeletedRecordState
            image={nodata_es}
            title="This item is either deleted or You do not have sufficient privileges to view this item."
          />
        </div>
      );
    }
  } else {
    return <div></div>;
  }
}

export default UpdateDeal;
