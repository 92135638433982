import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import React, { PropsWithChildren, Reducer, Suspense, useContext, useEffect, useReducer } from "react";
import { FormRenderProps } from "react-final-form";
import { useRecoilValue } from "recoil";
import ACDropdown from "../../../components/shared/ACFormFields/ACDropdown";
import ACEmailInputGroup from "../../../components/shared/ACFormFields/ACEmailInputGroup";
import ACInputGroup from "../../../components/shared/ACFormFields/ACInputGroup";
import ACTextArea from "../../../components/shared/ACFormFields/ACTextArea";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { AccountConstants } from "../../../constants/ActiveContact/ACPermissionConstants";
import { IsSuperAdmin } from "../../../RAFComponents/helpers/AppHelper";
import { Constants, RAFLayout } from "../../../constants/Common/Constants";
// import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import { IDialogProps, isNotNullAndUndefined, IsNullOrWhiteSpace, lazyRetry, RAFFormComponentProps } from "../../../RAFComponents/helpers/utils";
import { getFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
// import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
// import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
// import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { createInstance } from "../../../RAFComponents/Utility/FormUtility";
import { showSuccessToast, showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { RAFPageType } from "../../../constants/Common/RMConstants";
import { NavigateParamsProps } from "../../../router";
import { atomEditingRecordId } from "../../Common/List/IndexHelper";
import { RAFEntityContext } from "../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
import nodata_es from "../../../assets/ES/nodata_es.svg";
import { accountDecorators } from "./AccountHelper";
import { ConvertAccountRowToAccountDto } from "./AccountMappingRegister";
import { AccountRow } from "./AccountRow";


const RAFPermissionRender = React.lazy(
    () => lazyRetry(() => import("../../../RAFComponents/helpers/PermissionHelper"), "RAFPermissionRender")
);
const RAFDeletedRecordState = React.lazy(
    () => lazyRetry(() => import("../../../RAFComponents/Navigation/RAFDeletedRecordState"), "RAFDeletedRecordState")
);
const RAFAddress = React.lazy(
    () => lazyRetry(() => import("../../../components/shared/RAFAddress/RAFAddress"), "RAFAddress")
);
const RAFForm = React.lazy(
    () => lazyRetry(() => import("../../../RAFComponents/Inputs/RAFForm"), "RAFForm")
);
const RAFAttributeRelatedListProvider = React.lazy(
    () => lazyRetry(() => import("../../../RAFComponents/Providers/RAFAttributeRelatedListProvider"), "RAFAttributeRelatedListProvider")
);
const RAFEntityProvider = React.lazy(
    () => lazyRetry(() => import("../../Common/Providers/RAFEntityProvider"), "RAFEntityProvider")
);
const RAFPageLayoutProvider = React.lazy(
    () => lazyRetry(() => import("../../Common/Providers/RAFPageLayoutProvider"), "RAFPageLayoutProvider")
);
const RAFPageLayout = React.lazy(
    () => lazyRetry(() => import("../../Common/RAFPageLayout"), "RAFPageLayout")
);

interface IProps {
    account?: AccountRow;
    objectUID?: string;
}

interface IState {
    isActive?: boolean;
}

function UpdateAccount(
    {
        isActive,
        objectUID,
        //account,
        ...props
    }: PropsWithChildren<IProps & IDialogProps & RAFFormComponentProps & NavigateParamsProps>) {
    const entityRow = useContext(RAFEntityContext);

    const moduleName: string = createInstance(AccountRow).getClassName();
    let rafForm: FormRenderProps | null;

    let accountUID = useRecoilValue(atomEditingRecordId(moduleName));
    objectUID = isNotNullAndUndefined(accountUID) ? accountUID : isNotNullAndUndefined(objectUID) ? objectUID : (isNotNullAndUndefined(props.account) && isNotNullAndUndefined(props.account.UID)) ? props.account.UID : null;

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isActive: false,
        }
    );

    useEffect(() => {
        //console.log('UpdateAccount', objectUID);
        refresh(isActive, objectUID, props.account);
    }, [isActive, objectUID, props.account]);

    function refresh(isActive: boolean, id?: string, account?: AccountRow) {
        if (isNotNullAndUndefined(account) && isActive) {
            setState({
                isActive: true,
            });
        } else if (isNotNullAndUndefined(id) && isActive) {
            setState({
                isActive: true,
            });
        } else {
            setState({
                isActive: false,
            });
        }
    }

    // function getAccount(id, isActive) {
    //     const entityUID = isNotNullAndUndefined(entityRow) && isNotNullAndUndefined(entityRow.entity) ? entityRow.entity.UID : null;
    //     RetrieveRecord(id, moduleName, entityUID).then((response) => {
    //         if (isNotNullAndUndefined(response)) {
    //             const responseDto: IAccountDto = response as IAccountDto;
    //             let accountRow: AccountRow = ConvertAccountDtoToAccountRow(responseDto);
    //             setState({
    //                 noContent: false,
    //                 accountRow: accountRow
    //             });
    //         } else {
    //             setState({
    //                 noContent: true,
    //                 accountRow: null
    //             });
    //         }
    //     })
    //         .catch((error) => error);
    // }

    if (state.isActive) {
        // function onSaveAccount(entityId, objectName) {
        //     if (props.onSave)
        //         props.onSave(entityId, objectName);
        //     setState({ noContent: true });
        // };

        const onSaveOfflineClick = async (
            saveObject?: (
                objectData?: any,
                onlineOffline?: 'Online' | 'Offline'
            ) => Promise<{ objectUID?: string; objectName?: string; }>
        ) => {
            if (isNotNullAndUndefined(rafForm)) {
                if (rafForm.invalid === false) {
                    let formvalue = getFormValue(rafForm);
                    // let progressDiv = showProgress("#update-page-outerDiv");
                    let response = await saveObject(formvalue, 'Offline');
                    // hideProgress(progressDiv);
                    if (
                        isNotNullAndUndefined(response) &&
                        isNotNullAndUndefined(response.objectUID)
                    ) {
                        showSuccessToast("Saved successfully");
                        if (props.onSave)
                            props.onSave(response.objectUID, response.objectName);
                    } else {
                        showWarningToast("Sorry something went wrong !");
                    }
                } else {
                    rafForm.form.submit();
                }
            }
        };

        const onSubmitObject = (
            values,
            saveObject?: (
                objectData?: any
            ) => Promise<{ objectUID?: string; objectName?: string; }>
        ) => {
            //let progressDiv = showProgress("#update-page-outerDiv");
            saveObject(values)
                .then((response) => {
                    //hideProgress(progressDiv);
                    if (
                        isNotNullAndUndefined(response) &&
                        isNotNullAndUndefined(response.objectUID)
                    ) {
                        showSuccessToast("Saved successfully");
                        if (props.onSave)
                            props.onSave(response.objectUID, response.objectName);
                    } else {
                        showWarningToast("Sorry something went wrong !");
                    }
                })
                .catch((error) => error);
        };

        if (
            (!IsNullOrWhiteSpace(objectUID) ||
                (isNotNullAndUndefined(props.account) &&
                    isNotNullAndUndefined(props.account.UID)))
        ) {
            return (
                <Suspense fallback={<ACLoadingPanel loadingText="Loading..." />}>
                    <div className="update-page h-100" id={`raf_dlg_Outer_Div_${moduleName}`} key={`${objectUID}_${moduleName}`}>
                        <RAFEntityProvider moduleName={moduleName}>
                            <RAFAttributeRelatedListProvider moduleName={moduleName}>
                                <RAFPageLayoutProvider rafPageType={RAFPageType.Edit}>
                                    <RAFObjectContextProvider
                                        moduleName={moduleName}
                                        objectId={objectUID}
                                        progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                                    //objectData={props.account}
                                    >
                                        <RAFObjectContext.Consumer>
                                            {({ /*objectId, */ rafObject, error, saveObject }) => {
                                                if (
                                                    isNotNullAndUndefined(rafObject) &&
                                                    !IsNullOrWhiteSpace(rafObject.UID)
                                                ) {
                                                    return (
                                                        <RAFForm
                                                            type={AccountRow}
                                                            initialValues={rafObject}
                                                            formRef={(g) => (rafForm = g)}
                                                            layout={RAFLayout.TwoColumnLayout}
                                                            //onSave={onSaveAccount}
                                                            onSubmit={(value) => onSubmitObject(value, saveObject)}
                                                            decorators={[accountDecorators]}
                                                            primaryKey={rafObject.UID}
                                                            convertBeforeSave={ConvertAccountRowToAccountDto}
                                                            className="h-100"
                                                        >
                                                            <div className="e-dlg-content-outer">
                                                                <div className="e-dlg-body-content">
                                                                    <RAFPageLayout
                                                                        defaultComponent={
                                                                            <div>
                                                                                <ACTextBox<AccountRow>
                                                                                    field="AccountName"
                                                                                    label="Company Name"
                                                                                    required
                                                                                    placeholder="ABC Corp"
                                                                                />

                                                                                <ACDropdown<AccountRow>
                                                                                    field="AccountType"
                                                                                    label="Type"
                                                                                    placeholder="Select Type"
                                                                                    isColorOption
                                                                                    uitype="colorpicker"
                                                                                    moduleName={moduleName}
                                                                                    createOptionMode={"Footer"}
                                                                                />

                                                                                <ACDropdown<AccountRow>
                                                                                    field="Industry"
                                                                                    label="Industry"
                                                                                    placeholder="Select Industry"
                                                                                    isColorOption
                                                                                    uitype="colorpicker"
                                                                                    moduleName={moduleName}
                                                                                    createOptionMode={"Footer"}
                                                                                />

                                                                                <ACInputGroup<AccountRow>
                                                                                    field="Phone"
                                                                                    icon="fa fa-phone-alt"
                                                                                    label="Phone"
                                                                                    placeholder="+61 4 xxxx xxxx"
                                                                                />

                                                                                <ACEmailInputGroup<AccountRow>
                                                                                    field="Email"
                                                                                    icon="fas fa-envelope"
                                                                                    label="E-Mail"
                                                                                    placeholder="example@domain.com"
                                                                                />

                                                                                <ACTextBox<AccountRow>
                                                                                    field="Website"
                                                                                    label="Website"
                                                                                    placeholder="www.domain.com"
                                                                                />

                                                                                <ACTextBox<AccountRow>
                                                                                    field="NoOfEmployees"
                                                                                    showLabel
                                                                                    label="No of Employees"
                                                                                    placeholder="500-600"
                                                                                />

                                                                                {/* <ACAddress<AccountRow> field="AddressList" label="Address" showAdd={false} showAddressType={false} />*/}
                                                                                <div
                                                                                    className="row align-items-center"
                                                                                    id={"rafdivAddress"}
                                                                                >
                                                                                    <div className="col-12 d-flex">
                                                                                        <label className="form-label">Address</label>
                                                                                    </div>
                                                                                    <div>
                                                                                        <RAFAddress
                                                                                            field1="AddressLine1"
                                                                                            field2="AddressLine2"
                                                                                            field3="AddressCity"
                                                                                            field4="AddressState"
                                                                                            field5="AddressCountry"
                                                                                            field6="AddressPostalCode"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <ACTextArea<AccountRow>
                                                                                    field="Description"
                                                                                    showLabel
                                                                                    label="About company"
                                                                                    placeholder="first business deal, turnover, establishments etc."
                                                                                    rows={3}
                                                                                />
                                                                                {/*<RAFExtraInfo<AccountRow> field="ExtraInfoJson" label="Extra Info" />*/}
                                                                            </div>
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="e-dlg-footerContent ">
                                                                    <RAFPermissionRender
                                                                        permissionName={AccountConstants.AccountUpdate}
                                                                    >
                                                                        <ButtonComponent
                                                                            className="me-2"
                                                                            cssClass="e-flat e-info"
                                                                            id={`btn_save_update_${moduleName}`}
                                                                        >
                                                                            Save
                                                                        </ButtonComponent>
                                                                    </RAFPermissionRender>
                                                                    {(IsSuperAdmin() === true) &&
                                                                        <RAFPermissionRender
                                                                            permissionName={AccountConstants.AccountUpdate}
                                                                        >
                                                                            <ButtonComponent
                                                                                type="button"
                                                                                onClick={() => onSaveOfflineClick(saveObject)}
                                                                                className="me-2"
                                                                                cssClass="e-flat e-info"
                                                                                id={`btn_save_update_offline_${moduleName}`}
                                                                            >
                                                                                Save offline
                                                                            </ButtonComponent>
                                                                        </RAFPermissionRender>
                                                                    }
                                                                    <ButtonComponent
                                                                        type="button"
                                                                        cssClass="e-flat"
                                                                        onClick={props.onClose}
                                                                    >
                                                                        Cancel
                                                                    </ButtonComponent>
                                                                </div>
                                                            </div>
                                                        </RAFForm>
                                                    );
                                                } else {
                                                    if (error === Constants.RAFObject_NoContent) {
                                                        return (
                                                            <div className="container-fluid px-0">
                                                                <RAFDeletedRecordState
                                                                    image={nodata_es}
                                                                    title="This item is either deleted or You do not have sufficient privileges to view this item."
                                                                />
                                                            </div>
                                                        );
                                                    } else {
                                                        return (
                                                            <div
                                                                className="container-fluid px-0"
                                                                style={{ background: "transparent" }}
                                                            >
                                                                <ACLoadingPanel loadingText="Loading..." />
                                                            </div>
                                                        );
                                                    }
                                                }
                                            }}
                                        </RAFObjectContext.Consumer>
                                    </RAFObjectContextProvider>
                                </RAFPageLayoutProvider>
                            </RAFAttributeRelatedListProvider>
                        </RAFEntityProvider>
                    </div>
                </Suspense >
            );
        } else {
            return (
                <div className="container-fluid px-0">
                    <RAFDeletedRecordState
                        image={nodata_es}
                        title="This item is either deleted or You do not have sufficient privileges to view this item."
                    />
                </div>
            );
        }
    } else {
        return (
            <div></div>
        );
    }
}

export default UpdateAccount;