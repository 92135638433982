import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import React, { PropsWithChildren, Reducer, useContext, useEffect, useReducer } from 'react';
import { msalInstance } from '../../../..';
import { hideProgress, showProgress } from '../../../../RAFComponents/helpers/AppHelper';
import { getFocusListByRelatedUIDFocusType } from '../../../../RAFComponents/helpers/RAFMenuHelper';
import { isNotEmptyArray, isNotNullAndUndefined } from '../../../../RAFComponents/helpers/utils';
import { RAFFocusUserType } from '../../../../pages/FocusRow';
import { CurrentWorkspaceContext } from '../../../Common/Providers/WorkspaceContextProvider';
import RAFAddToFocusFormContent from './RAFAddToFocusFormContent';

interface IProps {
    relatedTo: string,
    relatedToType: string,
    relatedToUID: string,
    showAddToFocusListDialog?: boolean;
    onClose?: () => void;
    mode?: 'iconDiv' | 'Button';
}

interface IState {
    isUserItemExist: boolean;
    showAddToFocusListDialog: boolean;
}

function RAFAddToFocusList({
    mode = 'Button',
    ...props }: PropsWithChildren<IProps>) {
    const currentWorkspaceContext = useContext(CurrentWorkspaceContext);
    const currentWorkspace = isNotNullAndUndefined(currentWorkspaceContext) ? currentWorkspaceContext.currentWorkspace : null;

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isUserItemExist: false,
            showAddToFocusListDialog: false,
        }
    );

    useEffect(() => {
        refresh();
    }, [props.relatedToUID, props.relatedTo]);

    useEffect(() => {
        if (props.showAddToFocusListDialog === true) {
            showAddToFocusListDialog();
        } else {
            closeAddToFocusListDialog();
        }
    }, [props.showAddToFocusListDialog]);

    const refresh = async () => {
        setState({ showAddToFocusListDialog: false });
        let progressDiv = showProgress("#focusList_outerDiv");
        let allUserFocusList = await getFocusListByRelatedUIDFocusType(props.relatedToType, props.relatedToUID, [RAFFocusUserType.User, RAFFocusUserType.Portal], [msalInstance.currentUserId, isNotNullAndUndefined(currentWorkspace) ? currentWorkspace.UID : null]);
        let isUserItemExist = isNotEmptyArray(allUserFocusList) ? true : false;
        hideProgress(progressDiv);
        setState({ isUserItemExist });
    };

    //add_to_focus_Start
    function showAddToFocusListDialog() {
        setState({ showAddToFocusListDialog: true });
        document.body.classList.add('overflow-hidden');
    }

    const addToFocusListDialogContent = () => {
        if (state.showAddToFocusListDialog) {
            const { relatedTo, relatedToType, relatedToUID } = props;
            return (
                <RAFAddToFocusFormContent
                    relatedTo={relatedTo}
                    relatedToType={relatedToType}
                    relatedToUID={relatedToUID}
                    onSave={refresh}
                    onClose={closeAddToFocusListDialog}
                    isActive
                />
            );
        } else {
            return (
                <div></div>
            );
        }
    };

    const closeAddToFocusListDialog = () => {
        setState({ showAddToFocusListDialog: false });
        document.body.classList.remove('overflow-hidden');
        if (props.onClose) {
            props.onClose();
        }
    };
    //add_to_focus_end

    return (
        <TooltipComponent
            content={
                //isFocused ? "Remove from focus list" : 
                "Add to focus list"
            }
            position="BottomCenter"
            opensOn="Hover"
        >
            {mode === 'iconDiv' &&
                <div
                    id='focusList_outerDiv'
                    className='pointer'
                    onClick={() => {
                        showAddToFocusListDialog();
                    }}>
                    <i className={state.isUserItemExist ?
                        'fas fa-star text-warning-1'
                        //'fas fa-star-sharp-half-stroke' 
                        : 'fas fa-star'} ></i>
                </div>
            }
            {mode === 'Button' &&
                <ButtonComponent
                    type="button"
                    id='focusList_outerDiv'
                    iconCss="fas fa-bookmark"
                    cssClass={
                        state.isUserItemExist ? "primary-custom-button custom-button-md focus-icon-btn e-default imageWithoutTextBtn active" :
                            "primary-custom-button custom-button-md focus-icon-btn e-default imageWithoutTextBtn"
                    }
                    onClick={() => {
                        //</TooltipComponent>isFocused
                        //? RemoveFromFocusList(contactRow.UID) : 
                        showAddToFocusListDialog();
                    }}
                ></ButtonComponent>
            }
            {state.showAddToFocusListDialog && (
                <DialogComponent
                    header='Add To Focus List'
                    showCloseIcon visible={state.showAddToFocusListDialog}
                    cssClass="centerDialog-sm createEditForm form-center-dialog dlg-new-style fixed-header"
                    content={addToFocusListDialogContent.bind(this)}
                    isModal target='body'
                    closeOnEscape={false}
                    close={closeAddToFocusListDialog.bind(this)}
                    id="addToFocusListDialog"
                />
            )}
        </TooltipComponent>
    );
}

export default React.memo(RAFAddToFocusList);