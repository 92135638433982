import { MapperConfiguration, MappingPair } from '@dynamic-mapper/mapper';
import { ActivityRow, IActivityDto } from '../Activity/ActivityRow';

export const ConvertActivityToActivityDto = (activityRow: ActivityRow): IActivityDto => {

    const ActivityRowToIActivityDto = new MappingPair<ActivityRow, IActivityDto>();
    const ActivityRowToActivityDtoConfiguration = new MapperConfiguration(cfg => {
        cfg.createAutoMap(ActivityRowToIActivityDto, {})
            //.forSourceMember("UserName", opt => opt.ignore()) 
            ;
    });

    const mapper = ActivityRowToActivityDtoConfiguration.createMapper();
    return mapper.map(ActivityRowToIActivityDto, activityRow);
};

export const ConvertActivityDtoToActivityRow = (activitytDto: IActivityDto): ActivityRow => {

    const IActivityDtoToActivityRow = new MappingPair<IActivityDto, ActivityRow>();
    const IActivityDtoToActivityRowConfiguration = new MapperConfiguration(cfg => {
        cfg.createAutoMap(IActivityDtoToActivityRow, {
            // UserName:null
            ActivityTeamMembers: null
        })
            ;
    });

    const mapper = IActivityDtoToActivityRowConfiguration.createMapper();
    return mapper.map(IActivityDtoToActivityRow, activitytDto);
}

