import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { Component, Fragment } from "react";
import { FormRenderProps } from "react-final-form";
import RAFTextBox from "../../..//RAFComponents/Inputs/RAFTextBox";
import RAFCheckBox from "../../../RAFComponents/Inputs/RAFCheckBox";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFForm, { Condition } from "../../../RAFComponents/Inputs/RAFForm";
import RAFLookUpMUI from "../../../RAFComponents/Inputs/RAFLookUpMUI";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { createInstance } from "../../../RAFComponents/Utility/FormUtility";
import { showSuccessToast, showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  getSessionStorage,
  hideProgress,
  isCurrentTenantCareESIOPartner,
  setSessionStorage,
  showProgress
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  IsNullOrWhiteSpace,
  RAFFormComponentProps,
  deepEqual,
  isNotNullAndUndefined
} from "../../../RAFComponents/helpers/utils";
import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import { RAFViewRow } from "../../../RAFComponents/models/Common/RAFViewRow";
import { BusinessProcessRow } from "../../../RAFMaster/RMModules/BusinessProcess/Process/BusinessProcessRow";
import { BusinessProcessStepTemplateRow } from "../../../RAFMaster/RMModules/BusinessProcess/StepTemplate/BusinessProcessStepTemplateRow";
import { BusinessProcessTemplateRow } from "../../../RAFMaster/RMModules/BusinessProcess/Template/BusinessProcessTemplateRow";
import {
  RetrieveBusinessProcessTemplate,
  StartProcessAndSaveProcessSteps
} from "../../../RAFMaster/RMModules/BusinessProcess/helpers/ProcessHelper";
import ACDatePicker from "../../../components/shared/ACFormFields/ACDatePicker";
import ACDropdown from "../../../components/shared/ACFormFields/ACDropdown";
import ACEmail from "../../../components/shared/ACFormFields/ACEmail";
import ACPhone from "../../../components/shared/ACFormFields/ACPhone";
import ACTextArea from "../../../components/shared/ACFormFields/ACTextArea";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import RAFAddress from "../../../components/shared/RAFAddress/RAFAddress";
import {
  EmailRow,
  EmailType
} from "../../../components/shared/RAFEmail/EmailRow";
import {
  PhoneRow,
  PhoneType
} from "../../../components/shared/RAFPhone/PhoneRow";
import { ContactConstants } from "../../../constants/ActiveContact/ACPermissionConstants";
import {
  RAFLayout,
  StorageKey
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import {
  RAFBPStatus,
  RAFDeadlineCalculation,
  RAFExecutionOrder,
  RAFPageType
} from "../../../constants/Common/RMConstants";
import { NavigateParamsProps } from "../../../router";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
import RAFPageLayoutProvider from "../../Common/Providers/RAFPageLayoutProvider";
import RAFPageLayout from "../../Common/RAFPageLayout";
import CreateAccount from "../Account/CreateAccount";
import { contactDecorators } from "./ContactHelper";
import {
  ConvertContactDtoToContactRow,
  ConvertContactRowToContactDto
} from "./ContactMappingRegister";
import { ContactRow, IContactDto } from "./ContactRow";

interface IState {
  isActive?: boolean;
  typeValue?: QueryAttributeJM;
  bpTemplateRows?: BusinessProcessTemplateRow[];
  showStartProcessContent?: boolean;
  bpTemplate?: BusinessProcessTemplateRow;
  relatedTo?: string;
  relatedToType?: string;
  relatedToUID?: string;
  contactRow?: ContactRow;
}

interface IProps {
  accountUID?: string;
  account?: string;
  objectData?: string;
  onProcessStart?: (bpRow) => void;
  bpTemplate?: BusinessProcessTemplateRow;
}

class CreateContact extends Component<
  IProps & IDialogProps & RAFFormComponentProps & NavigateParamsProps,
  IState
> {
  public rafForm: FormRenderProps | null;
  public processrafForm: FormRenderProps | null;
  private moduleName: string = createInstance(ContactRow).getClassName();
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      typeValue: null,
      bpTemplate: isNotNullAndUndefined(this.props.bpTemplate)
        ? this.props.bpTemplate
        : {},
      showStartProcessContent: false,
      relatedTo: null,
      relatedToType: null,
      relatedToUID: null,
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.refresh(this.props.isActive);
  };

  componentDidUpdate = (prevProps) => {
    if (!deepEqual(prevProps.isActive, this.props.isActive)) {
      this.refresh(this.props.isActive);
      //if (this._isMounted) {
      //    this.setState({ isActive: this.props.isActive })
      //}
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  refresh = (isActive: boolean) => {
    // if (isActive === true) {
    //     if (this._isMounted) {
    //         let progressDiv = showProgress('#createContactDiv');
    //         getEntityIdByName("contact").then(entityId => {
    //             getBPTemplatesByEntityId(entityId).then(bpTemplateResponse => {
    //                 hideProgress(progressDiv);;
    //                 this.setState({ bpTemplateRows: bpTemplateResponse, isActive: isActive });
    //             });
    //         });
    //     }
    // } else {
    //     if (this._isMounted) {
    //         this.setState({ isActive: isActive });
    //     }
    // }
    if (this._isMounted) {
      this.setState({ isActive: isActive });
    }
  };

  onSaveContact = (entityId, objectName) => {
    let allViews: RAFViewRow[] = JSON.parse(
      getSessionStorage(StorageKey.allViews_modulename + this.moduleName, true)
    );
    let recentlyCreatedView: RAFViewRow =
      allViews && allViews.find((x) => x.ViewName === "Recently_Created");//recently_created;
    if (isNotNullAndUndefined(recentlyCreatedView)) {
      setSessionStorage(
        StorageKey.recentViewId_modulename + this.moduleName,
        true,
        JSON.stringify({
          viewId: recentlyCreatedView.UID,
          viewName: recentlyCreatedView.DisplayName,
        })
      );
    }
    if (this._isMounted) {
      this.setState({ isActive: false }, () => {
        if (this.props.onSave) this.props.onSave(entityId, objectName);
      });
    }
  };

  onSubmitContact = (value,
    saveObject?: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string; }>
  ) => {
    let objValues: ContactRow = value as ContactRow;
    let contactRow: IContactDto = ConvertContactRowToContactDto(objValues);
    // let progressDiv = showProgress("#createContactDiv");
    let url = "Contact/Save";
    let businessProcess = contactRow.BusinessProcess;
    let businessProcessUID = contactRow.BusinessProcessUID;
    /*objValues.BusinessProcess = null;
        objValues.BusinessProcessUID = null;*/
    saveObject(value).then((response) => {
      // hideProgress(progressDiv);
      if (
        isNotNullAndUndefined(response) &&
        !IsNullOrWhiteSpace(response.objectUID)
      ) {
        if (
          isNotNullAndUndefined(businessProcess) &&
          isNotNullAndUndefined(businessProcessUID)
        ) {
          showSuccessToast("Saved successfully");
          contactRow.BusinessProcess = businessProcess;
          contactRow.BusinessProcessUID = businessProcessUID;
          contactRow.UID = response.objectUID;
          let convertContactRow = ConvertContactDtoToContactRow(contactRow);
          this.setState(
            {
              relatedTo: response.objectName,
              relatedToUID: response.objectUID,
              relatedToType: "contact",
              contactRow: convertContactRow,
            },
            () => {
              let bpTemplateUID = businessProcessUID;

              RetrieveBusinessProcessTemplate(bpTemplateUID).then(
                (bpTempateResponse) => {
                  if (
                    isNotNullAndUndefined(
                      bpTempateResponse.ProcessDeadlineCalculation
                    ) &&
                    bpTempateResponse.ProcessDeadlineCalculation ===
                    RAFDeadlineCalculation.PromptUserWhenRun
                  ) {
                    this.startProcessDialogOpen(bpTempateResponse);
                  } else {
                    let progressDiv = showProgress("body", true);
                    StartProcessAndSaveProcessSteps(
                      {
                        bpRelatedTo: response.objectName,
                        bpRelatedToType: "contact",
                        bpRelatedToUID: response.objectUID,
                        objectData: this.props.objectData,
                      },
                      bpTempateResponse
                    ).then((bpResponse) => {
                      hideProgress(progressDiv);
                      this.onProcessStart(
                        bpResponse.bpRow,
                        bpResponse.bpStepTemplateRows,
                        saveObject
                      );
                    });
                  }
                }
              );
            }
          );
        } else {
          showSuccessToast("Saved successfully");
          let allViews: RAFViewRow[] = JSON.parse(
            getSessionStorage(
              StorageKey.allViews_modulename + this.moduleName,
              true
            )
          );
          let recentlyCreatedView: RAFViewRow =
            allViews &&
            allViews.find((x) => x.ViewName === "Recently_Created");//recently_created;
          if (isNotNullAndUndefined(recentlyCreatedView)) {
            setSessionStorage(
              StorageKey.recentViewId_modulename + this.moduleName,
              true,
              JSON.stringify({
                viewId: recentlyCreatedView.UID,
                viewName: recentlyCreatedView.DisplayName,
              })
            );
          }
          if (this._isMounted) {
            this.setState({ isActive: false }, () => {
              if (this.props.onSave)
                this.props.onSave(
                  response.objectUID,
                  response.objectName
                );
            });
          }
        }
      } else {
        showWarningToast("Sorry something went wrong !");
      }
    })
      .catch((error) => error);
  };

  private startProcessDialogOpen(bpTempateResponse): void {
    this.setState(
      { showStartProcessContent: true, bpTemplate: bpTempateResponse },
      () => {
        document.body.classList.add("overflow-hidden");
      }
    );
  }

  private startProcessDialogClose(): void {
    this.setState({ showStartProcessContent: false }, () => {
      let allViews: RAFViewRow[] = JSON.parse(
        getSessionStorage(
          StorageKey.allViews_modulename + this.moduleName,
          true
        )
      );
      let recentlyCreatedView: RAFViewRow =
        allViews && allViews.find((x) => x.ViewName === "Recently_Created");//recently_created;
      if (isNotNullAndUndefined(recentlyCreatedView)) {
        setSessionStorage(
          StorageKey.recentViewId_modulename + this.moduleName,
          true,
          JSON.stringify({
            viewId: recentlyCreatedView.UID,
            viewName: recentlyCreatedView.DisplayName,
          })
        );
      }
      if (this._isMounted) {
        this.setState({ isActive: false }, () => {
          if (this.props.onSave)
            this.props.onSave(this.state.relatedToUID, this.state.relatedTo);
        });
      }
      document.body.classList.remove("overflow-hidden");
    });
  }

  onSubmitProcessTemplate = (value,
    saveObject?: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string; }>
  ) => {
    let bPTemplateRow: BusinessProcessTemplateRow = value;
    let progressDiv = showProgress("body", true);

    let { relatedTo, relatedToType, relatedToUID } = this.state;
    //StartProcess({ bptemplateId: bPTemplateRow.UID, bpFormData: bPTemplateRow.FormData, bpDuedate: bPTemplateRow.DueDate, bpRelatedTo: relatedTo, bpRelatedToType: relatedToType, bpRelatedToUID: relatedToUID, objectData: this.props.objectData }).then(bpResponse => {
    StartProcessAndSaveProcessSteps(
      {
        bpRelatedTo: relatedTo,
        bpRelatedToType: relatedToType,
        bpRelatedToUID: relatedToUID,
        objectData: this.props.objectData,
      },
      bPTemplateRow
    ).then((bpResponse) => {
      hideProgress(progressDiv);
      this.onProcessStart(bpResponse.bpRow, bpResponse.bpStepTemplateRows, saveObject);
    });
  };

  startProcessContent(saveObject) {
    let { bpTemplate } = this.state;
    //const loggedinUserName = msalInstance.currentUserName;
    if (
      this.state.showStartProcessContent === true
    ) {
      return (
        <div id="startBusinessProcessForm">
          <RAFForm
            type={BusinessProcessTemplateRow}
            initialValues={bpTemplate}
            formRef={(g) => (this.processrafForm = g)}
            layout={RAFLayout.TwoColumnLayout}
            onSubmit={(value) => this.onSubmitProcessTemplate(value, saveObject)}
          >
            <div className="dlg-new-style">
              <div className="e-dlg-headerContent justify-content-between">
                <div className="d-flex align-items-center w-100">
                  <div className="e-dlg-header" id="_title">
                    {" "}
                    {bpTemplate.ProcessName}
                  </div>
                </div>
                <ButtonComponent
                  type="button"
                  cssClass="primary-custom-button"
                  iconCss="fas fa-xmark"
                  onClick={() => this.startProcessDialogClose()}
                ></ButtonComponent>
              </div>
              <div className="e-dlg-body-content pt-3 h-100 overflow-auto">
                {/*<div className="business-process-item mb-2">
                                        <div className="d-flex" style={{ fontWeight: 400 }}>
                                            <span className="pe-1" style={{ fontWeight: 500 }}>{bpTemplate.ProcessName}</span>
                                            <span className="pe-1"> started by <span className="pe-1" style={{ fontWeight: 500 }}>{(isNotNullAndUndefined(bpTemplate.Initiator) ? bpTemplate.Initiator : loggedinUserName)}</span></span>
                                            <span className="pe-1"> on <span style={{ fontWeight: 500 }}>{moment(new Date()).format("DD/MM/YYYY")}</span></span>
                                        </div>
                                    </div>*/}
                <Condition when="ProcessDeadlineCalculation" is={"Not set"}>
                  <ACDatePicker<BusinessProcessTemplateRow>
                    field="DueDate"
                    label="Do you want to set deadline?"
                  />
                </Condition>
                <Condition when="ProcessDeadlineCalculation" is={null}>
                  <ACDatePicker<BusinessProcessTemplateRow>
                    field="DueDate"
                    label="Do you want to set deadline?"
                  />
                </Condition>
              </div>
              <div className="e-dlg-footerContent ">
                <ButtonComponent
                  type="submit"
                  className="me-2"
                  cssClass="e-flat e-info"
                >
                  Start
                </ButtonComponent>
                <ButtonComponent
                  type="button"
                  cssClass="e-flat"
                  id="contactCreateBtn"
                  onClick={() => this.startProcessDialogClose()}
                >
                  Cancel
                </ButtonComponent>
              </div>
            </div>
          </RAFForm>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  onProcessStart = (
    bpRow: BusinessProcessRow,
    bpStepTemplateRows: BusinessProcessStepTemplateRow[],
    saveObject?: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string; }>
  ) => {
    if (isNotNullAndUndefined(bpRow)) {
      let objValues: ContactRow = this.state.contactRow as ContactRow;
      let contactRow: IContactDto = ConvertContactRowToContactDto(objValues);
      contactRow.ProcessStatus =
        bpRow.ExecutionOrder === RAFExecutionOrder.AnyOrder
          ? RAFBPStatus.InProgress
          : bpStepTemplateRows && bpStepTemplateRows[0].Title;
      contactRow.BusinessProcess = bpRow.ProcessName;
      contactRow.BusinessProcessUID = bpRow.UID;
      let progressDiv = showProgress("body", true);
      saveObject(contactRow).then((response) => {
        hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(response) &&
          !IsNullOrWhiteSpace(response.objectUID)
        ) {
          if (this._isMounted) {
            this.setState({ isActive: false }, () => {
              if (this.props.onProcessStart)
                this.props.onProcessStart(contactRow.UID);
              else if (this.props.onSave)
                this.props.onSave(
                  this.state.relatedToUID,
                  this.state.relatedTo
                );
            });
          }
        } else {
          showWarningToast("Sorry something went wrong !");
        }
      })
        .catch((error) => error);
    }
  };

  /*onSaveContact = (value) => {
        let contactRow: ContactRow = value as ContactRow;
        let allViews: RAFViewRow[] = JSON.parse(getSessionStorage(StorageKey.allViews_modulename + this.moduleName));
        let recentlyCreatedView: RAFViewRow = allViews && allViews.find(x => x.ViewName === 'recently_created');
        if (isNotNullAndUndefined(recentlyCreatedView)) {
            setSessionStorage(StorageKey.recentViewId_modulename + this.moduleName, true, JSON.stringify({ viewId: recentlyCreatedView.UID, viewName: recentlyCreatedView.DisplayName }));
        }
        if (this.props.onSave)
            this.props.onSave(entityId, objectName);
    }*/

  onBusinessProcessChange = (label) => {
    if (
      isNotNullAndUndefined(this.rafForm) &&
      isNotNullAndUndefined(this.rafForm.form) &&
      isNotNullAndUndefined(this.rafForm.form.mutators)
    ) {
      this.rafForm.form.mutators.setValue("BusinessProcess", label);
    }
  };

  render() {
    let { isActive, bpTemplateRows, bpTemplate } = this.state;

    let contactRow: ContactRow = new ContactRow();
    if (isNotNullAndUndefined(this.props.initialValues)) {
      contactRow = this.props.initialValues as ContactRow;
    }

    let phoneList: PhoneRow[] = [
      { PhoneType: PhoneType.Work, PhoneNumber: null },
    ];
    contactRow.PhoneList = phoneList;

    let emailList: EmailRow[] = [{ EmailType: EmailType.Work, EmailId: null }];
    contactRow.EmailList = emailList;

    //let accountAddressList: AddressRow[] = [{ AddressType: AddressType.PostalAddress, AddressLine1: null }]
    //contactRow.AddressList = accountAddressList;

    contactRow.Type = "Lead";
    contactRow.EmailOptedout = false;
    if (
      !isNotNullAndUndefined(contactRow.Account) &&
      !isNotNullAndUndefined(contactRow.AccountUID)
    ) {
      contactRow.Account = isNotNullAndUndefined(this.props.account)
        ? this.props.account
        : null;
      contactRow.AccountUID = isNotNullAndUndefined(this.props.accountUID)
        ? this.props.accountUID
        : null;
    }
    //contactRow.Account = 'Microsoft';
    //contactRow.AccountUID = '262df466-f3fd-11ea-abea-02abb19b7010';
    let phoneListDisableItems = [];
    phoneList &&
      phoneList.forEach((item) => {
        phoneListDisableItems.push({
          label: item.PhoneType,
          value: item.PhoneType,
        });
      });

    let emailListDisableItems = [];
    emailList &&
      emailList.forEach((item) => {
        emailListDisableItems.push({
          label: item.EmailType,
          value: item.EmailType,
        });
      });

    if (isNotNullAndUndefined(bpTemplate)) {
      contactRow.BusinessProcess = bpTemplate.ProcessName;
      contactRow.BusinessProcessUID = bpTemplate.UID;
    }

    if (isActive === true) {
      return (
        <div className="h-100" id={`raf_dlg_Outer_Div_${this.moduleName}`}>
          <RAFEntityProvider moduleName={this.moduleName}>
            <RAFAttributeRelatedListProvider moduleName={this.moduleName}>
              <RAFPageLayoutProvider rafPageType={RAFPageType.Create}>
                <RAFObjectContextProvider moduleName={this.moduleName} progressDivId={`#raf_dlg_Outer_Div_${this.moduleName}`}>
                  <RAFObjectContext.Consumer>
                    {({ /*objectId, rafObject,*/ saveObject }) => {
                      return (
                        <Fragment>
                          <RAFForm
                            type={ContactRow}
                            initialValues={contactRow}
                            formRef={(g) => (this.rafForm = g)}
                            layout={RAFLayout.TwoColumnLayout}
                            //onSubmit={this.onSubmitContact}
                            onSubmit={(value) => this.onSubmitContact(value, saveObject)}
                            decorators={[contactDecorators]}
                            convertBeforeSave={ConvertContactRowToContactDto}
                            className="h-100"

                          >
                            <div className="e-dlg-content-outer">
                              <div className="e-dlg-body-content mt-2">
                                <RAFPageLayout
                                  defaultComponent={
                                    <div>
                                      <div className="row align-items-center g-0">
                                        <div className="col-12 d-flex">
                                          <label className="form-label required">
                                            Name{" "}
                                          </label>
                                        </div>
                                        <div className="col-12">
                                          <div className="w-100">
                                            <div className="row gx-3">
                                              <div className="col-sm-6">
                                                <RAFTextBox<ContactRow>
                                                  field="FirstName"
                                                  label={"FName"}
                                                  showLabel={false}
                                                  placeholder="First Name"
                                                  required
                                                />
                                              </div>
                                              <div className="col">
                                                <RAFTextBox<ContactRow>
                                                  field="LastName"
                                                  showLabel={false}
                                                  placeholder="Last Name"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <ACTextBox<ContactRow>
                                        field="Designation"
                                        label="Designation"
                                        placeholder="Marketing Manager"
                                      />
                                      {/* <ACLookup<ContactRow> */}
                                      <RAFLookUpMUI<ContactRow>
                                        field="Account"
                                        label="Company Name"
                                        required={!isCurrentTenantCareESIOPartner()}
                                        //type={AccountRow}
                                        showCreateButton
                                        moduleName={RAFEntityName.Account}
                                        url={"Account/Lookup"}
                                        placeholder="Select Company Name"
                                        //createform={<CreateAccount isActive />}
                                        createform={CreateAccount}
                                        createformdefaultfield={"AccountName"}
                                        selectedField={"AccountName"}
                                        SearchCreateOptionMode={"Footer"}
                                      />
                                      <div className="hidden">
                                        <ACDropdown<ContactRow>
                                          field="BusinessProcessUID"
                                          label="Process Name"
                                          showLabel
                                          allowAdd={false}
                                          onChanged={(label, value) =>
                                            this.onBusinessProcessChange(label)
                                          }
                                          placeholder="Select Process"
                                        >
                                          {bpTemplateRows &&
                                            bpTemplateRows.map((item) => {
                                              return (
                                                <RAFChoiceOption
                                                  key={item.UID}
                                                  label={item.ProcessName}
                                                  value={item.UID}
                                                />
                                              );
                                            })}
                                        </ACDropdown>
                                      </div>
                                      <ACDropdown<ContactRow>
                                        field="Type"
                                        label="Type"
                                        placeholder="Select Type"
                                        isColorOption
                                        uitype="colorpicker"
                                        createOptionMode={"Footer"}
                                        moduleName={this.moduleName}
                                        required
                                      />

                                      <ACPhone<ContactRow>
                                        field="PhoneList"
                                        showLabel
                                        label="Phone"
                                      />

                                      <ACEmail<ContactRow>
                                        field="EmailList"
                                        showLabel
                                        label="E-Mail"
                                      />

                                      <div className="row align-items-center">
                                        <div className="col-12">
                                          <label className="form-label">
                                            Email opt-out
                                          </label>
                                        </div>
                                        <div className="col-12" style={{ width: "65px" }}>
                                          <RAFCheckBox<ContactRow>
                                            field="EmailOptedout"
                                            showLabel={false}
                                            label="Email opt-out"
                                            uitype="switch"
                                          ></RAFCheckBox>
                                        </div>
                                      </div>

                                      <ACTextBox<ContactRow>
                                        field="LinkedIn"
                                        label="LinkedIn"
                                        placeholder="LinkedIn Link"
                                      />

                                      <div
                                        className="row align-items-center"
                                        id={"rafdivAddress"}
                                      >
                                        <div className="col-12 d-flex">
                                          <label className="form-label">Address</label>
                                        </div>

                                        <div className="col-12">
                                          <RAFAddress
                                            field1="AddressLine1"
                                            field2="AddressLine2"
                                            field3="AddressCity"
                                            field4="AddressState"
                                            field5="AddressCountry"
                                            field6="AddressPostalCode"
                                          />
                                        </div>
                                      </div>
                                      <ACTextArea<ContactRow>
                                        field="Description"
                                        showLabel
                                        label="About contact"
                                        placeholder="where did you first meet, hobbies etc."
                                        rows={3}
                                      />
                                    </div>
                                  }
                                />
                              </div>
                              <div className="e-dlg-footerContent ">
                                <RAFPermissionRender
                                  permissionName={ContactConstants.ContactCreate}
                                >
                                  <ButtonComponent
                                    type="button"
                                    cssClass="form-custom-button e-flat e-info"
                                    onClick={() =>
                                      this.rafForm && this.rafForm.form.submit()
                                    }
                                    id={`btn_save_create_${this.moduleName}`}
                                  >
                                    Save
                                  </ButtonComponent>
                                </RAFPermissionRender>
                                <ButtonComponent
                                  type="button"
                                  cssClass="form-custom-button"
                                  onClick={this.props.onClose}
                                  id={`btn_cancel_create_${this.moduleName}`}
                                >
                                  Cancel
                                </ButtonComponent>
                              </div>
                            </div>
                          </RAFForm>
                          {this.state.showStartProcessContent && (
                            <DialogComponent
                              visible={this.state.showStartProcessContent}
                              cssClass="rightDialog createEditForm full-height form-center-dialog"
                              id="startBusinessProcessDialog"
                              content={this.startProcessContent.bind(this, saveObject)}
                              isModal
                              target="body"
                              closeOnEscape={false}
                              close={this.startProcessDialogClose.bind(this)}
                            ></DialogComponent>
                          )}
                        </Fragment>
                      );
                    }}
                  </RAFObjectContext.Consumer>
                </RAFObjectContextProvider>
              </RAFPageLayoutProvider>
            </RAFAttributeRelatedListProvider>
          </RAFEntityProvider>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default CreateContact;
