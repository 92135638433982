import { MappingPair, MapperConfiguration } from '@dynamic-mapper/mapper';
import { AddressRow, AddressType } from '../../../components/shared/RAFAddress/AddressRow';
import { isNotNullAndUndefined } from '../../../RAFComponents/helpers/utils';
import { getAddressListFromModel, getEmailListFromModel, getPhoneListFromModel } from './ContactHelper';
import { ContactRow, IContactDto } from "./ContactRow";

export const ConvertContactRowToContactDto = (contactRow: ContactRow): IContactDto => {

    const ContactRowToIContactDto = new MappingPair<ContactRow, IContactDto>();
    const ContactRowToContactDtoConfiguration = new MapperConfiguration(cfg => {
        cfg.createAutoMap(ContactRowToIContactDto, {})
            .forSourceMember("PhoneList", opt => opt.ignore())
            .forSourceMember("EmailList", opt => opt.ignore())
            .forSourceMember("AddressList", opt => opt.ignore())
            ;
    });

    const mapper = ContactRowToContactDtoConfiguration.createMapper();
    return mapper.map(ContactRowToIContactDto, contactRow);
}

export const ConvertContactDtoToContactRow = (contactDto: IContactDto): ContactRow => {
    let addressList: AddressRow[] = [{ AddressType: AddressType.PostalAddress, AddressLine1: null }];
    const IContactDtoToContactRow = new MappingPair<IContactDto, ContactRow>();
    const IContactDtoToContactRowConfiguration = new MapperConfiguration(cfg => {
        cfg.createAutoMap(IContactDtoToContactRow, {
            PhoneList: opt => opt.mapFrom(src => getPhoneListFromModel(src)),
            EmailList: opt => opt.mapFrom(src => getEmailListFromModel(src)),
            AddressList: opt => opt.mapFrom(src => (isNotNullAndUndefined(src) && (isNotNullAndUndefined(src.AddressLine1)) ? getAddressListFromModel(src) : addressList)),
        });
    });

    const mapper = IContactDtoToContactRowConfiguration.createMapper();
    return mapper.map(IContactDtoToContactRow, contactDto);
}

