import createDecorator from 'final-form-calculate';
import { hideProgress, showProgress } from "../../../RAFComponents/helpers/AppHelper";
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { AddressRow, AddressType } from "../../../components/shared/RAFAddress/AddressRow";
import { CityRow } from "../../../components/shared/RAFAddress/CityRow";
import { CountryRow } from "../../../components/shared/RAFAddress/CountryRow";
import { StateRow } from "../../../components/shared/RAFAddress/StateRow";
import { Constants, ContentType } from "../../../constants/Common/Constants";
import { AccountRow, IAccountDto } from "./AccountRow";

export function getAddressListFromModel(accountRow: AccountRow | IAccountDto): AddressRow[] {
    let addressList: AddressRow[] = [];
    if (isNotNullAndUndefined(accountRow)) {
        if (isNotNullAndUndefined(accountRow.AddressLine1)) {
            const countryRow = new CountryRow();
            if (isNotNullAndUndefined(accountRow.AddressCountryUID)) {
                countryRow.UID = accountRow.AddressCountryUID.toString();
            }
            countryRow.Country = accountRow.AddressCountry;


            const stateRow = new StateRow();
            if (isNotNullAndUndefined(accountRow.AddressStateUID)) {
                stateRow.UID = accountRow.AddressStateUID.toString();
            }
            stateRow.State = accountRow.AddressState;


            const cityRow = new CityRow();
            if (isNotNullAndUndefined(accountRow.AddressCityUID)) {
                cityRow.UID = accountRow.AddressCityUID.toString();
            }
            cityRow.City = accountRow.AddressCity;


            addressList.push({
                AddressType: AddressType.PostalAddress,
                AddressLine1: accountRow.AddressLine1,
                AddressLine2: accountRow.AddressLine2,
                City: accountRow.AddressCity,
                PostalCode: accountRow.AddressPostalCode,
                State: accountRow.AddressState,
                Country: accountRow.AddressCountry
            });
        }
    }
    return addressList;
}

//export function getKeyValueByAddressList(addressList: AddressRow[]) {
//    let postalAddressLine1 = null;
//    let postalAddressLine2 = null;
//    let postalPostcode = null;
//    let postalCountryUID = null;
//    let postalCountry = null;
//    let postalStateUID = null;
//    let postalState = null;
//    let postalCityUID = null;
//    let postalCity = null;


//    if (isNotNullAndUndefined(addressList) && addressList.length > 0) {
//        addressList.forEach((item, index) => {
//            if (isNotNullAndUndefined(item.AddressType)) {
//                postalAddressLine1 = item.AddressLine1;
//                postalAddressLine2 = item.AddressLine2;
//                postalPostcode = item.PostalCode;
//                if (isNotNullAndUndefined(item.City) && isNotNullAndUndefined(item.City.UID)) {
//                    postalCityUID = item.City.UID;
//                    postalCity = item.City.City;
//                }
//                if (isNotNullAndUndefined(item.Country) && isNotNullAndUndefined(item.Country.UID)) {
//                    postalCountryUID = item.Country.UID;
//                    postalCountry = item.Country.Country;
//                }
//                if (isNotNullAndUndefined(item.State) && isNotNullAndUndefined(item.State.UID)) {
//                    postalStateUID = item.State.UID;
//                    postalState = item.State.State;
//                }
//            }
//        });
//    }

//    let retVal: { fieldName: keyof AccountRow, fieldValue: any }[] = [];
//    retVal.push(
//        { fieldName: "AddressLine1", fieldValue: postalAddressLine1 },
//        { fieldName: "AddressLine2", fieldValue: postalAddressLine2 },
//        { fieldName: "AddressPostalCode", fieldValue: postalPostcode },
//        { fieldName: "AddressCountryUID", fieldValue: postalCountryUID },
//        { fieldName: "AddressCountry", fieldValue: postalCountry },
//        { fieldName: "AddressStateUID", fieldValue: postalStateUID },
//        { fieldName: "AddressState", fieldValue: postalState },
//        { fieldName: "AddressCityUID", fieldValue: postalCityUID },
//        { fieldName: "AddressCity", fieldValue: postalCity },

//    );

//    return retVal;
//}

/*export function getPhoneListFromModel(accountRow: AccountRow): PhoneRow[] {
    let phoneList: PhoneRow[] = [];
    if (isNotNullAndUndefined(accountRow)) {
        if (isNotNullAndUndefined(accountRow.PhoneHome)) {
            phoneList.push({
                PhoneNumber: accountRow.PhoneHome, PhoneType: PhoneType.HomePhone
            });
        }
        if (isNotNullAndUndefined(accountRow.PhonePersonal)) {
            phoneList.push({
                PhoneNumber: accountRow.PhonePersonal, PhoneType: PhoneType.PersonalPhone
            });
        }
        if (isNotNullAndUndefined(accountRow.PhoneWork)) {
            phoneList.push({
                PhoneNumber: accountRow.PhoneWork, PhoneType: PhoneType.WorkPhone
            });
        }
        if (isNotNullAndUndefined(accountRow.PhoneTollFree)) {
            phoneList.push({
                PhoneNumber: accountRow.PhoneTollFree, PhoneType: PhoneType.TollFree
            });
        }
        if (isNotNullAndUndefined(accountRow.PhoneFax)) {
            phoneList.push({
                PhoneNumber: accountRow.PhoneFax, PhoneType: PhoneType.Fax
            });
        }
    }
    return phoneList;
}

export function getKeyValueByPhoneList(phoneList: PhoneRow[]) {
    let homePhone = null;
    let personalPhone = null;
    let workPhone = null;
    let tollFree = null;
    let fax = null;

    if (isNotNullAndUndefined(phoneList) && phoneList.length > 0) {
        phoneList.forEach((item, index) => {
            if (isNotNullAndUndefined(item.PhoneType)) {
                if (item.PhoneType === PhoneType.HomePhone) {
                    homePhone = item.PhoneNumber;
                }
                else if (item.PhoneType === PhoneType.PersonalPhone) {
                    personalPhone = item.PhoneNumber;
                }
                else if (item.PhoneType === PhoneType.WorkPhone) {
                    workPhone = item.PhoneNumber;
                }
                else if (item.PhoneType === PhoneType.TollFree) {
                    tollFree = item.PhoneNumber;
                }
                else if (item.PhoneType === PhoneType.Fax) {
                    fax = item.PhoneNumber;
                }
            }
        });
    }

    let retVal: { fieldName: keyof AccountRow, fieldValue: any }[] = [];
    retVal.push(
        { fieldName: "PhoneHome", fieldValue: homePhone },
        { fieldName: "PhonePersonal", fieldValue: personalPhone },
        { fieldName: "PhoneWork", fieldValue: workPhone },
        { fieldName: "PhoneTollFree", fieldValue: tollFree },
        { fieldName: "PhoneFax", fieldValue: fax }
    );
    return retVal;
}

export function getEmailListFromModel(accountRow: AccountRow): EmailRow[] {
    let emailList: EmailRow[] = [];
    if (isNotNullAndUndefined(accountRow)) {
        if (isNotNullAndUndefined(accountRow.EmailInfoPersonal)) {
            emailList.push({
                EmailId: accountRow.EmailInfoPersonal, EmailType: EmailType.PersonalEmail
            });
        }
        if (isNotNullAndUndefined(accountRow.EmailInfoWork)) {
            emailList.push({
                EmailId: accountRow.EmailInfoWork, EmailType: EmailType.WorkEmail
            });
        }
    }
    return emailList;
}

export function getKeyValueByEmailList(emailList: EmailRow[]) {
    let personalEmail = null;
    let workEmail = null;
    if (isNotNullAndUndefined(emailList) && emailList.length > 0) {
        emailList.forEach((item, index) => {
            if (isNotNullAndUndefined(item.EmailType)) {
                if (item.EmailType === EmailType.PersonalEmail) {
                    personalEmail = item.EmailId;
                }
                else if (item.EmailType === EmailType.WorkEmail) {
                    workEmail = item.EmailId;
                }
            }
        });
    }
    let retVal: { fieldName: keyof AccountRow, fieldValue: any }[] = [];
    retVal.push(
        { fieldName: "EmailInfoPersonal", fieldValue: personalEmail },
        { fieldName: "EmailInfoWork", fieldValue: workEmail },
    );
    return retVal;
}

export function getPhoneNumberByType(phoneList: PhoneRow[], phoneType: PhoneType) {
    let retVal = null;
    if (isNotNullAndUndefined(phoneList) && phoneList.length > 0 && isNotNullAndUndefined(phoneType)) {
        const obj1 = phoneList.find(x => isNotNullAndUndefined(x) && x.PhoneType === phoneType);
        if (isNotNullAndUndefined(obj1) && isNotNullAndUndefined(obj1.PhoneNumber))
            retVal = obj1.PhoneNumber;
    }
    return retVal;
}

export function getEmailByType(emailList: EmailRow[], emailType: EmailType) {
    let retVal = null;
    if (isNotNullAndUndefined(emailList) && emailList.length > 0 && isNotNullAndUndefined(emailType)) {
        const obj1 = emailList.find(x => isNotNullAndUndefined(x) && x.EmailType === emailType);
        if (isNotNullAndUndefined(obj1) && isNotNullAndUndefined(obj1.EmailId))
            retVal = obj1.EmailId;
    }
    return retVal;
}
*/
export function getAddressPropertyByType(addressList: AddressRow[], addressType: AddressType, property: keyof AddressRow) {
    let retVal = null;
    if (isNotNullAndUndefined(addressList) && addressList.length > 0 && isNotNullAndUndefined(addressList)) {
        const obj = addressList.find(x => isNotNullAndUndefined(x) && x.AddressType === addressType);
        if (isNotNullAndUndefined(obj) && isNotNullAndUndefined(obj[property]))
            retVal = obj[property];
    }
    return retVal;
}

export const accountDecorators = createDecorator(
    {
        field: 'AddressList',
        updates: {
            AddressLine1: (minimumValue, allValues: AccountRow) => getAddressPropertyByType(allValues.AddressList, AddressType.PostalAddress, "AddressLine1"),
            AddressLine2: (minimumValue, allValues: AccountRow) => getAddressPropertyByType(allValues.AddressList, AddressType.PostalAddress, "AddressLine2"),
            AddressCity: (minimumValue, allValues: AccountRow) => getAddressPropertyByType(allValues.AddressList, AddressType.PostalAddress, "City"),
            //AddressCityUID: (minimumValue, allValues: AccountRow) => getAddressPropertyByType(allValues.AddressList, AddressType.PostalAddress, "CityUID"),
            AddressState: (minimumValue, allValues: AccountRow) => getAddressPropertyByType(allValues.AddressList, AddressType.PostalAddress, "State"),
            //AddressStateUID: (minimumValue, allValues: AccountRow) => getAddressPropertyByType(allValues.AddressList, AddressType.PostalAddress, "StateUID"),
            AddressCountry: (minimumValue, allValues: AccountRow) => getAddressPropertyByType(allValues.AddressList, AddressType.PostalAddress, "Country"),
            //AddressCountryUID: (minimumValue, allValues: AccountRow) => getAddressPropertyByType(allValues.AddressList, AddressType.PostalAddress, "CountryUID"),
            AddressPostalCode: (minimumValue, allValues: AccountRow) => getAddressPropertyByType(allValues.AddressList, AddressType.PostalAddress, "PostalCode"),
        }
    },
    {
        field: 'AccountType',
        updates: {
            AccountType: (minimumValue, allValues: AccountRow) => allValues.AccountType === 'None' ? null : allValues.AccountType,
        }
    },
    {
        field: 'Industry',
        updates: {
            Industry: (minimumValue, allValues: AccountRow) => allValues.Industry === 'None' ? null : allValues.Industry,
        }
    }
);

export const linkAccountToESIO = (accountUID: string) => {
    return new Promise<any>(async (resolve) => {
        if (isNotNullAndUndefined(accountUID)) {
            const url = Constants.baseRAFXeroUrl + "api/" + "Account/LinkAccountToESIO";
            const getRequest = { EntityId: accountUID };
            let progressDiv = showProgress('body');
            repositoryActions
                .postDataAndGetResponse(
                    url,
                    getRequest,
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    hideProgress(progressDiv);
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error) => resolve(null));
        } else {
            resolve(null);
        }
    });
};