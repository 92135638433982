import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, { Fragment, PropsWithChildren, Suspense, useEffect, useReducer } from "react";
import { FormRenderProps } from "react-final-form";
import ACDatePicker from "../../../components/shared/ACFormFields/ACDatePicker";
import ACDropdown from "../../../components/shared/ACFormFields/ACDropdown";
import ACEmailInputGroup from "../../../components/shared/ACFormFields/ACEmailInputGroup";
import ACInputGroup from "../../../components/shared/ACFormFields/ACInputGroup";
import ACTextArea from "../../../components/shared/ACFormFields/ACTextArea";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
// import RAFAddress from "../../../components/shared/RAFAddress/RAFAddress";
import { AccountConstants } from "../../../constants/ActiveContact/ACPermissionConstants";
import {
    getSessionStorage,
    hideProgress,
    IsSuperAdmin,
    setSessionStorage,
    showProgress
} from "../../../RAFComponents/helpers/AppHelper";
import {
    RAFLayout,
    StorageKey
} from "../../../constants/Common/Constants";
// import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
    IDialogProps,
    isNotNullAndUndefined,
    IsNullOrWhiteSpace, lazyRetry, RAFFormComponentProps
} from "../../../RAFComponents/helpers/utils";
import { Condition } from "../../../RAFComponents/Inputs/RAFForm";
import { getFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import { RAFViewRow } from "../../../RAFComponents/models/Common/RAFViewRow";
import { createInstance } from "../../../RAFComponents/Utility/FormUtility";
import { showSuccessToast, showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { 
    RAFExecutionOrder,
    RAFDeadlineCalculation,
    RAFBPStatus,
    RAFPageType} from "../../../constants/Common/RMConstants";
import {
    RetrieveBusinessProcessTemplate,
    StartProcessAndSaveProcessSteps
} from "../../../RAFMaster/RMModules/BusinessProcess/helpers/ProcessHelper";
import { BusinessProcessRow } from "../../../RAFMaster/RMModules/BusinessProcess/Process/BusinessProcessRow";
import { BusinessProcessStepTemplateRow } from "../../../RAFMaster/RMModules/BusinessProcess/StepTemplate/BusinessProcessStepTemplateRow";
import { BusinessProcessTemplateRow } from "../../../RAFMaster/RMModules/BusinessProcess/Template/BusinessProcessTemplateRow";
import { NavigateParamsProps } from "../../../router";
import RAFObjectContextProvider, { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
// import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
// import RAFPageLayoutProvider from "../../Common/Providers/RAFPageLayoutProvider";
// import RAFPageLayout from "../../Common/RAFPageLayout";
import { accountDecorators } from "./AccountHelper";
import {
    ConvertAccountDtoToAccountRow,
    ConvertAccountRowToAccountDto
} from "./AccountMappingRegister";
import { AccountRow, IAccountDto } from "./AccountRow";
import * as RAFDatabase from "../../../WorkesioRepository/index";
import { createTableByModuleName, saveDataByModuleName, syncDataByModuleName } from "../../../WorkesioRepository";


const RAFPermissionRender = React.lazy(
    () => lazyRetry(() => import("../../../RAFComponents/helpers/PermissionHelper"), "RAFPermissionRender")
);
const RAFAddress = React.lazy(
    () => lazyRetry(() => import("../../../components/shared/RAFAddress/RAFAddress"), "RAFAddress")
);
const RAFForm = React.lazy(
    () => lazyRetry(() => import("../../../RAFComponents/Inputs/RAFForm"), "RAFForm")
);
const RAFAttributeRelatedListProvider = React.lazy(
    () => lazyRetry(() => import("../../../RAFComponents/Providers/RAFAttributeRelatedListProvider"), "RAFAttributeRelatedListProvider")
);
const RAFEntityProvider = React.lazy(
    () => lazyRetry(() => import("../../Common/Providers/RAFEntityProvider"), "RAFEntityProvider")
);
const RAFPageLayoutProvider = React.lazy(
    () => lazyRetry(() => import("../../Common/Providers/RAFPageLayoutProvider"), "RAFPageLayoutProvider")
);
const RAFPageLayout = React.lazy(
    () => lazyRetry(() => import("../../Common/RAFPageLayout"), "RAFPageLayout")
);
interface IProps {
    isActive?: boolean;
    onProcessStart?: (bpRow) => void;
    bpTemplate?: BusinessProcessTemplateRow;
    objectData?: string;
    initialValues?: any;
}

function CreateAccount(
    {
        isActive,
        objectData,
        initialValues,
        ...props
    }: PropsWithChildren<IProps & IDialogProps & RAFFormComponentProps & NavigateParamsProps>) {

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            accountRow: null as AccountRow
        }
    );

    const moduleName: string = createInstance(AccountRow).getClassName();
    let rafForm: FormRenderProps | null;
    let processrafForm: FormRenderProps | null;

    useEffect(() => {
        refresh();
    }, [isActive]);

    const refresh = () => {
        if (isActive) {
            let { bpTemplate } = props;
            let accountRow: AccountRow = new AccountRow();
            if (isNotNullAndUndefined(initialValues)) {
                accountRow = initialValues as AccountRow;
            }

            //let accountAddressList: AddressRow[] = [{ AddressType: AddressType.PostalAddress, AddressLine1: null }]

            //accountRow.AddressList = accountAddressList;

            //accountRow.PhoneList = getPhoneListFromModel(accountRow);

            //accountRow.EmailList = getEmailListFromModel(accountRow);

            if (isNotNullAndUndefined(bpTemplate)) {
                accountRow.BusinessProcess = bpTemplate.ProcessName;
                accountRow.BusinessProcessUID = bpTemplate.UID;
            }

            setState({
                bpTemplate,
                accountRow,
            });
        } else {
            setState({ accountRow: null });
        }
    };

    const onSaveOfflineClick = async (
        saveObject?: (
            objectData?: any,
            onlineOffline?: 'Online' | 'Offline'
        ) => Promise<{ objectUID?: string; objectName?: string; }>
    ) => {
        if (isNotNullAndUndefined(rafForm)) {
            if (rafForm.invalid === false) {
                let formvalue = getFormValue(rafForm);
                // let progressDiv = showProgress("#update-page-outerDiv");
                let response = await saveObject(formvalue, 'Offline');
                // hideProgress(progressDiv);
                if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.objectUID)
                ) {
                    showSuccessToast("Saved successfully");
                    if (props.onSave)
                        props.onSave(response.objectUID, response.objectName);
                } else {
                    showWarningToast("Sorry something went wrong !");
                }
            } else {
                rafForm.form.submit();
            }
        }
    };


    const saveSyncClicked = async () => {
        if (isNotNullAndUndefined(rafForm)) {
            if (rafForm.invalid === false) {
                let formvalue = getFormValue(rafForm);
                console.log('saveSyncClicked', moduleName);
                let db = await RAFDatabase.get();

                db = createTableByModuleName(db, moduleName);
                formvalue = await saveDataByModuleName(moduleName, formvalue);

                const replicationState = await syncDataByModuleName(db, moduleName);
                replicationState.start();
                // return getAllDataByModuleName(props.moduleName).then((response) => {
                //     console.log('getAllDataByModuleName response', response);
                //     if (isNotNullAndUndefined(response) && response.length > 0) {
                //         return response;
                //     }
                //     else {
                //         return {
                //             count: undefined,
                //             result: undefined,
                //         };
                //     }

                // });
            }
        }
    };

    const onSubmitAccount = (value,
        saveObject?: (
            objectData?: any
        ) => Promise<{ objectUID?: string; objectName?: string; }>
    ) => {
        let objValues: AccountRow = value as AccountRow;
        let accountRow: IAccountDto = ConvertAccountRowToAccountDto(objValues);
        //let progressDiv = showProgress("body", true);
        //let url = "Account/Save";
        let businessProcess = accountRow.BusinessProcess;
        let businessProcessUID = accountRow.BusinessProcessUID;
        /*objValues.BusinessProcess = null;
                objValues.BusinessProcessUID = null;*/
        saveObject(accountRow).then((response) => {
            //hideProgress(progressDiv);
            if (
                isNotNullAndUndefined(response) &&
                !IsNullOrWhiteSpace(response.objectUID)
            ) {
                if (
                    isNotNullAndUndefined(businessProcess) &&
                    isNotNullAndUndefined(businessProcessUID)
                ) {
                    showSuccessToast("Saved successfully");
                    accountRow.BusinessProcess = businessProcess;
                    accountRow.BusinessProcessUID = businessProcessUID;
                    accountRow.UID = response.objectUID;
                    let convertAccountRow = ConvertAccountDtoToAccountRow(accountRow);
                    setState(
                        {
                            relatedTo: response.objectName,
                            relatedToUID: response.objectUID,
                            relatedToType: "account",
                            accountRow: convertAccountRow,
                        });
                    let bpTemplateUID = businessProcessUID;

                    RetrieveBusinessProcessTemplate(bpTemplateUID).then(
                        (bpTempateResponse) => {
                            if (
                                isNotNullAndUndefined(
                                    bpTempateResponse.ProcessDeadlineCalculation
                                ) &&
                                bpTempateResponse.ProcessDeadlineCalculation ===
                                RAFDeadlineCalculation.PromptUserWhenRun
                            ) {
                                startProcessDialogOpen(bpTempateResponse);
                            } else {
                                let progressDiv = showProgress("body", true);
                                StartProcessAndSaveProcessSteps(
                                    {
                                        bpRelatedTo: response.objectName,
                                        bpRelatedToType: "account",
                                        bpRelatedToUID: response.objectUID,
                                        objectData: objectData,
                                    },
                                    bpTempateResponse
                                ).then((bpResponse) => {
                                    hideProgress(progressDiv);
                                    onProcessStart(
                                        bpResponse.bpRow,
                                        bpResponse.bpStepTemplateRows,
                                        saveObject
                                    );
                                });
                            }
                        }
                    );
                } else {
                    showSuccessToast("Saved successfully");
                    let allViews: RAFViewRow[] = JSON.parse(
                        getSessionStorage(
                            StorageKey.allViews_modulename + moduleName,
                            true
                        )
                    );
                    let recentlyCreatedView: RAFViewRow =
                        allViews &&
                        allViews.find((x) => x.ViewName === "Recently_Created");//recently_created;
                    if (isNotNullAndUndefined(recentlyCreatedView)) {
                        setSessionStorage(
                            StorageKey.recentViewId_modulename + moduleName,
                            true,
                            JSON.stringify({
                                viewId: recentlyCreatedView.UID,
                                viewName: recentlyCreatedView.DisplayName,
                            })
                        );
                    }
                    setState({ accountRow: null });
                    if (props.onSave)
                        props.onSave(
                            response.objectUID,
                            response.objectName
                        );
                }

            } else {
                showWarningToast("Sorry something went wrong !");
            }
        })
            .catch((error) => error);
    };

    const onProcessStart = (
        bpRow: BusinessProcessRow,
        bpStepTemplateRows: BusinessProcessStepTemplateRow[],
        saveObject?: (
            objectData?: any
        ) => Promise<{ objectUID?: string; objectName?: string; }>
    ) => {
        if (isNotNullAndUndefined(bpRow)) {
            let objValues: AccountRow = state.accountRow as AccountRow;
            let accountRow: IAccountDto = ConvertAccountRowToAccountDto(objValues);
            accountRow.ProcessStatus =
                bpRow.ExecutionOrder === RAFExecutionOrder.AnyOrder
                    ? RAFBPStatus.InProgress
                    : bpStepTemplateRows && bpStepTemplateRows[0].Title;
            accountRow.BusinessProcess = bpRow.ProcessName;
            accountRow.BusinessProcessUID = bpRow.UID;
            let progressDiv = showProgress("body", true);
            saveObject(accountRow).then((response) => {
                hideProgress(progressDiv);
                if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.objectUID)
                ) {
                    if (isNotNullAndUndefined(response.objectUID)) {
                        setState({ accountRow: null });
                        if (props.onProcessStart)
                            props.onProcessStart(accountRow.UID);
                        else if (props.onSave)
                            props.onSave(
                                state.relatedToUID,
                                state.relatedTo
                            );
                    } else {
                        showWarningToast("Sorry something went wrong !");
                    }
                }
            })
                .catch((error) => error);
        }
    };

    //bp_dialog starts
    function startProcessDialogOpen(bpTempateResponse): void {
        setState(
            { showStartProcessContent: true, bpTemplate: bpTempateResponse }
        );
        document.body.classList.add("overflow-hidden");
    }

    function startProcessDialogClose(): void {
        setState({ showStartProcessContent: false });
        let allViews: RAFViewRow[] = JSON.parse(
            getSessionStorage(
                StorageKey.allViews_modulename + moduleName,
                true
            )
        );
        let recentlyCreatedView: RAFViewRow =
            allViews && allViews.find((x) => x.ViewName === "Recently_Created");//recently_created;
        if (isNotNullAndUndefined(recentlyCreatedView)) {
            setSessionStorage(
                StorageKey.recentViewId_modulename + moduleName,
                true,
                JSON.stringify({
                    viewId: recentlyCreatedView.UID,
                    viewName: recentlyCreatedView.DisplayName,
                })
            );
        }
        setState({ accountRow: null });
        if (props.onSave)
            props.onSave(state.relatedToUID, state.relatedTo);
        document.body.classList.remove("overflow-hidden");
    }

    const onSubmitProcessTemplate = (value,
        saveObject?: (
            objectData?: any
        ) => Promise<{ objectUID?: string; objectName?: string; }>
    ) => {
        let bPTemplateRow: BusinessProcessTemplateRow = value;
        let progressDiv = showProgress("body", true);

        let { relatedTo, relatedToType, relatedToUID } = state;
        //StartProcess({ bptemplateId: bPTemplateRow.UID, bpFormData: bPTemplateRow.FormData, bpDuedate: bPTemplateRow.DueDate, bpRelatedTo: relatedTo, bpRelatedToType: relatedToType, bpRelatedToUID: relatedToUID, objectData: props.objectData }).then(bpResponse => {
        StartProcessAndSaveProcessSteps(
            {
                bpRelatedTo: relatedTo,
                bpRelatedToType: relatedToType,
                bpRelatedToUID: relatedToUID,
                objectData: objectData,
            },
            bPTemplateRow
        ).then((bpResponse) => {
            hideProgress(progressDiv);
            onProcessStart(bpResponse.bpRow, bpResponse.bpStepTemplateRows, saveObject);
        });
    };

    const startProcessContent = (saveObject) => {
        let { bpTemplate } = state;
        //const loggedinUserName = msalInstance.currentUserName;
        if (
            state.showStartProcessContent === true
        ) {
            return (
                <Suspense fallback={<ACLoadingPanel loadingText="Loading..." />}>
                    <div id="startBusinessProcessForm">
                        <RAFForm
                            type={BusinessProcessTemplateRow}
                            initialValues={bpTemplate}
                            formRef={(g) => (processrafForm = g)}
                            layout={RAFLayout.TwoColumnLayout}
                            onSubmit={(value) => onSubmitProcessTemplate(value, saveObject)}
                        >
                            <div className="dlg-new-style">
                                <div className="e-dlg-headerContent justify-content-between">
                                    <div className="d-flex align-items-center w-100">
                                        <div className="e-dlg-header" id="_title">
                                            {" "}
                                            {bpTemplate.ProcessName}
                                        </div>
                                    </div>
                                    <ButtonComponent
                                        type="button"
                                        cssClass="primary-custom-button"
                                        iconCss="fas fa-xmark"
                                        onClick={() => startProcessDialogClose()}
                                    ></ButtonComponent>
                                </div>
                                <div className="e-dlg-body-content pt-3 h-100 overflow-auto">
                                    {/*<div className="business-process-item mb-2">
                                            <div className="d-flex" style={{ fontWeight: 400 }}>
                                                <span className="pe-1" style={{ fontWeight: 500 }}>{bpTemplate.ProcessName}</span>
                                                <span className="pe-1"> started by <span className="pe-1" style={{ fontWeight: 500 }}>{(isNotNullAndUndefined(bpTemplate.Initiator) ? bpTemplate.Initiator : loggedinUserName)}</span></span>
                                                <span className="pe-1"> on <span style={{ fontWeight: 500 }}>{moment(new Date()).format("DD/MM/YYYY")}</span></span>
                                            </div>
                                        </div>*/}
                                    <Condition when="ProcessDeadlineCalculation" is={"Not set"}>
                                        <ACDatePicker<BusinessProcessTemplateRow>
                                            field="DueDate"
                                            label="Do you want to set deadline?"
                                        />
                                    </Condition>
                                    <Condition when="ProcessDeadlineCalculation" is={null}>
                                        <ACDatePicker<BusinessProcessTemplateRow>
                                            field="DueDate"
                                            label="Do you want to set deadline?"
                                        />
                                    </Condition>
                                </div>
                                <div className="e-dlg-footerContent ">
                                    <ButtonComponent
                                        type="submit"
                                        className="me-2"
                                        cssClass="e-flat e-info"
                                    >
                                        Start
                                    </ButtonComponent>
                                    <ButtonComponent
                                        type="button"
                                        cssClass="e-flat"
                                        onClick={() => startProcessDialogClose()}
                                    >
                                        Cancel
                                    </ButtonComponent>
                                </div>
                            </div>
                        </RAFForm>
                    </div>
                </Suspense>
            );
        } else {
            return <div></div>;
        }
    };
    //bp_dialog end

    if (isNotNullAndUndefined(state.accountRow)) {
        return (
            <div className="h-100" id={`raf_dlg_Outer_Div_${moduleName}`}>
                <Suspense fallback={<ACLoadingPanel loadingText="Loading..." />}>
                    <RAFEntityProvider moduleName={moduleName}>
                        <RAFAttributeRelatedListProvider moduleName={moduleName}>
                            <RAFPageLayoutProvider rafPageType={RAFPageType.Create}>
                                <RAFObjectContextProvider moduleName={moduleName} progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}>
                                    <RAFObjectContext.Consumer>
                                        {({ /*objectId, rafObject,*/ saveObject }) => {
                                            return (
                                                <Fragment>
                                                    <RAFForm
                                                        type={AccountRow}
                                                        initialValues={state.accountRow}
                                                        formRef={(g) => (rafForm = g)}
                                                        layout={RAFLayout.TwoColumnLayout}
                                                        // onSave={onSaveAccount}
                                                        //onSubmit={onSubmitAccount}
                                                        onSubmit={(values) => onSubmitAccount(values, saveObject)}
                                                        decorators={[accountDecorators]}
                                                        //convertBeforeSave={ConvertAccountRowToAccountDto}
                                                        className="h-100"
                                                    >
                                                        <div className="e-dlg-content-outer">
                                                            <div className="e-dlg-body-content">
                                                                <RAFPageLayout
                                                                    defaultComponent={
                                                                        <div>
                                                                            <ACTextBox<AccountRow>
                                                                                field="AccountName"
                                                                                label="Company Name"
                                                                                required
                                                                                placeholder="ABC Corp"
                                                                            />

                                                                            <ACDropdown<AccountRow>
                                                                                field="AccountType"
                                                                                label="Type"
                                                                                placeholder="Select Type"
                                                                                isColorOption
                                                                                uitype="colorpicker"
                                                                                moduleName={moduleName}
                                                                                createOptionMode={"Footer"}
                                                                            />

                                                                            <ACDropdown<AccountRow>
                                                                                field="Industry"
                                                                                label="Industry"
                                                                                placeholder="Select Industry"
                                                                                isColorOption
                                                                                uitype="colorpicker"
                                                                                moduleName={moduleName}
                                                                                createOptionMode={"Footer"}
                                                                            />

                                                                            <ACInputGroup<AccountRow>
                                                                                field="Phone"
                                                                                icon="fa fa-phone-alt"
                                                                                label="Phone"
                                                                                placeholder="+61 4 xxxx xxxx"
                                                                            />

                                                                            <ACEmailInputGroup<AccountRow>
                                                                                field="Email"
                                                                                icon="fas fa-envelope"
                                                                                label="E-Mail"
                                                                                placeholder="example@domain.com"
                                                                            />

                                                                            <ACTextBox<AccountRow>
                                                                                field="Website"
                                                                                label="Website"
                                                                                placeholder="www.domain.com"
                                                                            />

                                                                            <ACTextBox<AccountRow>
                                                                                field="NoOfEmployees"
                                                                                showLabel
                                                                                label="No of Employees"
                                                                                placeholder="500-600"
                                                                            />

                                                                            <div
                                                                                className="row align-items-center"
                                                                                id={"rafdivAddress"}
                                                                            >
                                                                                <div className="col-12 d-flex">
                                                                                    <label className="form-label">Address</label>
                                                                                </div>
                                                                                <div>
                                                                                    <RAFAddress
                                                                                        field1="AddressLine1"
                                                                                        field2="AddressLine2"
                                                                                        field3="AddressCity"
                                                                                        field4="AddressState"
                                                                                        field5="AddressCountry"
                                                                                        field6="AddressPostalCode"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <ACTextArea<AccountRow>
                                                                                field="Description"
                                                                                showLabel
                                                                                label="About company"
                                                                                placeholder="first business deal, turnover, establishments etc."
                                                                                rows={3}
                                                                            />
                                                                            {/*<RAFExtraInfo<AccountRow> field="ExtraInfoJson" label="Extra Info" />*/}
                                                                        </div>
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="e-dlg-footerContent ">
                                                                <RAFPermissionRender
                                                                    permissionName={AccountConstants.AccountCreate}
                                                                >
                                                                    <ButtonComponent
                                                                        className="me-2"
                                                                        cssClass="custom-button-md e-flat e-info"
                                                                        id={`btn_save_create_${moduleName}`}
                                                                    >
                                                                        Save
                                                                    </ButtonComponent>
                                                                </RAFPermissionRender>
                                                                {(IsSuperAdmin() === true) &&
                                                                    <RAFPermissionRender
                                                                        permissionName={AccountConstants.AccountUpdate}
                                                                    >
                                                                        <ButtonComponent
                                                                            type="button"
                                                                            onClick={() => onSaveOfflineClick(saveObject)}
                                                                            className="me-2"
                                                                            cssClass="e-flat e-info hidden"
                                                                            id={`btn_save_create_offline_${moduleName}`}
                                                                        >
                                                                            Save offline
                                                                        </ButtonComponent>
                                                                        <ButtonComponent
                                                                            type="button"
                                                                            cssClass="d-none form-custom-button e-flat e-info hidden"
                                                                            onClick={saveSyncClicked}
                                                                            id={`btn_sync_${moduleName}_withProcess`}
                                                                        >
                                                                            Save & Sync
                                                                        </ButtonComponent>
                                                                    </RAFPermissionRender>
                                                                }
                                                                <ButtonComponent
                                                                    type="button"
                                                                    cssClass="custom-button-md e-flat"
                                                                    onClick={props.onClose}
                                                                    id={`btn_cancel_create_${moduleName}`}
                                                                >
                                                                    Cancel
                                                                </ButtonComponent>
                                                            </div>
                                                        </div>
                                                    </RAFForm>

                                                    {state.showStartProcessContent && (
                                                        <DialogComponent
                                                            visible={state.showStartProcessContent}
                                                            cssClass="rightDialog createEditForm full-height form-center-dialog"
                                                            id="startBusinessProcessDialog"
                                                            content={startProcessContent.bind(this, saveObject)}
                                                            isModal
                                                            target="body"
                                                            closeOnEscape={false}
                                                            close={startProcessDialogClose.bind(this)}
                                                        ></DialogComponent>
                                                    )}
                                                </Fragment>
                                            );
                                        }}
                                    </RAFObjectContext.Consumer>
                                </RAFObjectContextProvider>
                            </RAFPageLayoutProvider>
                        </RAFAttributeRelatedListProvider>
                    </RAFEntityProvider>
                </Suspense>
            </div>
        );
    } else {
        return (
            <div></div>
        );
    }
}

export default CreateAccount; 