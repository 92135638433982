import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import React, { PropsWithChildren, useContext } from "react";
import { FormRenderProps } from "react-final-form";
import { msalInstance } from "../../../..";
import RAFChoiceOption from "../../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFForm from "../../../../RAFComponents/Inputs/RAFForm";
import RAFRadioButtonList from "../../../../RAFComponents/Inputs/RAFRadioButtonList";
import { showSuccessToast, showWarningToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import { hideProgress, removeSessionStorage, showProgress } from "../../../../RAFComponents/helpers/AppHelper";
import { getFocusListByRelatedUIDFocusType } from "../../../../RAFComponents/helpers/RAFMenuHelper";
import { IDialogProps, getSaveRequest, isNotNullAndUndefined, isNullOrUndefined } from "../../../../RAFComponents/helpers/utils";
import * as repositoryActions from '../../../../RAFComponents/store/actions/repositoryActions';
import { ContentType, RAFLayout, StorageKey } from "../../../../constants/Common/Constants";
import { FocusRow, RAFFocusUserType } from "../../../../pages/FocusRow";
import { CurrentWorkspaceContext } from "../../../Common/Providers/WorkspaceContextProvider";

interface IProps {
    relatedTo: string,
    relatedToType: string,
    relatedToUID: string,
    defaultFocuslabel?: 'focus list' | 'favourites';
}

function RAFAddToFocusFormContent({
    defaultFocuslabel = 'focus list', ...props }: PropsWithChildren<IProps & IDialogProps>) {
    let rafForm: FormRenderProps | null;
    const currentWorkspaceContext = useContext(CurrentWorkspaceContext);
    const currentWorkspace = isNotNullAndUndefined(currentWorkspaceContext) ? currentWorkspaceContext.currentWorkspace : null;

    const closeAddToFocusListDialog = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    const onSubmitFocusLisForm = (value: FocusRow) => {
        let focusRow: FocusRow = value;
        const loggedinUserName = msalInstance.currentUserName;
        const loggedinUserId = msalInstance.currentUserId;
        if (isNullOrUndefined(focusRow.FocusUserType)) {
            focusRow.FocusUserType = RAFFocusUserType.User;
        }
        focusRow.FocusUser = focusRow.FocusUserType === RAFFocusUserType.User ? loggedinUserName : isNotNullAndUndefined(currentWorkspace) ? currentWorkspace.PortalName : null;
        focusRow.FocusUserUID = focusRow.FocusUserType === RAFFocusUserType.User ? loggedinUserId : isNotNullAndUndefined(currentWorkspace) ? currentWorkspace.UID : null;

        let progressDiv = showProgress("#addToFocusForm_Content");
        getFocusListByRelatedUIDFocusType(focusRow.RelatedToType, focusRow.RelatedToUID, [focusRow.FocusUserType], [focusRow.FocusUserUID]).then((focusList) => {
            let isItemExist = isNotNullAndUndefined(focusList) && focusList.length > 0 ? true : false;
            if (isItemExist !== true) {
                repositoryActions.postDataAndGetResponse(
                    'UserFocus/Save',
                    getSaveRequest(focusRow, null),
                    null,
                    ContentType.applicationJson,
                ).then((response) => {
                    hideProgress(progressDiv);
                    if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.EntityId)) {
                        //let EntityId: string = response.data.EntityId as string;
                        removeSessionStorage(StorageKey.allUserFocus_MyList_modulename + props.relatedToType);
                        showSuccessToast(`Added to my ${defaultFocuslabel}`);
                        if (props.onSave) {
                            props.onSave();
                        }
                    }
                    else {
                        showWarningToast(`Unable to add to the ${defaultFocuslabel}. Please try again later.`);
                    }
                }).catch((error) => error);
            } else {
                hideProgress(progressDiv);
                showWarningToast("Item already exists");
            }
        });
    };

    if (props.isActive) {
        const { relatedTo, relatedToType, relatedToUID } = props;
        let focusRow: FocusRow = new FocusRow();
        focusRow.RelatedTo = relatedTo;
        focusRow.RelatedToType = relatedToType;
        focusRow.RelatedToUID = relatedToUID;
        focusRow.FocusUserType = RAFFocusUserType.User;
        return (
            <div>
                <RAFForm type={FocusRow} initialValues={focusRow} layout={RAFLayout.TwoColumnLayout}
                    onSubmit={onSubmitFocusLisForm.bind(this)}
                    formRef={(g) => rafForm = g}
                >
                    <div className="e-dlg-content-outer" id='addToFocusForm_Content'>
                        <div className="e-dlg-body-content">
                            <div className="w-100">
                                <RAFRadioButtonList<FocusRow> field="FocusUserType" required uitype={"default"} label="Type"
                                    showLabel={true} radioBtnClassName='col-auto'
                                    formGroupClassName='mb-0'
                                >
                                    <RAFChoiceOption label={`My ${defaultFocuslabel}`} value={RAFFocusUserType.User} />
                                    {isNotNullAndUndefined(currentWorkspace) && isNotNullAndUndefined(currentWorkspace.UID) && (
                                        <RAFChoiceOption label="Share with everyone" value={RAFFocusUserType.Portal} />
                                    )}
                                </RAFRadioButtonList>
                            </div>
                        </div>
                        <div className="e-dlg-footerContent ">
                            <ButtonComponent type='button' className="me-2" cssClass='e-flat e-info'
                                onClick={() => rafForm && rafForm.form.submit()}>Add</ButtonComponent>
                            <ButtonComponent type="button" cssClass='e-flat me-2 d-none d-sm-inline-block d-md-none d-xl-inline-block' onClick={() => closeAddToFocusListDialog()}>Cancel</ButtonComponent>
                        </div>
                    </div>
                </RAFForm>
            </div>
        );
    } else {
        return (
            <></>
        );
    }
}

export default React.memo(RAFAddToFocusFormContent);