import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogUtility } from "@syncfusion/ej2-react-popups";
import { Component } from "react";
import RAFCollapse from "../../../RAFComponents/Navigation/RAFCollapse";
import { showSuccessToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { ActivityListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import { ContentType, RAFLayout } from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../constants/Common/PermissionConstants";
import {
  getAllUsers,
  hideProgress,
  showProgress,
} from "./../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "./../../../RAFComponents/helpers/PermissionHelper";
import {
  getSaveRequest,
  isNotNullAndUndefined,
  propertyOf,
} from "./../../../RAFComponents/helpers/utils";
import { LookUpRow } from "./../../../RAFComponents/models/CompositeTypes/LookUpRow";
import * as repositoryActions from "./../../../RAFComponents/store/actions/repositoryActions";
import { getHeaderHeight } from "./../../../helpers/ACutils";
import { RAFActivityAction } from "./ActivityHelper";
import { ConvertActivityToActivityDto } from "./ActivityMappingRegister";
import { ActivityRow, IActivityDto } from "./ActivityRow";
import ActivityView from "./ActivityView";

interface IProps {
  relatedToUID?: string;
  entityName?: string;
  showRelatedTo?: boolean;
  secondaryRelatedToUID?: string;
  secondaryRelatedToType?: string;
  modulesOnclick?: (rowData: Object) => void;
  relatedCommentsUpdated?: () => void;
  relatedCommentDivKey?: number;
  showFirstComment?: boolean;
  showCollapsePanel?: boolean;
  uiMode?: "table";
}

class RelatedActivityList extends Component<IProps> {
  state = {
    activities: [],
    activity: {},
    usersList: [],
    activitySummaryKey: Math.random(),
    showFirstComment: isNotNullAndUndefined(this.props.showFirstComment)
      ? this.props.showFirstComment
      : false,
  };

  private deleteDialog: any;

  private relatedCommentListOuterDivId;

  _isMounted = false;

  constructor(props) {
    super(props);
    this.relatedCommentListOuterDivId = `relatedCommentListOuterDiv${
      isNotNullAndUndefined(this.props.secondaryRelatedToUID)
        ? this.props.secondaryRelatedToUID
        : isNotNullAndUndefined(this.props.relatedToUID)
        ? this.props.relatedToUID
        : ""
    }`;
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.FetchData();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, _prevState) {
    if (
      prevProps.relatedCommentDivKey !== this.props.relatedCommentDivKey &&
      isNotNullAndUndefined(this.props.relatedCommentDivKey)
    ) {
      this.FetchData();
    }
  }

  EditActivity = (activity: ActivityRow) => {
    let activityToCreate: ActivityRow = activity as ActivityRow;
    let activityToCreateto: IActivityDto =
      ConvertActivityToActivityDto(activityToCreate);
    //let progressDiv = showProgress('.activitiesItems');
    let progressDiv = showProgress("body", true);

    activityToCreateto.Action = RAFActivityAction.Modified;

    repositoryActions
      .postDataAndGetResponse(
        "Activity/Save",
        //"Activity/SaveStream",
        getSaveRequest(activityToCreateto, activityToCreateto.UID),
        { ...this.props },
        ContentType.applicationJson
      )
      .then((response) => {
        hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          if (isNotNullAndUndefined(response.data.EntityId)) {
            showSuccessToast("Comment updated", "Success!");
            if (
              isNotNullAndUndefined(this.props.entityName) &&
              this.props.entityName !== RAFEntityName.BusinessProcess
            ) {
              if (this.props.relatedCommentsUpdated) {
                this.props.relatedCommentsUpdated();
              }
            } else {
              this.FetchData();
            }
            let editDiv = document.querySelector(
              "#editableActivity_" + activity.UID + "" + this.props.relatedToUID
            );
            let summaryDiv = document.querySelector(
              "#summaryActivity_" + activity.UID + "" + this.props.relatedToUID
            );
            let editDeletebtn = document.querySelector(
              "#editDeletebtn_" + activity.UID + "" + this.props.relatedToUID
            );
            if (
              isNotNullAndUndefined(editDiv) &&
              isNotNullAndUndefined(summaryDiv)
            ) {
              editDiv.classList.add("hidden");
              summaryDiv.classList.remove("hidden");
              editDeletebtn.classList.remove("hidden");
            } else {
              editDiv.classList.remove("hidden");
              summaryDiv.classList.add("hidden");
            }
          } else if (
            response.data.Error !== null &&
            response.data.Error !== undefined &&
            response.data.Error !== ""
          ) {
            //console.log("Error on save", response.Error);
          }
        }
      })
      .catch((error) => error);
  };

  DeleteClicked = (id) => {
    if (this._isMounted) {
      this.setState({ hideEditDialog: true });
    }
    this.deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: this.DeleteActivity.bind(this, id) },
      title: " Delete Comment",
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
      // cssClass: "alert-dialog",
    });
  };

  DeleteActivity = (id) => {
    let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
    // let progressDiv = showProgress("body", true);
    repositoryActions
      .postDataAndGetResponse(
        "Activity/Delete",
        { EntityId: id },
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        hideProgress(progressDiv);

        if (isNotNullAndUndefined(response)) {
          this.deleteDialog.hide();
          if (
            isNotNullAndUndefined(this.props.entityName) &&
            this.props.entityName !== RAFEntityName.BusinessProcess
          ) {
            if (this.props.relatedCommentsUpdated) {
              this.props.relatedCommentsUpdated();
            }
          } else {
            this.FetchData();
          }
        }
      })
      .catch((error) => error);
  };

  getHeaderHeight = () => {
    getHeaderHeight();
  };

  FetchData() {
    let {
      secondaryRelatedToUID,
      relatedToUID,
      entityName,
      secondaryRelatedToType,
    } = this.props;
    let progressDiv = showProgress(
      `#${this.relatedCommentListOuterDivId}`,
      false,
      "staticProgressDiv"
    );
    let url = "Activity/List";

    const listRequest: ActivityListServiceRequest =
      new ActivityListServiceRequest();

    if (isNotNullAndUndefined(relatedToUID)) {
      listRequest.RelatedToUID = relatedToUID;
    }

    if (isNotNullAndUndefined(secondaryRelatedToUID)) {
      listRequest.SecondaryRelatedToUID = secondaryRelatedToUID;
    }

    if (isNotNullAndUndefined(entityName)) {
      listRequest.EntityName = entityName;
    }

    if (isNotNullAndUndefined(secondaryRelatedToType)) {
      listRequest.SecondaryRelatedToType = secondaryRelatedToType;
    }

    let sortByDate = {
      field: propertyOf<ActivityRow>("ModifiedDate"),
      order: "descending",
    };
    let sortQuery: string[] = [];
    if (
      isNotNullAndUndefined(sortByDate) &&
      isNotNullAndUndefined(sortByDate.field)
    ) {
      sortQuery.push(
        sortByDate.order === "descending"
          ? `${sortByDate.field} desc`
          : sortByDate.field
      );
    }

    listRequest.Sort = sortQuery;

    getAllUsers()
      .then((usersList) => {
        repositoryActions
          .postDataAndGetResponse(
            url,
            listRequest,
            null,
            ContentType.applicationJson
          )
          .then((response) => {
            hideProgress(progressDiv);
            this.getHeaderHeight();
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data)
            ) {
              if (this._isMounted) {
                this.setState({
                  activitySummaryKey: Math.random(),
                  activities: response.data.Entities,
                  usersList: usersList,
                });
              }
            }
          })
          .catch((error) => error);
      })
      .catch((error) => error);
  }

  moduleNameClick = (moduleUID) => {
    if (isNotNullAndUndefined(moduleUID)) {
      if (this.props.modulesOnclick) {
        this.props.modulesOnclick(moduleUID);
      }
    }
  };

  showAllActivitiesClicked = (mode: "show" | "hide") => {
    if (mode === "show") {
      this.setState({ showFirstComment: false });
    }
    if (mode === "hide") {
      this.setState({ showFirstComment: true });
    }
  };

  render() {
    const activities = [];
    const usersList: LookUpRow[] = this.state.usersList;
    let activitiesRows: Array<ActivityRow> = new Array<ActivityRow>();
    let uiMode = this.props.uiMode;
    if (this.state.activities !== null && this.state.activities.length > 0) {
      this.state.activities.forEach((activity) => {
        let objRow = usersList.find((x) => x.UID === activity.UserUID);
        if (isNotNullAndUndefined(objRow)) {
          activity.UserName = objRow && objRow.Value;
          activitiesRows.push(activity);
        }
      });

      if (
        isNotNullAndUndefined(activitiesRows.length) &&
        this.state.showFirstComment === true
      ) {
        let i = null;
        for (i = 0; i < 1; i++) {
          activities.push(
            <ActivityView
              key={i}
              activity={activitiesRows[i]}
              editActivity={(activityRow) => this.EditActivity(activityRow)}
              isEidtable
              deleteActivity={(id) => this.DeleteClicked(id)}
              uiMode={this.props.uiMode}
              permissionValue={null}
            ></ActivityView>
          );
        }
      } else if (
        isNotNullAndUndefined(activitiesRows.length) &&
        this.state.showFirstComment === false
      ) {
        let i = null;
        for (i = 0; i < activitiesRows.length; i++) {
          activities.push(
            <ActivityView
              key={i}
              activity={activitiesRows[i]}
              editActivity={(activityRow) => this.EditActivity(activityRow)}
              isEidtable
              deleteActivity={(id) => this.DeleteClicked(id)}
              uiMode={this.props.uiMode}
              permissionValue={null}
            ></ActivityView>
          );
        }
      } else {
        activities.push(<li></li>);
      }
    }
    let showCollapsePanel =
      isNotNullAndUndefined(this.props.showCollapsePanel) &&
      this.props.showCollapsePanel === false
        ? false
        : true;

    return (
      <div
        // id={"relatedCommentListOuterDiv"}
        id={this.relatedCommentListOuterDivId}
        key={this.state.activitySummaryKey}
      >
        {this.state.activities !== null &&
          this.state.activities.length > 0 &&
          (showCollapsePanel === true ? (
            <div className="e-card mb-3" id="comments">
              <div className="e-card-content py-0 px-0">
                <RAFCollapse
                  title="Comments"
                  iconName="Comments"
                  contentCardClassName="relatedListOuterDiv p-0"
                  layout={RAFLayout.OneColumnLayout}
                  IsCollapsed={false}
                  removeContentCardPadding
                  arrowPosistion="title"
                >
                  <div>
                    <ul
                      className={
                        isNotNullAndUndefined(uiMode) && uiMode === "table"
                          ? "activitiesItems activitiesTable"
                          : "activitiesItems"
                      }
                    >
                      <RAFPermissionRender
                        permissionName={PermissionConstants.CommentsRead}
                      >
                        {activities}
                      </RAFPermissionRender>
                    </ul>
                    <div className="text-center">
                      {this.props.showFirstComment === true &&
                        (this.state.showFirstComment ? (
                          <ButtonComponent
                            type="button"
                            cssClass="primary-custom-button mb-2"
                            content="Show all"
                            onClick={() =>
                              this.showAllActivitiesClicked("show")
                            }
                          ></ButtonComponent>
                        ) : (
                          <ButtonComponent
                            type="button"
                            cssClass="primary-custom-button mb-2"
                            content="Hide all"
                            onClick={() =>
                              this.showAllActivitiesClicked("hide")
                            }
                          ></ButtonComponent>
                        ))}
                    </div>
                  </div>
                </RAFCollapse>
              </div>
            </div>
          ) : (
            <div>
              <ul
                className={
                  isNotNullAndUndefined(uiMode) && uiMode === "table"
                    ? "activitiesItems activitiesTable"
                    : "activitiesItems"
                }
              >
                <RAFPermissionRender
                  permissionName={PermissionConstants.CommentsRead}
                >
                  {activities}
                </RAFPermissionRender>
              </ul>
              {this.props.showFirstComment === true &&
                (this.state.showFirstComment ? (
                  <div className="text-center">
                    <ButtonComponent
                      type="button"
                      cssClass="primary-custom-button mb-2"
                      content="Show all"
                      onClick={() => this.showAllActivitiesClicked("show")}
                    ></ButtonComponent>
                  </div>
                ) : (
                  <div className="text-center">
                    <ButtonComponent
                      type="button"
                      cssClass="primary-custom-button mb-2"
                      content="Hide all"
                      onClick={() => this.showAllActivitiesClicked("hide")}
                    ></ButtonComponent>
                  </div>
                ))}
            </div>
          ))}
      </div>
    );
  }
}
export default RelatedActivityList;
