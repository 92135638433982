import { AddressRow, AddressType } from "../../../components/shared/RAFAddress/AddressRow";
import { CountryRow } from "../../../components/shared/RAFAddress/CountryRow";
import { StateRow } from "../../../components/shared/RAFAddress/StateRow";
import { CityRow } from "../../../components/shared/RAFAddress/CityRow";
import { EmailRow, EmailType } from "../../../components/shared/RAFEmail/EmailRow";
import { PhoneRow, PhoneType } from "../../../components/shared/RAFPhone/PhoneRow";
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { ContactRow, IContactDto } from "./ContactRow";
import createDecorator from "final-form-calculate";

export function getAddressListFromModel(contactRow: ContactRow | IContactDto): AddressRow[] {
    let addressList: AddressRow[] = [];
    if (isNotNullAndUndefined(contactRow)) {
        if (isNotNullAndUndefined(contactRow.AddressLine1)) {
            const countryRow = new CountryRow();
            if (isNotNullAndUndefined(contactRow.AddressCountryUID)) {
                countryRow.UID = contactRow.AddressCountryUID.toString();
                countryRow.Country = contactRow.AddressCountry;
            }

            const stateRow = new StateRow();
            if (isNotNullAndUndefined(contactRow.AddressStateUID)) {
                stateRow.UID = contactRow.AddressStateUID.toString();
                stateRow.State = contactRow.AddressState;
            }

            const cityRow = new CityRow();
            if (isNotNullAndUndefined(contactRow.AddressCityUID)) {
                cityRow.UID = contactRow.AddressCityUID.toString();
                cityRow.City = contactRow.AddressCity;
            }

            addressList.push({
                AddressType: AddressType.PostalAddress,
                AddressLine1: contactRow.AddressLine1,
                AddressLine2: contactRow.AddressLine2,
                City: contactRow.AddressCity,
                PostalCode: contactRow.AddressPostalCode,
                State: contactRow.AddressState,
                Country: contactRow.AddressCountry,
            });
        }
    }
    return addressList;
}

//export function getKeyValueByAddressList(addressList: AddressRow[]) {
//    let postalAddressLine1 = null;
//    let postalAddressLine2 = null;
//    let postalPostcode = null;
//    let postalCountryUID = null;
//    let postalCountry = null;
//    let postalStateUID = null;
//    let postalState = null;
//    let postalCityUID = null;
//    let postalCity = null;
    

//    if (isNotNullAndUndefined(addressList) && addressList.length > 0) {
//        addressList.forEach((item, index) => {
//            if (isNotNullAndUndefined(item.AddressType)) {
//                    postalAddressLine1 = item.AddressLine1;
//                    postalAddressLine2 = item.AddressLine2;
//                postalPostcode = item.PostalCode;
//                if (isNotNullAndUndefined(item.City) && isNotNullAndUndefined(item.City.UID)) {
//                    postalCityUID = item.City.UID;
//                    postalCity = item.City.City;
//                }
//                if (isNotNullAndUndefined(item.Country) && isNotNullAndUndefined(item.Country.UID)) {
//                    postalCountryUID = item.Country.UID;
//                    postalCountry = item.Country.Country;
//                    }
//                    if (isNotNullAndUndefined(item.State) && isNotNullAndUndefined(item.State.UID)) {
//                        postalStateUID = item.State.UID;
//                        postalState = item.State.State;
//                    }
//            }
//        });
//    }

//    let retVal: { fieldName: keyof ContactRow, fieldValue: any }[] = [];
//    retVal.push(
//        { fieldName: "AddressLine1", fieldValue: postalAddressLine1 },
//        { fieldName: "AddressLine2", fieldValue: postalAddressLine2 },
//        { fieldName: "AddressPostalCode", fieldValue: postalPostcode },
//        { fieldName: "AddressCountryUID", fieldValue: postalCountryUID },
//        { fieldName: "AddressCountry", fieldValue: postalCountry },
//        { fieldName: "AddressStateUID", fieldValue: postalStateUID },
//        { fieldName: "AddressState", fieldValue: postalState },
//        { fieldName: "AddressCityUID", fieldValue: postalCityUID },
//        { fieldName: "AddressCity", fieldValue: postalCity },

//    );

//    return retVal;
//}

export function getPhoneListFromModel(contactRow: ContactRow | IContactDto): PhoneRow[] {
    let phoneList: PhoneRow[] = [];
    if (isNotNullAndUndefined(contactRow)) {
        if (isNotNullAndUndefined(contactRow.PhoneHome)) {
            phoneList.push({
                PhoneNumber: contactRow.PhoneHome, PhoneType: PhoneType.Home
            });
        }
        if (isNotNullAndUndefined(contactRow.PhonePersonal)) {
            phoneList.push({
                PhoneNumber: contactRow.PhonePersonal, PhoneType: PhoneType.Personal
            });
        }
        if (isNotNullAndUndefined(contactRow.PhoneWork)) {
            phoneList.push({
                PhoneNumber: contactRow.PhoneWork, PhoneType: PhoneType.Work
            });
        }
        if (isNotNullAndUndefined(contactRow.PhoneTollFree)) {
            phoneList.push({
                PhoneNumber: contactRow.PhoneTollFree, PhoneType: PhoneType.TollFree
            });
        }
        if (isNotNullAndUndefined(contactRow.PhoneFax)) {
            phoneList.push({
                PhoneNumber: contactRow.PhoneFax, PhoneType: PhoneType.Fax
            });
        }
    }
    return phoneList;
}

export function getKeyValueByPhoneList(phoneList: PhoneRow[]) {
    let homePhone = null;
    let personalPhone = null;
    let workPhone = null;
    let tollFree = null;
    let fax = null;

    if (isNotNullAndUndefined(phoneList) && phoneList.length > 0) {
        phoneList.forEach((item) => {
            if (isNotNullAndUndefined(item.PhoneType)) {
                if (item.PhoneType === PhoneType.Home) {
                    homePhone = item.PhoneNumber;
                }
                else if (item.PhoneType === PhoneType.Personal) {
                    personalPhone = item.PhoneNumber;
                }
                else if (item.PhoneType === PhoneType.Work) {
                    workPhone = item.PhoneNumber;
                }
                else if (item.PhoneType === PhoneType.TollFree) {
                    tollFree = item.PhoneNumber;
                }
                else if (item.PhoneType === PhoneType.Fax) {
                    fax = item.PhoneNumber;
                }
            }
        });
    }

    let retVal: { fieldName: keyof ContactRow, fieldValue: any }[] = [];
    retVal.push(
        { fieldName: "PhoneHome", fieldValue: homePhone },
        { fieldName: "PhonePersonal", fieldValue: personalPhone },
        { fieldName: "PhoneWork", fieldValue: workPhone },
        { fieldName: "PhoneTollFree", fieldValue: tollFree },
        { fieldName: "PhoneFax", fieldValue: fax }
    );
    return retVal;
}

export function getPhoneNumberByType(phoneList: PhoneRow[], phoneType: PhoneType) {
    let retVal = null;
    if (isNotNullAndUndefined(phoneList) && phoneList.length > 0 && isNotNullAndUndefined(phoneType)) {
        const obj1 = phoneList.find(x => isNotNullAndUndefined(x) && x.PhoneType === phoneType);
        if (isNotNullAndUndefined(obj1) && isNotNullAndUndefined(obj1.PhoneNumber))
            retVal = obj1.PhoneNumber;
    }
    return retVal;
}

export function getEmailListFromModel(contactRow: ContactRow | IContactDto): EmailRow[] {
    let emailList: EmailRow[] = [];
    if (isNotNullAndUndefined(contactRow)) {
        if (isNotNullAndUndefined(contactRow.EmailInfoPersonal)) {
            emailList.push({
                EmailId: contactRow.EmailInfoPersonal, EmailType: EmailType.Personal
            });
        }
        if (isNotNullAndUndefined(contactRow.EmailInfoWork)) {
            emailList.push({
                EmailId: contactRow.EmailInfoWork, EmailType: EmailType.Work
            });
        }
    }
    return emailList;
}

export function getKeyValueByEmailList(emailList: EmailRow[]) {
    let personalEmail = null;
    let workEmail = null;
    if (isNotNullAndUndefined(emailList) && emailList.length > 0) {
        emailList.forEach((item) => {
            if (isNotNullAndUndefined(item.EmailType)) {
                if (item.EmailType === EmailType.Personal) {
                    personalEmail = item.EmailId;
                }
                else if (item.EmailType === EmailType.Work) {
                    workEmail = item.EmailId;
                }
            }
        });
    }
    let retVal: { fieldName: keyof ContactRow, fieldValue: any }[] = [];
    retVal.push(
        { fieldName: "EmailInfoPersonal", fieldValue: personalEmail },
        { fieldName: "EmailInfoWork", fieldValue: workEmail },
    );
    return retVal;
}

export function getEmailIdByType(emailList: EmailRow[], emailType: EmailType) {
    let retVal = null;
    if (isNotNullAndUndefined(emailList) && emailList.length > 0 && isNotNullAndUndefined(emailType)) {
        const obj1 = emailList.find(x => isNotNullAndUndefined(x) && x.EmailType === emailType);
        if (isNotNullAndUndefined(obj1) && isNotNullAndUndefined(obj1.EmailId))
            retVal = obj1.EmailId;
    }
    return retVal;
}

export function getAddressPropertyByType(addressList: AddressRow[], addressType: AddressType, property: keyof AddressRow) {
    let retVal = null;
    if (isNotNullAndUndefined(addressList) && addressList.length > 0 && isNotNullAndUndefined(addressList)) {
        const obj = addressList.find(x => isNotNullAndUndefined(x) && x.AddressType === addressType);
        if (isNotNullAndUndefined(obj) && isNotNullAndUndefined(obj[property]))
            retVal = obj[property];
    }
    return retVal;
}

export const contactDecorators = createDecorator(
    {
        field: 'PhoneList',
        updates: {
            PhoneHome: (minimumValue, allValues: ContactRow) => getPhoneNumberByType(allValues.PhoneList, PhoneType.Home),
            PhonePersonal: (minimumValue, allValues: ContactRow) => getPhoneNumberByType(allValues.PhoneList, PhoneType.Personal),
            PhoneWork: (minimumValue, allValues: ContactRow) => getPhoneNumberByType(allValues.PhoneList, PhoneType.Work),
            PhoneTollFree: (minimumValue, allValues: ContactRow) => getPhoneNumberByType(allValues.PhoneList, PhoneType.TollFree),
            PhoneFax: (minimumValue, allValues: ContactRow) => getPhoneNumberByType(allValues.PhoneList, PhoneType.Fax)
        }
    },
    {
        field: 'EmailList',
        updates: {
            EmailInfoPersonal: (minimumValue, allValues: ContactRow) => getEmailIdByType(allValues.EmailList, EmailType.Personal),
            EmailInfoWork: (minimumValue, allValues: ContactRow) => getEmailIdByType(allValues.EmailList, EmailType.Work),
        }
    },
    {
        field: 'AddressList',
        updates: {
            AddressLine1: (minimumValue, allValues: ContactRow) => getAddressPropertyByType(allValues.AddressList, AddressType.PostalAddress, "AddressLine1"),
            AddressLine2: (minimumValue, allValues: ContactRow) => getAddressPropertyByType(allValues.AddressList, AddressType.PostalAddress, "AddressLine2"),
            AddressCity: (minimumValue, allValues: ContactRow) => getAddressPropertyByType(allValues.AddressList, AddressType.PostalAddress, "City"),
            //AddressCityUID: (minimumValue, allValues: ContactRow) => getAddressPropertyByType(allValues.AddressList, AddressType.PostalAddress, "CityUID"),
            AddressState: (minimumValue, allValues: ContactRow) => getAddressPropertyByType(allValues.AddressList, AddressType.PostalAddress, "State"),
           // AddressStateUID: (minimumValue, allValues: ContactRow) => getAddressPropertyByType(allValues.AddressList, AddressType.PostalAddress, "StateUID"),
            AddressCountry: (minimumValue, allValues: ContactRow) => getAddressPropertyByType(allValues.AddressList, AddressType.PostalAddress, "Country"),
           // AddressCountryUID: (minimumValue, allValues: ContactRow) => getAddressPropertyByType(allValues.AddressList, AddressType.PostalAddress, "CountryUID"),
            AddressPostalCode: (minimumValue, allValues: ContactRow) => getAddressPropertyByType(allValues.AddressList, AddressType.PostalAddress, "PostalCode"),
        }
    },
)