import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent, DialogUtility } from "@syncfusion/ej2-react-popups";
import { Component, Fragment } from "react";
import { msalInstance } from "../../../..";
import RAFFieldStateProvider from "../../../../RAFComponents/Grid/RAFFieldStateProvider";
import RAFGridColumn from "../../../../RAFComponents/Grid/RAFGridColumn";
import RAFReportDataList, {
  RAFReportDataListContext
} from "../../../../RAFComponents/List/RAFReportDataList";
import RAFCollapse from "../../../../RAFComponents/Navigation/RAFCollapse";
import RAFDataResultProvider from "../../../../RAFComponents/Providers/RAFDataResultProvider";
import {
  RAFCustomFilter,
  RAFCustomOperator
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { createInstance } from "../../../../RAFComponents/Utility/FormUtility";
import { showSuccessToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  hideProgress,
  showProgress
} from "../../../../RAFComponents/helpers/AppHelper";
import { getFocusListByRelatedUID, removeFromFocusList } from "../../../../RAFComponents/helpers/RAFMenuHelper";
import {
  isNotNullAndUndefined,
  propertyOf
} from "../../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import { BusinessProcessStepRow } from "../../../../RAFMaster/RMModules/BusinessProcess/Step/BusinessProcessStepRow";
import { BusinessProcessStepTemplateRow } from "../../../../RAFMaster/RMModules/BusinessProcess/StepTemplate/BusinessProcessStepTemplateRow";
import { CompleteTaskAndStep } from "../../../../RAFMaster/RMModules/BusinessProcess/helpers/ProcessHelper";
import {
  Constants,
  ContentType,
  RAFActionStatus,
  RAFLayout
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import {
  addToFocusList
} from "../../../../helpers/ACutils";
import RAFGridTemplates from "../../../../helpers/RAFGridTemplates";
import { NavigateParamsProps, withRouter } from "../../../../router";
import { MyTeamsContext } from "../../../Common/Providers/MyTeamsContextProvider";
import { BusinessProcessRow } from "../../BusinessProcess/BusinessProcessRow";
import ManageTask from "../../Task/ManageTask";
import TaskCardView2 from "../../Task/TaskCardView2";
import { TaskGridNoCompletedDateColumnTemplates } from "../../Task/TaskGridTemplateHelper";
import { completeTaskAndRemoveFromFocusList } from "../../Task/TaskHelper";
import { ConvertTaskRowToTaskDto } from "../../Task/TaskMappingRegister";
import { TaskRow } from "../../Task/TaskRow";
import { UserGroupRow } from "../../UserGroup/UserGroupRow";

interface IProps {
  businessProcessStepRows?: BusinessProcessStepRow[];
  businessProcessStepRow?: BusinessProcessStepRow;
  businessProcessRow?: BusinessProcessRow;
  onSave?: (values) => void;
  onBPStepSave?: (
    allBPRows?: BusinessProcessStepRow[],
    dependentBPRows?: BusinessProcessStepRow[],
    currentBPStepRow?: BusinessProcessStepRow,
    businessProcessRow?: BusinessProcessRow
  ) => void;
  bpTemplateStepsRow?: BusinessProcessStepTemplateRow[];
  objectData?: any;
  relatedTaskUpdated?: () => void;
  hideRelateTaskSections?: boolean;
  relatedTaskDivKey?: number;
  relatedDocumnetDivKey?: number;
  relatedCommentDivKey?: number;
  showEmptyState?: boolean;
}

interface IState {
  relatedTaskList: TaskRow[];
  showCreateTaskContent?: boolean;
  showTaskDetailsContent?: boolean;
  taskUID?: string;
  takSummaryKey?: number;
  relatedTaskCount?: number;
}

class RelatedTaskList extends Component<NavigateParamsProps & IProps, IState> {
  _isMounted = false;
  private deleteFocusListDialog: any;
  private moduleName: string = createInstance(
    BusinessProcessStepRow
  ).getClassName();
  private colmpleteTaskAlertDialog: any;
  private myTeams: UserGroupRow[] = [];
  static defaultProps: { showEmptyState: boolean; };

  constructor(props) {
    super(props);
    this.state = {
      relatedTaskList: [],
      showCreateTaskContent: false,
      showTaskDetailsContent: false,
      takSummaryKey: Math.random(),
      relatedTaskCount: null,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount = () => {
    this._isMounted = true;
    //this.refresh();
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.relatedTaskDivKey !== this.props.relatedTaskDivKey &&
      isNotNullAndUndefined(this.props.relatedTaskDivKey)
    ) {
      if (this._isMounted) {
        if (this._isMounted) {
          this.setState({
            takSummaryKey: Math.random(),
            relatedTaskCount: null,
          });
        }
      }
    }
  }

  refresh = () => {
    if (this._isMounted) {
      this.setState({ takSummaryKey: Math.random(), relatedTaskCount: null });
    }
  };

  getTaskList = () => {
    return new Promise<TaskRow[]>((resolve) => {
      let { businessProcessStepRow } = this.props;
      let progressDiv = showProgress(
        "#relatedTaskListOuterDiv_" + this.state.takSummaryKey,
        false,
        "staticProgressDiv"
      );
      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];

      let relatedToFilter: RAFCustomFilter = {};
      let relatedToFilterVal: string[] = [];
      relatedToFilterVal.push(businessProcessStepRow.UID);
      relatedToFilter.Operator = RAFCustomOperator.Equal;
      relatedToFilter.Value = relatedToFilterVal;
      relatedToFilter.Field = propertyOf<TaskRow>("SecondaryRelatedToUID");
      customFilter.Rules.push(relatedToFilter);

      let relatedToFilter2: RAFCustomFilter = {};
      let relatedToFilterVal2: string[] = [];
      relatedToFilterVal2.push(businessProcessStepRow.UID);
      relatedToFilter2.Operator = RAFCustomOperator.IsNull;
      //relatedToFilter2.Value = relatedToFilterVal2;
      relatedToFilter2.Field = propertyOf<TaskRow>("BPStepUID");
      customFilter.Rules.push(relatedToFilter2);

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = customFilter;
      //listServiceRequest.ViewName = "all_tasks";

      repositoryActions
        .postDataAndGetResponse(
          "Task/RelatedList",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        });
    });
  };

  rowClick = (e: Object) => {
    let taskRow: TaskRow = e as TaskRow;
    this.taskDetailsDialogOpen(taskRow.UID);
    //if (taskRow.TaskStatus === RAFActionStatus.Completed) {
    //    showWarningToast('This task has been completed')
    //} else {
    //    this.taskDetailsDialogOpen(taskRow.UID);
    //}
  };

  taskDetailsDialogOpen = (id?: string) => {
    if (this._isMounted) {
      this.setState({ showTaskDetailsContent: true, taskUID: id }, () => {
        document.body.classList.add("overflow-hidden");
      });
    }
  };

  taskDetailsDialogClose = () => {
    if (this._isMounted) {
      this.setState({ showTaskDetailsContent: false });
    }
  };

  taskDetailsContent = () => {
    let businessProcessStepRow = this.props.businessProcessStepRow;
    let businessProcessRow = this.props.businessProcessStepRow;
    if (
      isNotNullAndUndefined(this.state.showTaskDetailsContent) &&
      this.state.showTaskDetailsContent === true
    ) {
      return (
        <ManageTask
          onSave={() => this.refreshOnUpdateTask()}
          onClose={this.taskDetailsDialogClose.bind(this)}
          taskUID={this.state.taskUID}
          complete={(taskRow, taskStatus) =>
            this.CompleteTaskByStatus(taskRow, taskStatus)
          }
          secondaryRelatedToType={RAFEntityName.BusinessProcessStep}
          secondaryRelatedToUID={businessProcessStepRow.UID}
          secondaryRelatedTo={businessProcessStepRow.Title}
          relatedToType={businessProcessRow.RelatedToType}
          relatedToUID={businessProcessRow.RelatedToUID}
          relatedTo={businessProcessRow.RelatedTo}
          isActive
          isDashboard={false}
          // hideHeader
          {...this.props}
        />
      );
    } else {
      return <div></div>;
    }
  };

  refreshOnUpdateTask = () => {
    if (this._isMounted) {
      this.setState({ showTaskDetailsContent: false }, () => {
        if (this.props.relatedTaskUpdated) {
          this.props.relatedTaskUpdated();
        }
        let relatedTaskListOuterDiv = document.getElementById(
          "relatedTaskListOuterDiv_" + this.state.takSummaryKey
        );
        if (isNotNullAndUndefined(relatedTaskListOuterDiv) && this.state.relatedTaskCount === 1 && this.props.showEmptyState !== true) {
          relatedTaskListOuterDiv.classList.add("hidden");
          //relatedTaskListOuterDiv.classList.add("fade-out");
        }
        this.refresh();
      });
    }
  };

  CompleteTask = (selectedTaskRow: TaskRow, taskStatus: string) => {
    let isMyTeamExist = isNotNullAndUndefined(selectedTaskRow.AssignTeamUID)
      ? this.myTeams.find((x) => x.UID === selectedTaskRow.AssignTeamUID)
      : null;
    if (
      (isNotNullAndUndefined(selectedTaskRow.AssigneeUID) &&
        selectedTaskRow.AssigneeUID === msalInstance.currentUserId) ||
      (isNotNullAndUndefined(selectedTaskRow.AssignTeamUID) &&
        isNotNullAndUndefined(isMyTeamExist))
    ) {
      this.CompleteTaskByStatus(selectedTaskRow, taskStatus);
    } else {
      this.ColmpleteTaskAlert(selectedTaskRow, taskStatus);
    }
  };

  ColmpleteTaskAlert(taskRow: TaskRow, taskStatus: string) {
    this.colmpleteTaskAlertDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content:
        "Are you sure you want to complete this task assigned to another user?",
      okButton: {
        text: "Yes",
        click: this.CompleteTaskByStatus.bind(this, taskRow, taskStatus),
      },
      showCloseIcon: true,
      title: " Complete Task",
      position: { X: "center", Y: "center" },
      cssClass: "alert-dialog",
    });
  }

  CompleteTaskByStatus = (selectedTaskRow: TaskRow, taskStatus: string) => {
    this.colmpleteTaskAlertDialog && this.colmpleteTaskAlertDialog.hide();
    let taskRow = ConvertTaskRowToTaskDto(selectedTaskRow);
    let progressDiv = showProgress("body", true);
    let businessProcessStepRow: BusinessProcessStepRow =
      this.props.businessProcessStepRow;
    let businessProcessStepRows: BusinessProcessStepRow[] =
      this.props.businessProcessStepRows;
    let businessProcessRow: BusinessProcessRow = this.props.businessProcessRow;
    let bpTemplateStepsRow: BusinessProcessStepTemplateRow[] =
      this.props.bpTemplateStepsRow;
    let objectData = this.props.objectData;
    if (
      isNotNullAndUndefined(taskRow.BPStepUID) &&
      isNotNullAndUndefined(businessProcessStepRow) &&
      businessProcessStepRow.UID === taskRow.BPStepUID
    ) {
      CompleteTaskAndStep(
        businessProcessStepRow,
        businessProcessStepRows,
        businessProcessRow,
        bpTemplateStepsRow,
        objectData,
        taskStatus
      ).then((result) => {
        hideProgress(progressDiv);
        this.taskDetailsDialogClose();
        this.refresh();
        if (this.props.onBPStepSave) {
          this.props.onBPStepSave(
            result.allBPRows,
            result.dependentBPRows,
            businessProcessStepRow,
            businessProcessRow
          );
        }
      });
    } else {
      taskRow.TaskStatus = taskStatus;

      completeTaskAndRemoveFromFocusList(taskRow).then((response) => {
        hideProgress(progressDiv);
        //if (isNotNullAndUndefined(response)) {
        this.taskDetailsDialogClose();
        this.refresh();

        if (this.props.relatedTaskUpdated) {
          this.props.relatedTaskUpdated();
        }
        //}
      })
        .catch((error) => error);
    }
  };

  onFocusClicked = (relatedTo: string, relatedUID: string) => {
    getFocusListByRelatedUID(relatedUID).then((isFocused) => {
      if (isFocused) {
        this.RemoveFromFocusList(relatedUID);
      } else {
        this.AddToFocusList(relatedTo, relatedUID);
      }
    });
  };

  AddToFocusList = (relatedTo: string, relatedUID: string) => {
    if (isNotNullAndUndefined(relatedTo) && isNotNullAndUndefined(relatedUID)) {
      addToFocusList(relatedTo, this.moduleName, relatedUID).then(
        (response) => {
          if (isNotNullAndUndefined(response)) {
            showSuccessToast("Added to my focus list");
            this.refresh();
          }
        }
      );
    }
  };

  RemoveFromFocusList = (relatedUID: string) => {
    this.deleteFocusListDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Already added in focus list! Are you sure want to remove?",
      okButton: {
        text: "Yes",
        click: this.onRemoveFocusList.bind(this, relatedUID),
      },
      showCloseIcon: true,
      title: "Remove Item",
      position: { X: "center", Y: "center" },
      cssClass: "alert-dialog e-small",
    });
  };

  onRemoveFocusList = (relatedUID: string) => {
    removeFromFocusList(relatedUID).then((response) => {
      if (isNotNullAndUndefined(response)) {
        showSuccessToast("Removed from my focus list");
        this.deleteFocusListDialog.hide();
        this.refresh();
      }
    });
  };

  setRelatedTaskCount = (relatedTaskCount?: number) => {
    if (relatedTaskCount !== this.state.relatedTaskCount) {
      if (this._isMounted) {
        this.setState({ relatedTaskCount }, () => {
          let relatedTaskListOuterDiv = document.getElementById(
            "relatedTaskListOuterDiv_" + this.state.takSummaryKey
          );
          if (isNotNullAndUndefined(relatedTaskListOuterDiv)) {
            if (relatedTaskCount > 0) {
              relatedTaskListOuterDiv.classList.remove("hidden");
              //relatedTaskListOuterDiv.classList.add("fade-in");
            } else if (this.props.showEmptyState !== true) {
              relatedTaskListOuterDiv.classList.add("hidden");
              //relatedTaskListOuterDiv.classList.add("fade-out");
            }
          }
        });
      }
    }
  };

  render() {
    let { businessProcessStepRow } = this.props;
    let addTaskBtn = (
      <div>
        <ButtonComponent
          type="button"
          cssClass="e-small d-none primary-custom-button d-sm-inline-block"
          iconCss="fas fa-plus"
          onClick={() => this.taskDetailsDialogOpen()}
        >
          Add
        </ButtonComponent>
        <ButtonComponent
          type="button"
          cssClass="e-small primary-custom-button d-sm-none"
          iconCss="fas fa-plus"
          onClick={() => this.taskDetailsDialogOpen()}
        ></ButtonComponent>
      </div>
    );
    return (
      <MyTeamsContext.Consumer>
        {({ teams }) => {
          this.myTeams = teams;
          return (
            <div
              id={"relatedTaskListOuterDiv_" + this.state.takSummaryKey}
            >
              <div className="e-card p-0 border-0" id="tasks">
                <div
                  className="e-card-content py-0 px-0"
                  key={this.state.takSummaryKey}
                >
                  <RAFDataResultProvider>
                    <RAFCollapse
                      title="Tasks"
                      iconName="tasks"
                      contentCardClassName="relatedListOuterDiv relatedTaskList"
                      layout={RAFLayout.OneColumnLayout}
                      IsCollapsed={false}
                      customButton={addTaskBtn}
                      arrowPosistion="title"
                      removeContentCardPadding
                      showSectionHeader={false}
                    >
                      <RAFReportDataList
                        url={`${Constants.baseAPIUrl}Task/RelatedList`}
                        //viewName="all_tasks"
                        additionalFilter={{
                          Condition: "and",
                          Rules: [
                            // {
                            //     Operator: RAFCustomOperator.Equal,
                            //     Value: [businessProcessStepRow.UID],
                            //     Field: propertyOf<TaskRow>(
                            //         "SecondaryRelatedToUID"
                            //     ),
                            // },
                            {
                              Operator: RAFCustomOperator.IsNull,
                              Value: [businessProcessStepRow.UID],
                              Field: propertyOf<TaskRow>("BPStepUID"),
                            },
                          ],
                        }}
                        additionalParams={[
                          //{ key: 'RelatedField', value: propertyOf<TaskRow>("BPStepUID") },
                          //{ key: 'RelatedFieldValue', value: businessProcessStepRow.UID },
                          {
                            key: "SecondaryRelatedField",
                            value: propertyOf<TaskRow>("SecondaryRelatedToUID"),
                          },
                          {
                            key: "SecondaryRelatedFieldValue",
                            value: businessProcessStepRow.UID,
                          },
                        ]}
                        isRelated
                      >
                        <RAFFieldStateProvider moduleName={"task"}>
                          <RAFGridTemplates>
                            <TaskCardView2
                              gridId={"TaskRow1"}
                              // data={{
                              //     data: {
                              //         result: this.state.relatedDocumentList,
                              //         count: this.state.relatedDocumentList.length,
                              //     },
                              // }}
                              entityType={TaskRow}
                              isRemote
                              rowClick={this.rowClick}
                              allowEditing={false}
                              allowSelection={false}
                              allowFiltering={false}
                              gridTemplates={
                                TaskGridNoCompletedDateColumnTemplates
                              }
                              //filterType="FilterBar"
                              //relatedModuleClick={(taskRow) => this.onRelatedToClick(taskRow)}
                              completeClicked={(taskRow) =>
                                this.CompleteTask(
                                  taskRow,
                                  RAFActionStatus.Completed
                                )
                              }
                              onFocusClicked={() =>
                                this.onFocusClicked(
                                  businessProcessStepRow.Title,
                                  businessProcessStepRow.UID
                                )
                              }
                              emptyStateProps={{
                                title: "No data available at the moment!",
                                body: "Please enjoy your day!",
                                // image: dashboard_upcoming_es,
                              }}
                              ///showRelatedToField={showRelatedToField}
                              showAssigneeField
                              showStatusField
                              showCompletedDateField
                            >
                              <RAFGridColumn<TaskRow>
                                field="TaskDate"
                                headerText="Date"
                                minWidth="95px"
                                maxWidth="130px"
                                width="130px"
                              />
                              <RAFGridColumn<TaskRow>
                                field="Title"
                                headerText="Title"
                                width="150px"
                              />
                              <RAFGridColumn<TaskRow>
                                field="Assignee"
                                headerText="Assigned To"
                                width="300px"
                              />
                              {/* <RAFGridColumn<TaskRow>
                                field="TaskStatus"
                                headerText="Status"
                                width="150px"
                              />
                              <RAFGridColumn<TaskRow>
                                field="CompletedDate"
                                headerText="Completed on"
                                width="150px"
                              />
                              <RAFGridColumn<TaskRow>
                                field="ModifiedDate"
                                headerText="Updated"
                                width="150px"
                              /> */}
                            </TaskCardView2>
                          </RAFGridTemplates>
                        </RAFFieldStateProvider>
                        <Fragment>
                          <RAFReportDataListContext.Consumer>
                            {({ data }) => {
                              if (
                                isNotNullAndUndefined(data) &&
                                isNotNullAndUndefined(data.data) &&
                                isNotNullAndUndefined(data.data.count)
                              ) {
                                this.setRelatedTaskCount(data.data.count);
                              }
                              return <div></div>;
                            }}
                          </RAFReportDataListContext.Consumer>
                          {this.state.showTaskDetailsContent && (
                            <DialogComponent
                              visible={this.state.showTaskDetailsContent}
                              cssClass="rightDialog createEditForm full-height"
                              id="taskUpdateDialog"
                              content={this.taskDetailsContent.bind(this)}
                              isModal
                              target="body"
                              closeOnEscape={false}
                              close={() => this.taskDetailsDialogClose()}
                            />
                          )}
                        </Fragment>
                      </RAFReportDataList>
                    </RAFCollapse>
                  </RAFDataResultProvider>
                </div>
              </div>
            </div>
          );
        }}
      </MyTeamsContext.Consumer>
    );
  }
}

RelatedTaskList.defaultProps = {
  showEmptyState: true,
};

RelatedTaskList.contextType = RAFReportDataListContext;
export default withRouter(RelatedTaskList);
