import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { PropsWithChildren, Reducer, useContext, useEffect, useReducer } from "react";
import { FormRenderProps } from "react-final-form";
import { useRecoilValue } from "recoil";
import RAFCheckBox from "../../../RAFComponents/Inputs/RAFCheckBox";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFTextBox from "../../../RAFComponents/Inputs/RAFTextBox";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { createInstance } from "../../../RAFComponents/Utility/FormUtility";
import { showSuccessToast, showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { isCurrentTenantCareESIOPartner } from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
    IDialogProps,
    IsNullOrWhiteSpace, RAFFormComponentProps,
    isNotNullAndUndefined
} from "../../../RAFComponents/helpers/utils";
import nodata_es from "../../../assets/ES/nodata_es.svg";
import ACDropdown from "../../../components/shared/ACFormFields/ACDropdown";
import ACEmail from "../../../components/shared/ACFormFields/ACEmail";
import ACLookup from "../../../components/shared/ACFormFields/ACLookup";
import ACPhone from "../../../components/shared/ACFormFields/ACPhone";
import ACTextArea from "../../../components/shared/ACFormFields/ACTextArea";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import RAFAddress from "../../../components/shared/RAFAddress/RAFAddress";
import { ContactConstants } from "../../../constants/ActiveContact/ACPermissionConstants";
import {
    Constants,
    RAFLayout
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import {
    RAFPageType
} from "../../../constants/Common/RMConstants";
import { NavigateParamsProps } from "../../../router";
import { atomEditingRecordId } from "../../Common/List/IndexHelper";
import RAFEntityProvider, { RAFEntityContext } from "../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
import RAFPageLayoutProvider from "../../Common/Providers/RAFPageLayoutProvider";
import RAFPageLayout from "../../Common/RAFPageLayout";
import CreateAccount from "../Account/CreateAccount";
import { contactDecorators } from "./ContactHelper";
import {
    ConvertContactRowToContactDto
} from "./ContactMappingRegister";
import { ContactRow, IContactDto } from "./ContactRow";
import RAFLookUpMUI from "../../../RAFComponents/Inputs/RAFLookUpMUI";

interface IState {
    isActive?: boolean;
    noContent?: boolean;
    //contactRow?: ContactRow;
}

interface IProps {
    objectUID?: string;
    objectData?: string;
    contact?: ContactRow;
    onProcessStart?: (relatedUID?: string) => void;
}

function UpdateContact({
    isActive,
    objectUID,
    //contact,
    ...props
}: PropsWithChildren<IDialogProps &
    RAFFormComponentProps &
    NavigateParamsProps &
    IProps>) {
    const entityRow = useContext(RAFEntityContext);

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isActive: false,
            noContent: false,
            //contactRow: null,
        }
    );

    const moduleName: string = createInstance(ContactRow).getClassName();
    let rafForm: FormRenderProps | null;

    let contactUID = useRecoilValue(atomEditingRecordId(moduleName));
    objectUID = isNotNullAndUndefined(contactUID) ? contactUID : isNotNullAndUndefined(objectUID) ? objectUID : (isNotNullAndUndefined(props.contact) && isNotNullAndUndefined(props.contact.UID)) ? props.contact.UID : null;

    useEffect(() => {
        //console.log('UpdateAccount', objectUID);
        refresh(isActive, objectUID, props.contact);
    }, [isActive, objectUID, props.contact]);

    function refresh(isActive: boolean, id?: string, contact?: ContactRow) {
        if (isNotNullAndUndefined(contact) && isActive) {
            setState({
                isActive: true,
                noContent: false,
                //contactRow: contact
            });
        } else if (isNotNullAndUndefined(id) && isActive) {
            setState({
                isActive: true,
                noContent: false
            });
            //getContact(id);
        } else {
            setState({
                isActive: false,
            });
        }
    }

    // function getContact1(id) {
    //     const entityUID = isNotNullAndUndefined(entityRow) && isNotNullAndUndefined(entityRow.entity) ? entityRow.entity.UID : null;
    //     RetrieveRecord(id, moduleName, entityUID).then((response) => {
    //         if (isNotNullAndUndefined(response)) {
    //             const responseDto: IContactDto = response as IContactDto;
    //             let contactRow: ContactRow =
    //                 ConvertContactDtoToContactRow(responseDto);
    //             setState({
    //                 noContent: false,
    //                 contactRow: contactRow
    //             });
    //         } else {
    //             setState({
    //                 noContent: true,
    //                 contactRow: null
    //             });
    //         }
    //     })
    //         .catch((error) => error);
    // }

    if (state.isActive) {
        const onSubmitContact = (value,
            saveObject?: (
                objectData?: any
            ) => Promise<{ objectUID?: string; objectName?: string; }>
        ) => {
            let objValues: ContactRow = value as ContactRow;
            let contactRow: IContactDto = ConvertContactRowToContactDto(objValues);
            // let progressDiv = showProgress(`#dlg_update_${moduleName}.e-popup-open`);
            let url = "Contact/Save";
            /*objValues.BusinessProcess = null;
                objValues.BusinessProcessUID = null;*/
            saveObject(contactRow).then((response) => {
                //  hideProgress(progressDiv);

                if (
                    isNotNullAndUndefined(response) &&
                    !IsNullOrWhiteSpace(response.objectUID)
                ) {
                    showSuccessToast("Saved successfully");
                    setState({ isActive: false });
                    if (props.onSave) {
                        props.onSave(response.objectUID, response.objectName);
                    }
                } else {
                    showWarningToast("Sorry something went wrong !");
                }
            })
                .catch((error) => error);
        };

        if (
            (!IsNullOrWhiteSpace(objectUID) ||
                (isNotNullAndUndefined(props.contact) &&
                    isNotNullAndUndefined(props.contact.UID)))
        ) {
            return (
                <div className="update-page h-100" key={`${objectUID}_${moduleName}`} id={`raf_dlg_Outer_Div_${moduleName}`}>
                    <RAFEntityProvider moduleName={moduleName}>
                        <RAFAttributeRelatedListProvider moduleName={moduleName}>
                            <RAFPageLayoutProvider rafPageType={RAFPageType.Edit}>
                                <RAFObjectContextProvider
                                    moduleName={moduleName}
                                    objectId={objectUID}
                                    progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                                >
                                    <RAFObjectContext.Consumer>
                                        {({ /*objectId, */ rafObject, error, saveObject }) => {
                                            if (
                                                isNotNullAndUndefined(rafObject) &&
                                                !IsNullOrWhiteSpace(rafObject.UID)
                                            ) {
                                                return (
                                                    <RAFForm
                                                        type={ContactRow}
                                                        initialValues={rafObject}
                                                        formRef={(g) => (rafForm = g)}
                                                        layout={RAFLayout.TwoColumnLayout}
                                                        onSubmit={(value) => onSubmitContact(value, saveObject)}
                                                        decorators={[contactDecorators]}
                                                        primaryKey={rafObject.UID}
                                                        convertBeforeSave={ConvertContactRowToContactDto}
                                                        className="h-100"
                                                    >
                                                        <div className="e-dlg-content-outer">
                                                            <div className="e-dlg-body-content">
                                                                <RAFPageLayout
                                                                    defaultComponent={
                                                                        <div>
                                                                            <div className="row align-items-center g-0">
                                                                                <div className="col-12 d-flex">
                                                                                    <label className="form-label required">
                                                                                        Name{" "}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <div className="w-100">
                                                                                        <div className="row gx-3">
                                                                                            <div className="col-md-6">
                                                                                                <RAFTextBox<ContactRow>
                                                                                                    field="FirstName"
                                                                                                    showLabel={false}
                                                                                                    label="First Name"
                                                                                                    placeholder="First Name"
                                                                                                    required
                                                                                                />
                                                                                            </div>
                                                                                            <div className="col">
                                                                                                <RAFTextBox<ContactRow>
                                                                                                    field="LastName"
                                                                                                    showLabel={false}
                                                                                                    label="Last Name"
                                                                                                    placeholder="Last Name"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <ACTextBox<ContactRow>
                                                                                field="Designation"
                                                                                label="Designation"
                                                                                placeholder="Marketing Manager"
                                                                            />
                                                                            <RAFLookUpMUI<ContactRow>
                                                                                field="Account"
                                                                                label="Company Name"
                                                                                placeholder="Select Company Name"
                                                                                required={!isCurrentTenantCareESIOPartner()}
                                                                                //type={AccountRow}
                                                                                showCreateButton
                                                                                url={"Account/Lookup"}
                                                                                moduleName={RAFEntityName.Account}
                                                                                //createform={<CreateAccount isActive />}
                                                                                createform={CreateAccount}
                                                                                createformdefaultfield={"AccountName"}
                                                                                selectedField={"AccountName"}
                                                                                SearchCreateOptionMode={"Footer"}
                                                                            />

                                                                            <ACDropdown<ContactRow>
                                                                                field="Type"
                                                                                label="Type"
                                                                                placeholder="Select Type"
                                                                                isColorOption
                                                                                uitype="colorpicker"
                                                                                createOptionMode={"Footer"}
                                                                                moduleName={moduleName}
                                                                                required
                                                                            />

                                                                            <ACPhone<ContactRow>
                                                                                field="PhoneList"
                                                                                showLabel
                                                                                label="Phone"
                                                                            />

                                                                            <ACEmail<ContactRow>
                                                                                field="EmailList"
                                                                                showLabel
                                                                                label="E-Mail"
                                                                            />

                                                                            <div className="row align-items-center">
                                                                                <div className="col-12">
                                                                                    <label className="form-label">
                                                                                        Email opt-out
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-12" style={{ width: "65px" }}>
                                                                                    <RAFCheckBox<ContactRow>
                                                                                        field="EmailOptedout"
                                                                                        showLabel={false}
                                                                                        label="Email opt-out"
                                                                                        uitype="switch"
                                                                                    ></RAFCheckBox>
                                                                                </div>
                                                                            </div>

                                                                            <ACTextBox<ContactRow>
                                                                                field="LinkedIn"
                                                                                label="LinkedIn"
                                                                                placeholder="LinkedIn Link"
                                                                            />
                                                                            {/* <ACAddress<ContactRow> field="AddressList" label="Address" showAdd={false} showAddressType={false} />*/}
                                                                            <div
                                                                                className="row align-items-center"
                                                                                id={"rafdivAddress"}
                                                                            >
                                                                                <div className="col-12 d-flex">
                                                                                    <label className="form-label">Address</label>
                                                                                </div>

                                                                                <div className="col-12">
                                                                                    <RAFAddress
                                                                                        field1="AddressLine1"
                                                                                        field2="AddressLine2"
                                                                                        field3="AddressCity"
                                                                                        field4="AddressState"
                                                                                        field5="AddressCountry"
                                                                                        field6="AddressPostalCode"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <ACTextArea<ContactRow>
                                                                                field="Description"
                                                                                showLabel
                                                                                label="About contact"
                                                                                placeholder="where did you first meet, hobbies etc."
                                                                                rows={3}
                                                                            />
                                                                        </div>
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="e-dlg-footerContent ">
                                                                <RAFPermissionRender
                                                                    permissionName={ContactConstants.ContactUpdate}
                                                                >
                                                                    <ButtonComponent
                                                                        cssClass="form-custom-button e-flat e-info"
                                                                    >
                                                                        Save
                                                                    </ButtonComponent>
                                                                </RAFPermissionRender>
                                                                <ButtonComponent
                                                                    type="button"
                                                                    cssClass="form-custom-button e-flat"
                                                                    onClick={props.onClose}
                                                                >
                                                                    Cancel
                                                                </ButtonComponent>
                                                            </div>
                                                        </div>
                                                    </RAFForm>
                                                );
                                            } else {
                                                if (error === Constants.RAFObject_NoContent) {
                                                    return (
                                                        <div className="container-fluid px-0">
                                                            <RAFDeletedRecordState
                                                                image={nodata_es}
                                                                title="This item is either deleted or You do not have sufficient privileges to view this item."
                                                            />
                                                        </div>
                                                    );
                                                } else {
                                                    return (
                                                        <div
                                                            className="container-fluid px-0"
                                                            style={{ background: "transparent" }}
                                                        >
                                                            <ACLoadingPanel loadingText="Loading..." />
                                                        </div>
                                                    );
                                                }
                                            }
                                        }}
                                    </RAFObjectContext.Consumer>
                                </RAFObjectContextProvider>
                            </RAFPageLayoutProvider>
                        </RAFAttributeRelatedListProvider>
                    </RAFEntityProvider>
                </div>
            );
        } else {
            return (
                <div className="container-fluid px-0">
                    <RAFDeletedRecordState
                        image={nodata_es}
                        title="This item is either deleted or You do not have sufficient privileges to view this item."
                    />
                </div>
            );
        }
    } else {
        return (
            <div></div>
        );
    }
}

export default UpdateContact;
