import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import {
  DropDownButtonComponent,
  MenuEventArgs,
} from "@syncfusion/ej2-react-splitbuttons";
import DOMPurify from "dompurify";
import React, { PropsWithChildren } from "react";
import { FormRenderProps } from "react-final-form";
import { msalInstance } from "../../..";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFTextArea from "../../../RAFComponents/Inputs/RAFTextArea";
import RAFIconImage from "../../../RAFComponents/Navigation/RAFIconImage";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import RAFPermissionRender, { hasPermission } from "../../../RAFComponents/helpers/PermissionHelper";
import {
  Guid,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../RAFComponents/helpers/utils";
import { RolePermissionRow } from "../../../RAFComponents/models/Common/RolePermissionRow";
import {
  RAFButtonConstant,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../constants/Common/PermissionConstants";
import { ActivityRow } from "./ActivityRow";

interface IProps {
  activity: ActivityRow;
  editActivity: (activityRow) => void;
  deleteActivity: (id) => void;
  isEidtable: boolean;
  cssClassname?: string;
  uiMode?: "table";
  secondaryRelatedTo?: string;
  secondaryRelatedToUID?: string;
  secondaryRelatedToType?: string;
  showRelatedTo?: boolean;
  showSecondaryRelatedTo?: boolean;

  permissionValue: RolePermissionRow;
}

export class ActivityView extends React.Component<PropsWithChildren<IProps>> {
  private activityRow = this.props.activity;
  public rafForm: FormRenderProps | null;
  public submitButton: ButtonComponent;
  private indexVal: string = Guid.newGuid();
  state = {
    activityKey: Math.random(),
  };

  onSubmitActivity = (value) => {
    let activityToCreate: ActivityRow = value as ActivityRow;
    let editDeletebtn = document.querySelector(
      "#editDeletebtn_" + this.activityRow.UID + "_" + this.indexVal
    );

    if (this.props.editActivity) {
      this.props.editActivity(activityToCreate);
      editDeletebtn.classList.add("hidden");
    }
    this.onCancelClicked();
  };

  enableSubmitButton = (g: FormRenderProps | null) => {
    if (g) {
      if (isNotNullAndUndefined(this.submitButton)) {
        //this.submitButton.disabled = !g.valid || g.submitting || g.pristine;
        this.submitButton.disabled = !g.valid || g.submitting;
      }
    }
  };

  onEditClicked = () => {
    if (!hasPermission(this.props.permissionValue, PermissionConstants.CommentsUpdate)) {
      showWarningToast('Sorry, you do not have permission to perform this action.');
      return;
    }
    let editDiv = document.querySelector(
      "#editableActivity_" + this.activityRow.UID + "_" + this.indexVal
    );
    let summaryDiv = document.querySelector(
      "#summaryActivity_" + this.activityRow.UID + "_" + this.indexVal
    );
    let editDeletebtn = document.querySelector(
      "#editDeletebtn_" + this.activityRow.UID + "_" + this.indexVal
    );
    let recordInfobtn = document.querySelector(
      "#recordInfoBtn_" + this.activityRow.UID + "_" + this.indexVal
    );
    if (isNotNullAndUndefined(recordInfobtn)) {
      if (recordInfobtn.classList.contains("hidden"))
        recordInfobtn.classList.remove("hidden");
      else recordInfobtn.classList.add("hidden");
    }
    if (isNotNullAndUndefined(editDiv)) {
      if (editDiv.classList.contains("hidden"))
        editDiv.classList.remove("hidden");
      else editDiv.classList.add("hidden");
    }
    if (isNotNullAndUndefined(summaryDiv)) {
      if (editDeletebtn.classList.contains("hidden"))
        summaryDiv.classList.remove("hidden");
      else summaryDiv.classList.add("hidden");
    }
    if (isNotNullAndUndefined(editDeletebtn)) {
      if (editDeletebtn.classList.contains("hidden"))
        editDeletebtn.classList.remove("hidden");
      else editDeletebtn.classList.add("hidden");
    }
  };

  onCancelClicked = () => {
    let editDiv = document.querySelector(
      "#editableActivity_" + this.activityRow.UID + "_" + this.indexVal
    );
    let summaryDiv = document.querySelector(
      "#summaryActivity_" + this.activityRow.UID + "_" + this.indexVal
    );
    let editDeletebtn = document.querySelector(
      "#editDeletebtn_" + this.activityRow.UID + "_" + this.indexVal
    );
    let recordInfobtn = document.querySelector(
      "#recordInfoBtn_" + this.activityRow.UID + "_" + this.indexVal
    );
    if (isNotNullAndUndefined(editDiv)) {
      editDiv.classList.add("hidden");
    } else {
      editDiv.classList.remove("hidden");
    }
    if (isNotNullAndUndefined(recordInfobtn)) {
      recordInfobtn.classList.remove("hidden");
    }
    if (isNotNullAndUndefined(summaryDiv)) {
      summaryDiv.classList.remove("hidden");
    } else {
      summaryDiv.classList.add("hidden");
    }
    if (isNotNullAndUndefined(editDeletebtn)) {
      editDeletebtn.classList.remove("hidden");
    } else {
      editDeletebtn.classList.add("hidden");
    }
    this.setState({
      activityKey: Math.random(),
    });
  };

  activityDropdownMenuClicked = (e: MenuEventArgs) => {
    if (e.item.id === "edit") {
      this.onEditClicked();
    }
    if (e.item.id === "delete") {
      this.onDeleteActivityClicked();
    }
  };

  onDeleteActivityClicked = () => {
    if (!hasPermission(this.props.permissionValue, PermissionConstants.CommentsDelete)) {
      showWarningToast('Sorry, you do not have permission to perform this action.');
      return;
    }
    let activityRow: ActivityRow = this.props.activity;
    if (isNotNullAndUndefined(this.props.deleteActivity)) {
      this.props.deleteActivity(activityRow.UID);
    }
  };

  getActionMessage = (userFeedRow: ActivityRow) => {
    const loggedinUserId = msalInstance.currentUserId;

    let retVal = "";

    if (isNullOrUndefined(userFeedRow)) return retVal;

    let mentionsUID = isNotNullAndUndefined(userFeedRow.Mentions)
      ? userFeedRow.Mentions[0]
      : null;

    if (isNotNullAndUndefined(mentionsUID) && mentionsUID === loggedinUserId) {
      retVal = isNotNullAndUndefined(userFeedRow.SecondaryRelatedTo)
        ? " mentioned you on "
        : " mentioned you ";
    } else {
      retVal = isNotNullAndUndefined(userFeedRow.SecondaryRelatedTo)
        ? "commented "
        : "added comment";
    }
    return retVal;
  };

  getModuleIcon = (moduleName?: string) => {
    let iconName = "file-alt";
    let text = "Not set";
    let avatarClassName = "default-avatar";
    switch (moduleName) {
      case RAFEntityName.Task:
        iconName = "tasks";
        text = "Action";
        avatarClassName = "task-avatar";
        break;
      case RAFEntityName.BusinessProcessStep:
        iconName = "list-alt";
        text = "Process Step";
        avatarClassName = "default-avatar";
        break;
      default:
        break;
    }
    return (
      <div>
        <TooltipComponent content={text} position="BottomCenter">
          <div className="d-flex align-items-center">
            <span
              className={
                "avatar-text-circle transparent-avatar " + avatarClassName
              }
              style={{
                width: "1.5rem",
                height: "1.5rem",
                minWidth: "1.5rem",
                minHeight: "1.5rem",
                fontSize: "0.875em",
                marginRight: "0.3125em",
              }}
            >
              <i className={"fa fa-" + iconName}></i>
            </span>
          </div>
        </TooltipComponent>
      </div>
    );
  };

  render() {
    let activityRow: ActivityRow = this.props.activity;
    //let activityDate = convertUTCDateToLocalTimezone(activityRow.ModifiedDate);
    let {
      secondaryRelatedToUID,
      secondaryRelatedTo,
      secondaryRelatedToType,
      showSecondaryRelatedTo,
    } = this.props;

    if (
      isNotNullAndUndefined(this.props.uiMode) &&
      this.props.uiMode === "table"
    ) {
      return (
        <li
          key={activityRow.UID}
          className={
            isNotNullAndUndefined(this.props.cssClassname)
              ? `${this.props.cssClassname} activitiesItem flex-nowrap align-items-start px-0 pb-0`
              : "activitiesItem flex-nowrap align-items-start px-0 pb-0"
          }
        >
          <div className="row gx-3">
            <div className="col-auto">
              <RAFIconImage
                // value={
                //   isNotNullAndUndefined(activityRow.UserName)
                //     ? activityRow.UserName
                //     : "Satheesh Kumar"
                // }
                userIcon
                iconSize="30"
                fontSize="12"
                moduleavatar="activitiesItem__header__avatar e-avatar-small"
              ></RAFIconImage>
            </div>
            <div className="col overflow-hidden">
              <div className="row g-0">
                <div className="col activitiesItem__header__col">
                  <span className="header-text-sm me-2">
                    {isNotNullAndUndefined(activityRow.UserName)
                      ? activityRow.UserName
                      : "Satheesh Kumar"}
                  </span>

                  <RAFRecordInfo
                    createdBy={activityRow.CreatedBy}
                    createdDate={activityRow.CreatedDate}
                    modifiedDate={activityRow.ModifiedDate}
                    modifiedBy={activityRow.ModifiedBy}
                    lastActivityDate={activityRow.LastActivityDate}
                    content=""
                    hideClockIcon
                    dropdownBtnClassName="unset-custom-button-md p-0 remove-minWidth"
                  ></RAFRecordInfo>
                </div>
                {this.props.isEidtable === true && (
                  <div
                    className="col-auto activitiesItem__button__col"
                    id={
                      "editDeletebtn_" + activityRow.UID + "_" + this.indexVal
                    }
                  >
                    <DropDownButtonComponent
                      cssClass="e-caret-hide custom-button-sm"
                      iconCss={RAFButtonConstant.More.IconCss}
                      select={(e) => {
                        this.activityDropdownMenuClicked(e);
                      }}
                      items={[
                        { id: "edit", text: "Edit", iconCss: "fal fa-pencil" },
                        {
                          id: "delete",
                          text: "Delete",
                          iconCss: "fas fa-trash",
                        },
                      ]}
                    ></DropDownButtonComponent>
                  </div>
                )}
              </div>
              <div>
                {isNotNullAndUndefined(showSecondaryRelatedTo) &&
                  showSecondaryRelatedTo === true ? (
                  <div className="d-flex align-items-center flex-nowrap">
                    <span className="d-inline-block">
                      {this.getModuleIcon(secondaryRelatedToType)}
                    </span>
                    <span
                      className="ecllipseFirstLine"
                      style={{
                        fontSize: "0.8125rem",
                        lineHeight: "1.5625",
                        cursor: "pointer",
                        color: "#006DFF",
                      }}
                    >
                      {secondaryRelatedTo}
                    </span>
                  </div>
                ) : null}
              </div>
              <div
                className="secondary-header-text"
                id={"summaryActivity_" + activityRow.UID + "_" + this.indexVal}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      activityRow !== null
                        ? DOMPurify.sanitize(activityRow.Message)
                        : undefined,
                  }}
                ></span>
              </div>
              <div
                id={"editableActivity_" + activityRow.UID + "_" + this.indexVal}
                className="hidden actionTextAreaDiv"
              >
                <RAFForm
                  key={this.state.activityKey}
                  type={ActivityRow}
                  initialValues={activityRow}
                  submitOnEnterKey
                  onSubmit={this.onSubmitActivity}
                  formRef={(g) => {
                    this.enableSubmitButton(g);
                    return (this.rafForm = g);
                  }}
                >
                  <div className="createActivity" id="actionTextAreaDiv">
                    <RAFTextArea<ActivityRow>
                      field="Message"
                      showLabel={false}
                      required
                      placeholder="Ask a question or post an update. Use @ to mention the user"
                      rows={5}
                      useMentions
                      mentionsField="Mentions"
                      rowClassName="g-0"
                    ></RAFTextArea>
                  </div>
                  <div className="d-flex justify-content-end align-items-center mt-2 mb-3">
                    <RAFPermissionRender
                      permissionName={PermissionConstants.CommentsUpdate}
                    >
                      <ButtonComponent
                        type="button"
                        cssClass="e-flat custom-button-md e-info me-2"
                        onClick={() => {
                          this.rafForm && this.rafForm.form.submit();
                        }}
                      >
                        Save
                      </ButtonComponent>
                    </RAFPermissionRender>
                    <ButtonComponent
                      cssClass="e-flat custom-button-md"
                      type="button"
                      onClick={() => this.onCancelClicked()}
                    >
                      Cancel
                    </ButtonComponent>
                  </div>
                </RAFForm>
              </div>
            </div>
          </div>
        </li>
      );
    } else {
      return (
        <li
          key={activityRow.UID}
          className={
            isNotNullAndUndefined(this.props.cssClassname)
              ? `${this.props.cssClassname} activitiesItem flex-nowrap align-items-start`
              : "activitiesItem flex-nowrap align-items-start"
          }
        >
          <div className="activitiesItem__header">
            <div className="row g-2 flex-nowrap align-items-center">
              <div className="col-auto">
                <RAFIconImage
                  // value={
                  //   isNotNullAndUndefined(activityRow.UserName)
                  //     ? activityRow.UserName
                  //     : "Satheesh Kumar"
                  // }
                  userIcon
                  iconSize="30"
                  fontSize="12"
                  moduleavatar="activitiesItem__header__avatar e-avatar-xsmall"
                ></RAFIconImage>
              </div>
              <div className="col">
                <span className="activity-title">
                  {isNotNullAndUndefined(activityRow.UserName)
                    ? activityRow.UserName
                    : "Satheesh Kumar"}
                </span>
                <span className="activitiesItem__header__secondaryTitle">
                  {" "}
                  added a note
                </span>
              </div>
              <div
                className="col-auto"
                id={"recordInfoBtn_" + activityRow.UID + "_" + this.indexVal}
              >
                <RAFRecordInfo
                  createdBy={activityRow.CreatedBy}
                  createdDate={activityRow.CreatedDate}
                  modifiedDate={activityRow.ModifiedDate}
                  modifiedBy={activityRow.ModifiedBy}
                  lastActivityDate={activityRow.LastActivityDate}
                  content=""
                  hideClockIcon
                  dropdownBtnClassName="unset-custom-button-md p-0 remove-minWidth"
                ></RAFRecordInfo>
              </div>
              {this.props.isEidtable === true && (
                <div
                  className="col-auto"
                  id={"editDeletebtn_" + activityRow.UID + "_" + this.indexVal}
                >
                  <DropDownButtonComponent
                    cssClass="e-dropdown-btnDefault custom-button-md userProfileName e-caret-hide e-icon-btn"
                    iconCss=""
                    select={(e) => {
                      this.activityDropdownMenuClicked(e);
                    }}
                    items={[
                      { id: "edit", text: "Edit", iconCss: "fal fa-pencil" },
                      { id: "delete", text: "Delete", iconCss: "fas fa-trash" },
                    ]}
                  >
                    <div className="icon-ellipsis-sm icon-ellipsis-horizontal">
                      <span className="icon-ellipsis-dot"></span>
                    </div>
                  </DropDownButtonComponent>
                </div>
              )}
            </div>
          </div>
          <div className="activitiesItem__content">
            <div
              id={"summaryActivity_" + activityRow.UID + "_" + this.indexVal}
              className="activitiesItem__content__text"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    activityRow !== null
                      ? DOMPurify.sanitize(activityRow.Message)
                      : undefined,
                }}
              ></span>
            </div>
            <div
              id={"editableActivity_" + activityRow.UID + "_" + this.indexVal}
              className="hidden actionTextAreaDiv col-12"
            >
              <RAFForm
                key={this.state.activityKey}
                type={ActivityRow}
                initialValues={activityRow}
                submitOnEnterKey
                onSubmit={this.onSubmitActivity}
                formRef={(g) => {
                  this.enableSubmitButton(g);
                  return (this.rafForm = g);
                }}
              >
                <div className="createActivity" id="actionTextAreaDiv">
                  <RAFTextArea<ActivityRow>
                    field="Message"
                    showLabel={false}
                    required
                    placeholder="Ask a question or post an update. Use @ to mention the user"
                    rows={5}
                    useMentions
                    mentionsField="Mentions"
                  ></RAFTextArea>
                </div>
                <div className="d-flex justify-content-end align-items-center mt-2">
                  <RAFPermissionRender
                    permissionName={PermissionConstants.CommentsUpdate}
                  >
                    <ButtonComponent
                      type="button"
                      cssClass="e-flat custom-button-md e-info me-2"
                      onClick={() => {
                        this.rafForm && this.rafForm.form.submit();
                      }}
                    >
                      Save
                    </ButtonComponent>
                  </RAFPermissionRender>
                  <ButtonComponent
                    cssClass="e-flat custom-button-md"
                    type="button"
                    onClick={() => this.onCancelClicked()}
                  >
                    Cancel
                  </ButtonComponent>
                </div>
              </RAFForm>
            </div>
          </div>
          <div className="d-none">
            <RAFIconImage
              // value={
              //   isNotNullAndUndefined(activityRow.UserName)
              //     ? activityRow.UserName
              //     : "Satheesh Kumar"
              // }
              userIcon
              iconSize="30"
              fontSize="12"
            ></RAFIconImage>
          </div>
          <div className="w-100 d-none">
            <div className="activitiesContentDiv">
              <div className="w-100">
                <div className="row">
                  <div className="col d-flex align-items-center">
                    <div className="row">
                      <div className="col-12">
                        <div className="row flex-nowrap g-2">
                          <div className="col-auto">
                            <span className="mx-1 activity-title">
                              {isNotNullAndUndefined(activityRow.UserName)
                                ? activityRow.UserName
                                : "Satheesh Kumar"}
                            </span>
                          </div>
                          <div className="col-auto">
                            <RAFRecordInfo
                              createdBy={activityRow.CreatedBy}
                              createdDate={activityRow.CreatedDate}
                              modifiedDate={activityRow.ModifiedDate}
                              modifiedBy={activityRow.ModifiedBy}
                              lastActivityDate={activityRow.LastActivityDate}
                              content=""
                              hideClockIcon
                              dropdownBtnClassName="unset-custom-button-md p-0 remove-minWidth"
                            ></RAFRecordInfo>
                          </div>
                        </div>
                      </div>
                      {isNotNullAndUndefined(
                        activityRow.SecondaryRelatedToType
                      ) && (
                          <div className="d-flex align-items-center flex-nowrap">
                            <div className="col-12 d-flex align-items-center">
                              <span className="d-inline-block">
                                {this.getModuleIcon(
                                  activityRow.SecondaryRelatedToType
                                )}
                              </span>
                              <span
                                className="ecllipseFirstLine"
                                style={{
                                  fontSize: "0.8125rem",
                                  lineHeight: "1.5625",
                                  cursor: "pointer",
                                  color: "#006DFF",
                                }}
                              >
                                {activityRow.SecondaryRelatedTo}
                              </span>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  {this.props.isEidtable === true && (
                    <div
                      className="col-auto"
                      id={
                        "editDeletebtn_" + activityRow.UID + "_" + this.indexVal
                      }
                    >
                      <div className="d-flex justify-content-end mt-2">
                        <DropDownButtonComponent
                          cssClass="e-dropdown-btnDefault custom-button-md userProfileName e-caret-hide e-icon-btn"
                          iconCss=""
                          select={(e) => {
                            this.activityDropdownMenuClicked(e);
                          }}
                          items={[
                            {
                              id: "edit",
                              text: "Edit",
                              iconCss: "fal fa-pencil",
                            },
                            {
                              id: "delete",
                              text: "Delete",
                              iconCss: "fas fa-trash",
                            },
                          ]}
                        >
                          <div className="icon-ellipsis-sm icon-ellipsis-horizontal">
                            <span className="icon-ellipsis-dot"></span>
                          </div>
                          {/*<span> {userName} </span>*/}
                        </DropDownButtonComponent>
                        <div className="d-none">
                          <ButtonComponent
                            type="button"
                            iconCss="fal fa-pencil "
                            cssClass="custom-button-md e-outline d-md-none"
                            onClick={() => this.onEditClicked()}
                          ></ButtonComponent>
                          <ButtonComponent
                            type="button"
                            cssClass="custom-button-md e-outline d-none d-md-block"
                            onClick={() => this.onEditClicked()}
                          >
                            Edit
                          </ButtonComponent>
                          <ButtonComponent
                            type="button"
                            iconCss="fas fa-trash"
                            cssClass="custom-button-md e-outline ms-2 d-md-none"
                            onClick={() =>
                              this.onDeleteActivityClicked()
                            }
                          ></ButtonComponent>
                          <ButtonComponent
                            type="button"
                            iconCss="fas fa-trash"
                            cssClass="custom-button-md e-outline ms-2 d-none d-md-block"
                            onClick={() =>
                              this.onDeleteActivityClicked()
                            }
                          >
                            Delete
                          </ButtonComponent>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className="activity-footer"
              id={"summaryActivity_" + activityRow.UID + "_" + this.indexVal}
            >
              {/*<span className="activity-date" id="acivityRecordInfo">
                                    {moment(activityDate).format("ddd, MMM Do YYYY, h:mm:ss a")}
                                </span>*/}
              <div className="activity-secondaryValue ps-1 pt-1">
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      activityRow !== null
                        ? DOMPurify.sanitize(activityRow.Message)
                        : undefined,
                  }}
                ></span>
              </div>
            </div>
            <div
              key={this.state.activityKey}
              id={"editableActivity_" + activityRow.UID + "_" + this.indexVal}
              className="hidden actionTextAreaDiv col-12"
            >
              <RAFForm
                type={ActivityRow}
                initialValues={activityRow}
                submitOnEnterKey
                onSubmit={this.onSubmitActivity}
                formRef={(g) => {
                  this.enableSubmitButton(g);
                  return (this.rafForm = g);
                }}
              >
                <div className="d-flex flex-column w-100 mt-2">
                  <div className="d-flex flex-column createActivity">
                    <div className="w-100" id="actionTextAreaDiv">
                      <RAFTextArea<ActivityRow>
                        field="Message"
                        showLabel={false}
                        required
                        placeholder="Ask a question or post an update. Use @ to mention the user"
                        rows={5}
                        useMentions
                        mentionsField="Mentions"
                      ></RAFTextArea>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center mb-2">
                    <RAFPermissionRender
                      permissionName={PermissionConstants.CommentsUpdate}
                    >
                      <ButtonComponent
                        type="button"
                        cssClass="e-flat custom-button-md e-info me-2"
                        onClick={() => {
                          this.rafForm && this.rafForm.form.submit();
                        }}
                      >
                        Save
                      </ButtonComponent>
                    </RAFPermissionRender>
                    <ButtonComponent
                      cssClass="e-flat custom-button-md me-2"
                      type="button"
                      onClick={() => this.onCancelClicked()}
                    >
                      Cancel
                    </ButtonComponent>
                  </div>
                </div>
              </RAFForm>
            </div>
          </div>
          {/* <div className="activity-date">
                        <div className="activity-edit">
                            <div className="d-flex flex-column" id={"editDeletebtn_" + activityRow.UID + "_" + this.indexVal} >
                                <RAFRecordInfo createdBy={activityRow.CreatedBy} createdDate={activityRow.CreatedDate} modifiedDate={activityRow.ModifiedDate} modifiedBy={activityRow.ModifiedBy} lastActivityDate={activityRow.LastActivityDate} content="" hideClockIcon></RAFRecordInfo>
                                {this.props.editable === true &&
                                    <div className="d-flex justify-content-end mt-2">
                                        <DropDownButtonComponent cssClass="e-dropdown-btnDefault custom-button-md userProfileName e-caret-hide e-icon-btn" iconCss='' select={(e) => { this.activityDropdownMenuClicked(e) }}
                                            items={[
                                                { id: 'edit', text: 'Edit', iconCss: 'fal fa-pencil', },
                                                { id: 'delete', text: 'Delete', iconCss: 'fas fa-trash', },
                                            ]} >
                                            <div className="icon-ellipsis-sm icon-ellipsis-horizontal">
                                                <span className="icon-ellipsis-dot"></span>
                                            </div>
                                            {/*<span> {userName} </span>*/}
          {/* </DropDownButtonComponent>
                                        <div className="d-none">
                                            <ButtonComponent type="button" iconCss="fal fa-pencil " cssClass='custom-button-md e-outline d-md-none' onClick={() => this.onEditClicked()}></ButtonComponent>
                                            <ButtonComponent type="button" cssClass='custom-button-md e-outline d-none d-md-block' onClick={() => this.onEditClicked()}>Edit</ButtonComponent>
                                            <ButtonComponent type="button" iconCss="fas fa-trash" cssClass='custom-button-md e-outline ms-2 d-md-none' onClick={() => this.props.deleteActivity && this.props.deleteActivity(activityRow.UID)}></ButtonComponent>
                                            <ButtonComponent type="button" iconCss="fas fa-trash" cssClass='custom-button-md e-outline ms-2 d-none d-md-block' onClick={() => this.props.deleteActivity && this.props.deleteActivity(activityRow.UID)}>Delete</ButtonComponent>
                                        </div>
                                    </div>
                                }
                                {this.props.allowReply === true &&
                                    <ButtonComponent iconCss="fa-icons fa-Reply" cssClass='e-outline custom-button-md'>Reply</ButtonComponent>}
                            </div>
                        </div>
                    </div> */}
        </li>
      );
    }
  }
}

export default ActivityView;
