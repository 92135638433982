import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import {
  RAFEventName,
  subscribeRAFEvent,
  unsubscribeRAFEvent,
} from "../../../../RAFComponents/helpers/RAFDocumentEvents";
import { isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import CreateActivity from "../CreateActivity";
import ActivitySummary from "./ActivitySummary";

interface IProps {
  moduleName?: string;
  relatedToUID?: string;
  relatedTo?: string;
  onChangeRelatedActivity?: () => void;
  showEmptyState?: boolean;
  showEmptyStateIcon?: boolean;
  displayStyle?: 'ImageOnly' | 'TextOnly' | 'Default';
  // menuContainer?: string;

  secondaryRelatedToUID?: string;
  secondaryRelatedTo?: string;
  secondaryRelatedToType?: string;
  activityItemClassname?: string;
  excludeRelatedFilter?: boolean;
  showSecondaryRelatedTo?: boolean;
  paddingClassName?: string;
  contentPaddingClassName?: string;
}

interface IState {
  activitySummaryKey?: number;
}

function CreateActivitySummary({
  showEmptyState = true,
  showEmptyStateIcon = true,
  displayStyle = "Default",
  ...props }: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      activitySummaryKey: Math.random(),
    }
  );

  useEffect(() => {
    let eventID = isNotNullAndUndefined(props.secondaryRelatedToUID)
      ? props.secondaryRelatedToUID
      : props.relatedToUID;
    subscribeRAFEvent(
      `${RAFEventName.ReloadCommentsContent}_${eventID}`,
      reloadCommentsContent
    );
    return () => {
      unsubscribeRAFEvent(
        `${RAFEventName.ReloadCommentsContent}_${eventID}`,
        reloadCommentsContent
      );
    };
  }, []);

  function reloadCommentsContent(args) {
    let entity = isNotNullAndUndefined(props.secondaryRelatedToType)
      ? props.secondaryRelatedToType
      : props.moduleName;
    let objmoduleName = args.detail.moduleName;
    if (objmoduleName === entity) {
      setState({ activitySummaryKey: Math.random() });
    }
  }

  const relatedActivityUpdated = () => {
    if (props.onChangeRelatedActivity) {
      props.onChangeRelatedActivity();
    }
    setState({ activitySummaryKey: Math.random() });
  };

  const relatedCommentsUpdated = () => {
    if (props.onChangeRelatedActivity) {
      props.onChangeRelatedActivity();
    }
  };
  return (
    <div>
      <div className="row gy-2 gx-0">
        <div className='col-12'>
          <div className={`${isNotNullAndUndefined(props.paddingClassName) ? props.paddingClassName : "pt-3 px-3"}`} style={{ background: "#fff" }}>
            <CreateActivity
              onUpdate={() => {
                relatedActivityUpdated();
              }}
              relatedToUID={props.relatedToUID}
              relatedTo={props.relatedTo}
              entityName={props.moduleName}
              // menuContainer={props.menuContainer}
              secondaryRelatedToUID={props.secondaryRelatedToUID}
              secondaryRelatedTo={props.secondaryRelatedTo}
              secondaryRelatedToType={props.secondaryRelatedToType}
            />
          </div>
        </div>
        <div className='col-12'>
          <div className={`${isNotNullAndUndefined(props.contentPaddingClassName) ? props.contentPaddingClassName : "px-3"}`}>
            <ActivitySummary
              key={state.activitySummaryKey}
              relatedCommentsUpdated={() => relatedCommentsUpdated()}
              relatedToUID={props.relatedToUID}
              entityName={props.moduleName}
              showEmptyStateIcon={showEmptyStateIcon}
              showEmptyState={showEmptyState}
              displayStyle={displayStyle}
              secondaryRelatedToUID={props.secondaryRelatedToUID}
              secondaryRelatedTo={props.secondaryRelatedTo}
              secondaryRelatedToType={props.secondaryRelatedToType}
              activityItemClassname={props.activityItemClassname}
              excludeRelatedFilter={props.excludeRelatedFilter}
              showSecondaryRelatedTo={props.showSecondaryRelatedTo}
              uiMode="table"
            />
          </div>
        </div>
      </div>

    </div>
  );
}

export default React.memo(CreateActivitySummary);
