import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import React, { Component } from "react";
import { FormRenderProps } from "react-final-form";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import { RAFLayout } from "../../../constants/Common/Constants";
import { IDialogProps, isNotNullAndUndefined, RAFFormComponentProps } from "../../../RAFComponents/helpers/utils";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import { NavigateParamsProps } from "../../../router";
import { CountryRow } from './CountryRow';

interface IState {
    isActive?: boolean,
}

class CreateCountry extends Component<IDialogProps & RAFFormComponentProps & NavigateParamsProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            isActive: isNotNullAndUndefined(props.isActive) ? props.isActive : false,
        }
    }
    public rafForm: FormRenderProps | null;
    public submitButton: ButtonComponent;

    onSaveCountry = (entityId, objectName) => {
        if(this.props.onSave)
            this.props.onSave(entityId, objectName);
    }

    enableSubmitButton = (g: FormRenderProps | null) => {
        if (g) {
            if (isNotNullAndUndefined(this.submitButton)) {
                this.submitButton.disabled =  g.submitting;
            }
        }
    }

    enableCreate = (isActive) => {
        this.setState({ isActive: isActive });
    }

    render() {
        let countryRow: CountryRow = new CountryRow();
        if (isNotNullAndUndefined(this.props.initialValues)) {
            countryRow = this.props.initialValues as CountryRow;
        }
        let isActive = this.state.isActive;
        countryRow.IsActive = true;

        return (
            isActive &&
            <div>
                <RAFForm type={CountryRow} initialValues={countryRow} formRef={g => { this.enableSubmitButton(g); return this.rafForm = g }} layout={RAFLayout.TwoColumnLayout} onSave={this.onSaveCountry}>
                    <div className="e-dlg-body-content">
                        <ACTextBox<CountryRow> field="CountryName" label="Country Name" required placeholder="Country Name" />
                    </div>
                    <div className="e-dlg-footerContent">
                        <ButtonComponent id="btnCreateCountrySave" type="button" className="me-2" cssClass='e-flat e-info' ref={g => this.submitButton = g} disabled={(this.rafForm && (this.rafForm.submitting))} onClick={() => { this.rafForm && this.rafForm.form.submit() }}>Save</ButtonComponent>
                        <ButtonComponent id="btnCreateCountryCancel" type="button" cssClass='e-flat' onClick={this.props.onClose}>Cancel</ButtonComponent>
                    </div>
                </RAFForm>
                </div>
        );
    }
}

export default CreateCountry;
