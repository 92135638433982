import { MappingPair, MapperConfiguration } from '@dynamic-mapper/mapper';
import { AddressRow, AddressType } from '../../../components/shared/RAFAddress/AddressRow';
import { isNotNullAndUndefined } from '../../../RAFComponents/helpers/utils';
import { getAddressListFromModel } from './AccountHelper';
import { AccountRow, IAccountDto } from "./AccountRow";

export const ConvertAccountRowToAccountDto = (accountRow: AccountRow): IAccountDto => {

    const AccountRowToIAccountDto = new MappingPair<AccountRow, IAccountDto>();
    const AccountRowToAccountDtoConfiguration = new MapperConfiguration(cfg => {
        cfg.createAutoMap(AccountRowToIAccountDto, {})
            .forSourceMember("AddressList", opt => opt.ignore())
            ;
    });

    const mapper = AccountRowToAccountDtoConfiguration.createMapper();
    return mapper.map(AccountRowToIAccountDto, accountRow);
}

export const ConvertAccountDtoToAccountRow = (accountDto: IAccountDto): AccountRow => {
    let addressList: AddressRow[] = [{ AddressType: AddressType.PostalAddress, AddressLine1: null }];
    const IAccountDtoToAccountRow = new MappingPair<IAccountDto, AccountRow>();
    const IAccountDtoToAccountRowConfiguration = new MapperConfiguration(cfg => {
        cfg.createAutoMap(IAccountDtoToAccountRow, {
            AddressList: opt => opt.mapFrom(src => (isNotNullAndUndefined(src) && isNotNullAndUndefined(src.AddressLine1) ? getAddressListFromModel(src) : addressList)),
        })
            ;
    });

    const mapper = IAccountDtoToAccountRowConfiguration.createMapper();
    return mapper.map(IAccountDtoToAccountRow, accountDto);
}

