import { propertyOf } from "../../../RAFComponents/helpers/utils";
import { RAFEntityBase } from "../../../RAFComponents/models/Common/RAFEntityBase";
import { LookUpRow, RelatedTo } from "../../../RAFComponents/models/CompositeTypes/LookUpRow";

export enum RAFActivityCommentType {
    Note = 'Note',
    Post = 'Post',
    Alert = 'Alert',
    Reason = 'Reason',
    Update = 'Update',
    Announcement = 'Announcement',
    Handover = 'Handover',
    Notification = 'Notification',
}

export enum RAFActivityVisibility {
    Public = 'Public',
    Private = 'Private',
}

export class IActivityDto {
    UID?: string;
    RelatedToUID?: string;//dealuid
    RelatedTo?: string;//dealname
    RelatedToType?: string;//Deal
    UserUID?: string;//loggedin
    UserName?: string;//loggedinusername
    Action?: string;//1 -added,2-modified
    Message?: string;//typed comment
    Mentions?: LookUpRow[];// user array
    IsSystem?: boolean;//false
    PostFeed?: boolean;
    SecondaryRelatedTo?: string;
    SecondaryRelatedToType?: string;
    SecondaryRelatedToUID?: string;
    CommentType?: string;
    Subject?: string;
    Visibility?: string;
    DisplayOrder?: number;
    HasMentions?: boolean;
    Pinned?: boolean;
    ColourCode?: string;
    Parent?: string;
    ParentUID?: string;
    ReadCount?: number;
    ReactionCount?: number;
    ChildCount?: number;
    Status?: string;
    Priority?: string;
    Category?: string;
    Portal?: string;
    PortalUID?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    LastActivityDate?: Date;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    TeamMembers?: RelatedTo[];
}

export class IActivityRow {
    UID?: string;
    RelatedToUID?: string;//dealuid
    RelatedTo?: string;//dealname
    RelatedToType?: string;//Deal
    UserUID?: string;//loggedin
    UserName?: string;//loggedinusername
    Action?: string;//1 -added,2-modified
    Message?: string;//typed comment
    Mentions?: LookUpRow[];// user array
    IsSystem?: boolean;//false
    PostFeed?: boolean;
    SecondaryRelatedTo?: string;
    SecondaryRelatedToType?: string;
    SecondaryRelatedToUID?: string;
    CommentType?: string;
    Subject?: string;
    Visibility?: string;
    DisplayOrder?: number;
    HasMentions?: boolean;
    Pinned?: boolean;
    ColourCode?: string;
    Parent?: string;
    ParentUID?: string;
    ReadCount?: number;
    ReactionCount?: number;
    ChildCount?: number;
    Status?: string;
    Priority?: string;
    Category?: string;
    Portal?: string;
    PortalUID?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    LastActivityDate?: Date;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    TeamMembers?: RelatedTo[];
}

export class ActivityRow extends IActivityRow implements RAFEntityBase {
    //UserName?: string;
    ActivityTeamMembers?: string[];

    getClassName?() {
        return 'activity';
    }
    getIdField?(): string {
        return propertyOf<ActivityRow>("UID");
    }
    getListUrl?(): string {//use route name(entity displayname)
        return "Activity/List";
    }
    getSaveUrl?(): string {//use route name(entity displayname)
        return "Activity/Save";
        //return "Activity/SaveStream";
    }
    getLookupUrl(): string {//use route name(entity displayname)
        return "Activity/Lookup";
    }
    isOptionCreatable?(): boolean {
        return false;
    }
}










