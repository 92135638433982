import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { Component, Fragment } from "react";
import { FormRenderProps } from "react-final-form";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDatePickerMUI from "../../../RAFComponents/Inputs/RAFDatePickerMUI";
import RAFForm, { Condition } from "../../../RAFComponents/Inputs/RAFForm";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { createInstance } from "../../../RAFComponents/Utility/FormUtility";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  getSessionStorage,
  hideProgress,
  setSessionStorage,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  IsNullOrWhiteSpace,
  RAFFormComponentProps,
  deepEqual,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { RAFViewRow } from "../../../RAFComponents/models/Common/RAFViewRow";
import { BusinessProcessRow } from "../../../RAFMaster/RMModules/BusinessProcess/Process/BusinessProcessRow";
import { BusinessProcessStepTemplateRow } from "../../../RAFMaster/RMModules/BusinessProcess/StepTemplate/BusinessProcessStepTemplateRow";
import { BusinessProcessTemplateRow } from "../../../RAFMaster/RMModules/BusinessProcess/Template/BusinessProcessTemplateRow";
import {
  RetrieveBusinessProcessTemplate,
  StartProcessAndSaveProcessSteps,
} from "../../../RAFMaster/RMModules/BusinessProcess/helpers/ProcessHelper";
import ACDatePicker from "../../../components/shared/ACFormFields/ACDatePicker";
import ACDropdown from "../../../components/shared/ACFormFields/ACDropdown";
import ACLookup from "../../../components/shared/ACFormFields/ACLookup";
import ACNumber from "../../../components/shared/ACFormFields/ACNumber";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import { DealConstants } from "../../../constants/ActiveContact/ACPermissionConstants";
import { RAFLayout, StorageKey } from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import {
  RAFBPStatus,
  RAFDeadlineCalculation,
  RAFExecutionOrder,
  RAFPageType,
} from "../../../constants/Common/RMConstants";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../Common/Providers/RAFObjectContextProvider";
import RAFPageLayoutProvider from "../../Common/Providers/RAFPageLayoutProvider";
import RAFPageLayout from "../../Common/RAFPageLayout";
import CreateAccount from "../Account/CreateAccount";
import ACContactLookup from "../Contact/ACContactLookup";
import CreateContact from "../Contact/CreateContact";
import { DealRow } from "./DealRow";
import RAFLookUpMUI from "../../../RAFComponents/Inputs/RAFLookUpMUI";

interface IProps {
  accountUID?: string;
  contactUID?: string;
  account?: string;
  contact?: string;
  onProcessStart?: (bpRow) => void;
  bpTemplate?: BusinessProcessTemplateRow;
  initialValues?: any;
}

interface IState {
  isActive?: boolean;
  bpTemplateRows?: BusinessProcessTemplateRow[];
  showStartProcessContent?: boolean;
  bpTemplate?: BusinessProcessTemplateRow;
  relatedTo?: string;
  relatedToType?: string;
  relatedToUID?: string;
  deal?: DealRow;
}

class CreateDeal extends Component<
  IProps & IDialogProps & RAFFormComponentProps,
  IState
> {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      bpTemplate: isNotNullAndUndefined(this.props.bpTemplate)
        ? this.props.bpTemplate
        : {},
      showStartProcessContent: false,
      relatedTo: null,
      relatedToType: null,
      relatedToUID: null,
    };
  }

  private moduleName: string = createInstance(DealRow).getClassName();

  public rafForm: FormRenderProps | null;
  public processrafForm: FormRenderProps | null;
  public submitButton: ButtonComponent;
  _isMounted = false;

  componentDidMount = () => {
    this._isMounted = true;
    this.refresh(this.props.isActive);
  };

  componentDidUpdate = (prevProps) => {
    if (!deepEqual(prevProps.isActive, this.props.isActive)) {
      this.refresh(this.props.isActive);
      //if (this._isMounted) {
      //    this.setState({ isActive: this.props.isActive })
      //}
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSaveDeal = (entityId, objectName) => {
    let allViews: RAFViewRow[] = JSON.parse(
      getSessionStorage(StorageKey.allViews_modulename + this.moduleName, true)
    );
    let recentlyCreatedView: RAFViewRow =
      allViews && allViews.find((x) => x.ViewName === "Recently_Created"); //recently_created;
    if (isNotNullAndUndefined(recentlyCreatedView)) {
      setSessionStorage(
        StorageKey.recentViewId_modulename + this.moduleName,
        true,
        JSON.stringify({
          viewId: recentlyCreatedView.UID,
          viewName: recentlyCreatedView.DisplayName,
        })
      );
    }
    if (this._isMounted) {
      this.setState({ isActive: false }, () => {
        if (this.props.onSave) this.props.onSave(entityId, objectName);
      });
    }
  };

  enableSubmitButton = (g: FormRenderProps | null) => {
    if (g) {
      if (isNotNullAndUndefined(this.submitButton)) {
        this.submitButton.disabled = g.submitting;
      }
    }
  };

  onAccountLookupChanged = (id) => {
    if (
      isNotNullAndUndefined(this.rafForm) &&
      isNotNullAndUndefined(this.rafForm.form) &&
      isNotNullAndUndefined(this.rafForm.form.mutators)
    ) {
      this.rafForm.form.mutators.setValue("AccountUID", id);
      this.rafForm.form.mutators.setValue("ContactUID", null);
      this.rafForm.form.mutators.setValue("Contact", null);
    }
  };

  refresh = (isActive: boolean) => {
    if (isActive === true) {
      //     if (this._isMounted) {
      //         let progressDiv = showProgress('#createDealDiv');
      //         getEntityIdByName("deal").then(entityId => {
      //             getBPTemplatesByEntityId(entityId).then(bpTemplateResponse => {
      //                 hideProgress(progressDiv);;
      //                 if (this._isMounted) {
      //                     this.setState({ bpTemplateRows: bpTemplateResponse, isActive: isActive });
      //                 }
      //             });
      //         });

      //     }
      // } else {
      //     if (this._isMounted) {
      //         this.setState({ isActive: isActive });
      //     }

      if (this._isMounted) {
        this.setState({ isActive: isActive });
      }
    }
  };

  onBusinessProcessChange = (label, value) => {
    if (
      isNotNullAndUndefined(this.rafForm) &&
      isNotNullAndUndefined(this.rafForm.form) &&
      isNotNullAndUndefined(this.rafForm.form.mutators)
    ) {
      this.rafForm.form.mutators.setValue("BusinessProcess", label);
      this.rafForm.form.mutators.setValue("BusinessProcessUID", value);
    }
  };

  private startProcessDialogOpen(bpTempateResponse): void {
    if (this._isMounted) {
      this.setState(
        { showStartProcessContent: true, bpTemplate: bpTempateResponse },
        () => {
          document.body.classList.add("overflow-hidden");
        }
      );
    }
  }

  private startProcessDialogClose(): void {
    if (this._isMounted) {
      this.setState({ showStartProcessContent: false }, () => {
        let allViews: RAFViewRow[] = JSON.parse(
          getSessionStorage(
            StorageKey.allViews_modulename + this.moduleName,
            true
          )
        );
        let recentlyCreatedView: RAFViewRow =
          allViews && allViews.find((x) => x.ViewName === "Recently_Created"); //recently_created;
        if (isNotNullAndUndefined(recentlyCreatedView)) {
          setSessionStorage(
            StorageKey.recentViewId_modulename + this.moduleName,
            true,
            JSON.stringify({
              viewId: recentlyCreatedView.UID,
              viewName: recentlyCreatedView.DisplayName,
            })
          );
        }
        if (this._isMounted) {
          this.setState({ isActive: false }, () => {
            if (this.props.onSave)
              this.props.onSave(this.state.relatedToUID, this.state.relatedTo);
          });
        }
        document.body.classList.remove("overflow-hidden");
      });
    }
  }

  onSubmitProcessTemplate = (
    value,
    saveObject?: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string }>
  ) => {
    let bPTemplateRow: BusinessProcessTemplateRow = value;
    let progressDiv = showProgress(
      `#dlg_create_${this.moduleName}.e-popup-open`
    );

    let { relatedTo, relatedToType, relatedToUID, deal } = this.state;
    //StartProcess({ bptemplateId: bPTemplateRow.UID, bpFormData: bPTemplateRow.FormData, bpDuedate: bPTemplateRow.DueDate, bpRelatedTo: relatedTo, bpRelatedToType: relatedToType, bpRelatedToUID: relatedToUID, objectData: this.props.objectData }).then(bpResponse => {
    StartProcessAndSaveProcessSteps(
      {
        bpRelatedTo: relatedTo,
        bpRelatedToType: relatedToType,
        bpRelatedToUID: relatedToUID,
        objectData: deal,
      },
      bPTemplateRow
    ).then((bpResponse) => {
      hideProgress(progressDiv);
      this.onProcessStart(
        bpResponse.bpRow,
        bpResponse.bpStepTemplateRows,
        saveObject
      );
    });
  };

  startProcessContent(saveObject) {
    let { bpTemplate } = this.state;
    //const loggedinUserName = msalInstance.currentUserName;
    if (
      this.state.showStartProcessContent &&
      this.state.showStartProcessContent === true
    ) {
      return (
        <div id="startBusinessProcessForm">
          <RAFForm
            type={BusinessProcessTemplateRow}
            initialValues={bpTemplate}
            formRef={(g) => (this.processrafForm = g)}
            layout={RAFLayout.TwoColumnLayout}
            onSubmit={(value) =>
              this.onSubmitProcessTemplate(value, saveObject)
            }
          >
            <div className="dlg-new-style">
              <div className="e-dlg-headerContent justify-content-between">
                <div className="d-flex align-items-center w-100">
                  <div className="e-dlg-header" id="_title">
                    {" "}
                    {bpTemplate.ProcessName}
                  </div>
                </div>

                <ButtonComponent
                  type="button"
                  cssClass="primary-custom-button"
                  iconCss="fas fa-xmark"
                  onClick={() => this.startProcessDialogClose()}
                ></ButtonComponent>
              </div>
              <div className="e-dlg-body-content pt-3 h-100 overflow-auto">
                {/*<div className="business-process-item mb-2">
                                        <div className="d-flex" style={{ fontWeight: 400 }}>
                                            <span className="pe-1" style={{ fontWeight: 500 }}>{bpTemplate.ProcessName}</span>
                                            <span className="pe-1"> started by <span className="pe-1" style={{ fontWeight: 500 }}>{(isNotNullAndUndefined(bpTemplate.Initiator) ? bpTemplate.Initiator : loggedinUserName)}</span></span>
                                            <span className="pe-1"> on <span style={{ fontWeight: 500 }}>{moment(new Date()).format("DD/MM/YYYY")}</span></span>
                                        </div>
                                    </div>*/}
                <Condition when="ProcessDeadlineCalculation" is={"Not set"}>
                  <ACDatePicker<BusinessProcessTemplateRow>
                    field="DueDate"
                    label="Do you want to set deadline?"
                  />
                </Condition>
                <Condition when="ProcessDeadlineCalculation" is={null}>
                  <ACDatePicker<BusinessProcessTemplateRow>
                    field="DueDate"
                    label="Do you want to set deadline?"
                  />
                </Condition>
              </div>
              <div className="e-dlg-footerContent ">
                <ButtonComponent
                  type="submit"
                  className="me-2"
                  cssClass="e-flat e-info"
                >
                  Start
                </ButtonComponent>
                <ButtonComponent
                  type="button"
                  cssClass="e-flat"
                  onClick={() => this.startProcessDialogClose()}
                >
                  Cancel
                </ButtonComponent>
              </div>
            </div>
          </RAFForm>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  onProcessStart = (
    bpRow: BusinessProcessRow,
    bpStepTemplateRows: BusinessProcessStepTemplateRow[],
    saveObject?: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string }>
  ) => {
    if (isNotNullAndUndefined(bpRow)) {
      let dealRow: DealRow = this.state.deal as DealRow;
      //dealRow.ProcessStatus = bpRow.ProcessStatus;
      //dealRow.ProcessStatus = bpStepRows && bpStepRows[0].Title;
      dealRow.ProcessStatus =
        bpRow.ExecutionOrder === RAFExecutionOrder.AnyOrder
          ? RAFBPStatus.InProgress
          : bpStepTemplateRows && bpStepTemplateRows[0].Title;
      dealRow.BusinessProcess = bpRow.ProcessName;
      dealRow.BusinessProcessUID = bpRow.UID;
      let progressDiv = showProgress("body", true);
      saveObject(dealRow)
        .then((response) => {
          hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            !IsNullOrWhiteSpace(response.objectUID)
          ) {
            if (this._isMounted) {
              this.setState({ isActive: false }, () => {
                if (this.props.onProcessStart)
                  this.props.onProcessStart(dealRow.UID);
                else if (this.props.onSave)
                  this.props.onSave(
                    this.state.relatedToUID,
                    this.state.relatedTo
                  );
              });
            }
          } else {
            showWarningToast("Sorry something went wrong !");
          }
        })
        .catch((error) => error);
    }
  };

  onSubmitDeal = (
    value,
    saveObject?: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string }>
  ) => {
    // let progressDiv = showProgress(
    //   `#dlg_create_${this.moduleName}.e-popup-open`
    // );
    let objValues: DealRow = value as DealRow;
    let url = "Deal/Save";
    let businessProcess = objValues.BusinessProcess;
    let businessProcessUID = objValues.BusinessProcessUID;
    /*objValues.BusinessProcess = null;
        objValues.BusinessProcessUID = null;*/
    saveObject(objValues)
      .then((response) => {
        // hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(response) &&
          !IsNullOrWhiteSpace(response.objectUID)
        ) {
          if (
            isNotNullAndUndefined(businessProcess) &&
            isNotNullAndUndefined(businessProcessUID)
          ) {
            showSuccessToast("Saved successfully");
            objValues.BusinessProcess = businessProcess;
            objValues.BusinessProcessUID = businessProcessUID;
            objValues.UID = response.objectUID;
            if (this._isMounted) {
              this.setState(
                {
                  relatedTo: response.objectName,
                  relatedToUID: response.objectUID,
                  relatedToType: "deal",
                  deal: objValues,
                },
                () => {
                  let bpTemplateUID = businessProcessUID;

                  RetrieveBusinessProcessTemplate(bpTemplateUID).then(
                    (bpTempateResponse) => {
                      if (
                        isNotNullAndUndefined(
                          bpTempateResponse.ProcessDeadlineCalculation
                        ) &&
                        bpTempateResponse.ProcessDeadlineCalculation ===
                          RAFDeadlineCalculation.PromptUserWhenRun
                      ) {
                        this.startProcessDialogOpen(bpTempateResponse);
                      } else {
                        let progressDiv = showProgress(
                          `#dlg_create_${this.moduleName}.e-popup-open`
                        );
                        StartProcessAndSaveProcessSteps(
                          {
                            bpRelatedTo: response.objectName,
                            bpRelatedToType: "deal",
                            bpRelatedToUID: response.objectUID,
                            objectData: objValues,
                          },
                          bpTempateResponse
                        ).then((bpResponse) => {
                          hideProgress(progressDiv);
                          this.onProcessStart(
                            bpResponse.bpRow,
                            bpResponse.bpStepTemplateRows,
                            saveObject
                          );
                        });
                      }
                    }
                  );
                }
              );
            }
          } else {
            showSuccessToast("Saved successfully");
            let allViews: RAFViewRow[] = JSON.parse(
              getSessionStorage(
                StorageKey.allViews_modulename + this.moduleName,
                true
              )
            );
            let recentlyCreatedView: RAFViewRow =
              allViews &&
              allViews.find((x) => x.ViewName === "Recently_Created"); //recently_created;
            if (isNotNullAndUndefined(recentlyCreatedView)) {
              setSessionStorage(
                StorageKey.recentViewId_modulename + this.moduleName,
                true,
                JSON.stringify({
                  viewId: recentlyCreatedView.UID,
                  viewName: recentlyCreatedView.DisplayName,
                })
              );
            }
            if (this._isMounted) {
              this.setState({ isActive: false }, () => {
                if (this.props.onSave)
                  this.props.onSave(response.objectUID, response.objectName);
              });
            }
          }
        } else {
          showWarningToast("Sorry something went wrong !");
        }
      })
      .catch((error) => error);
  };

  render() {
    let dealRow: DealRow = new DealRow();
    let { isActive, bpTemplateRows, bpTemplate } = this.state;
    if (isNotNullAndUndefined(this.props.initialValues)) {
      dealRow = this.props.initialValues as DealRow;
    }
    // dealRow.Account = initialValues["Account"];
    // dealRow.AccountUID = initialValues["AccountUID"];
    // dealRow.ContactUID = initialValues["ContactUID"];
    // dealRow.Contact = initialValues["Contact"];
    /*dealRow.Account = this.props.account;
    dealRow.AccountUID = this.props.accountUID;
    dealRow.ContactUID = this.props.contactUID;
    dealRow.Contact = this.props.contact;*/
    /* dealRow.Stage = 'New Enquiry';
         dealRow.StageUID = '670fc8c2-2ca2-4f51-bbf7-71a121969160';*/
    dealRow.Status = "Open";
    dealRow.DealType = "Sales Process";
    dealRow.DealTypeUID = "104dd974-c8cc-4f08-9ee5-4703a628a976";
    dealRow.DealDate = new Date();

    //console.log("dealRow", dealRow);
    if (isNotNullAndUndefined(bpTemplate)) {
      dealRow.BusinessProcess = bpTemplate.ProcessName;
      dealRow.BusinessProcessUID = bpTemplate.UID;
    }
    if (isActive === true) {
      return (
        <div className="h-100" id={`raf_dlg_Outer_Div_${this.moduleName}`}>
          <RAFEntityProvider moduleName={this.moduleName}>
            <RAFAttributeRelatedListProvider moduleName={this.moduleName}>
              <RAFPageLayoutProvider rafPageType={RAFPageType.Create}>
                <RAFObjectContextProvider
                  moduleName={this.moduleName}
                  progressDivId={`#raf_dlg_Outer_Div_${this.moduleName}`}
                >
                  <RAFObjectContext.Consumer>
                    {({ /*objectId, rafObject,*/ saveObject }) => {
                      return (
                        <Fragment>
                          <RAFForm
                            type={DealRow}
                            initialValues={dealRow}
                            formRef={(g) => {
                              this.enableSubmitButton(g);
                              return (this.rafForm = g);
                            }}
                            layout={RAFLayout.TwoColumnLayout}
                            //onSubmit={this.onSubmitDeal}
                            onSubmit={(value) =>
                              this.onSubmitDeal(value, saveObject)
                            }
                            className="h-100"
                          >
                            <div className="e-dlg-content-outer">
                              <div className="e-dlg-body-content">
                                <RAFPageLayout
                                  defaultComponent={
                                    <div>
                                      <ACTextBox<DealRow>
                                        field="DealName"
                                        label="Deal Name"
                                        required
                                        placeholder="Deal Name"
                                      />
                                      <RAFDatePickerMUI<DealRow>
                                        field="DealDate"
                                        label="Deal Date"
                                        required
                                        placeholder="Deal Date"
                                      />
                                      <RAFLookUpMUI<DealRow>
                                        field="Account"
                                        label="Company Name"
                                        placeholder="Select Company Name"
                                        required
                                        //type={AccountRow}
                                        showCreateButton
                                        url={"Account/Lookup"}
                                        moduleName={RAFEntityName.Account}
                                        createform={CreateAccount}
                                        createformdefaultfield={"AccountName"}
                                        onChanged={(value, uid) =>
                                          this.onAccountLookupChanged(uid)
                                        }
                                        selectedField={"AccountName"}
                                        SearchCreateOptionMode={"Footer"}
                                      />
                                      <ACContactLookup<DealRow>
                                        field="Contact"
                                        label="Contact Name"
                                        placeholder="Select Contact Name"
                                        required
                                        //type={ContactRow}
                                        showCreateButton
                                        url={"Contact/Lookup"}
                                        moduleName={RAFEntityName.Contact}
                                        //account={dealRow.Account} accountUID={dealRow.AccountUID}
                                        createform={CreateContact}
                                        createformdefaultfield={"FirstName"}
                                        //createformaccountfield={"Account"}
                                        //createformaccountUIDfield={"AccountUID"}
                                        //fieldName="AccountUID"
                                        fieldName="Account"
                                        selectedField={"ContactName"}
                                        //createFormInitialValues={[{ key: "Account", value: dealRow.Account }, { key: "AccountUID", value: dealRow.AccountUID }]}
                                        SearchCreateOptionMode={"Footer"}
                                      />
                                      {/*<ACLookup<DealRow> field="Stage" label="Stage" placeholder="Select Stage" required type={BusinessProcessStageRow} showFullList={false} />*/}
                                      <div className="hidden">
                                        <ACDropdown<DealRow>
                                          field="BusinessProcess"
                                          label="Process Name"
                                          showLabel
                                          allowAdd={false}
                                          onChanged={(label, value) =>
                                            this.onBusinessProcessChange(
                                              label,
                                              value
                                            )
                                          }
                                          placeholder="Select Process"
                                        >
                                          {bpTemplateRows &&
                                            bpTemplateRows.map((item) => {
                                              return (
                                                <RAFChoiceOption
                                                  key={item.UID}
                                                  label={item.ProcessName}
                                                  value={item.UID}
                                                />
                                              );
                                            })}
                                        </ACDropdown>
                                      </div>
                                      <ACDropdown<DealRow>
                                        field="Status"
                                        label="Status"
                                        placeholder="Select Status"
                                        isColorOption
                                        uitype="colorpicker"
                                        createOptionMode={"Footer"}
                                        moduleName={this.moduleName}
                                        required
                                      />
                                      <RAFDatePickerMUI<DealRow>
                                        field="EstimateClosingDate"
                                        label="Estimate Closing Date"
                                        placeholder="Estimate Closing Date"
                                      />
                                      <ACNumber<DealRow>
                                        field="Probability"
                                        label="Probability"
                                        placeholder="Probability"
                                      />
                                      <RAFDatePickerMUI<DealRow>
                                        field="ActualClosingDate"
                                        label="Actual Closing Date"
                                        placeholder="Actual Closing Date"
                                      />
                                      <ACTextBox<DealRow>
                                        field="Weighted"
                                        label="Weighted"
                                        placeholder="Weighted"
                                      />
                                      <ACNumber<DealRow>
                                        field="DealValue"
                                        label="Deal Value"
                                        placeholder="Deal Value"
                                      />
                                      {/*<RAFExtraInfo<DealRow> field="ExtraInfoJson" label="Extra Info" />*/}
                                    </div>
                                  }
                                />
                              </div>
                              <div className="e-dlg-footerContent ">
                                <RAFPermissionRender
                                  permissionName={DealConstants.DealCreate}
                                >
                                  <ButtonComponent
                                    type="button"
                                    className="me-2"
                                    cssClass="e-flat e-info"
                                    onClick={() => {
                                      this.rafForm &&
                                        this.rafForm.form.submit();
                                    }}
                                    id={`btn_save_create_${this.moduleName}`}
                                  >
                                    Save
                                  </ButtonComponent>
                                </RAFPermissionRender>
                                <ButtonComponent
                                  type="button"
                                  cssClass="e-flat"
                                  onClick={this.props.onClose}
                                  id={`btn_cancel_create_${this.moduleName}`}
                                >
                                  Cancel
                                </ButtonComponent>
                              </div>
                            </div>
                          </RAFForm>
                          {this.state.showStartProcessContent && (
                            <DialogComponent
                              visible={this.state.showStartProcessContent}
                              cssClass="rightDialog createEditForm full-height form-center-dialog"
                              id="startBusinessProcessDialog"
                              content={this.startProcessContent.bind(
                                this,
                                saveObject
                              )}
                              isModal
                              target="body"
                              closeOnEscape={false}
                              close={this.startProcessDialogClose.bind(this)}
                            ></DialogComponent>
                          )}
                        </Fragment>
                      );
                    }}
                  </RAFObjectContext.Consumer>
                </RAFObjectContextProvider>
              </RAFPageLayoutProvider>
            </RAFAttributeRelatedListProvider>
          </RAFEntityProvider>
        </div>
      );
    } else {
      return <div id="createDealDiv"></div>;
    }
  }
}

//const mapStateToProps = (state) => {
//    return {
//        showSuccessModal: state.repository.showSuccessModal,
//        showErrorModal: state.errorHandler.showErrorModal,
//        errorMessage: state.errorHandler.errorMessage
//    }
//}

//const mapDispatchToProps = (dispatch) => {
//    return {
//        onCreateDeal: (url, deal, props) => dispatch(repositoryActions.postData(url, deal, props, ContentType.applicationJson)),
//        onCloseSuccessModal: (url, props) => dispatch(repositoryActions.closeSuccessModal(props, url)),
//        onCloseErrorModal: () => dispatch(errorHandlerActions.closeErrorModal())
//    }
//}

//export default connect(mapStateToProps, mapDispatchToProps)(CreateDeal);
export default CreateDeal;
