import { propertyOf } from '../../../RAFComponents/helpers/utils';
import { RAFEntityBase } from '../../../RAFComponents/models/Common/RAFEntityBase';
import { ILookupRow } from '../../../RAFComponents/models/CompositeTypes/ILookupRow';

//export class CountryRow extends RAFEntityBase {
export class ICountryRow {
   // Id?: number;
    UID?: string;
    CountryName?: string;
    IsActive?: boolean;
    SortOrder?: number;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    LastActivityDate?: Date;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
}
export class CountryRow extends ICountryRow implements RAFEntityBase, ILookupRow {
    getClassName?() { //use entityname
        //const name = this.constructor.name;
        //return name.substring(0, name.lastIndexOf("Row"));
        // OR return (this as any).constructor.name;
        return 'country';
    }
    getIdField?(): string {
        return propertyOf<CountryRow>("UID");
    }
    getListUrl?(): string { //use route name(entity displayname)
        return "Country/List";
    }
    getSaveUrl?(): string { //use route name(entity displayname)
        return "Country/Save";
    }
    getGroupByUrl?(): string {
        return "Account/GroupBy";
    }
    getLookupUrl(): string { //use route name(entity displayname)
        return "Country/Lookup";
    }
    isOptionCreatable?(): boolean {
        return true;
    }
}
