import { AddressRow } from '../../../components/shared/RAFAddress/AddressRow';
import { EmailRow } from '../../../components/shared/RAFEmail/EmailRow';
import { PhoneRow } from '../../../components/shared/RAFPhone/PhoneRow';
import { propertyOf } from '../../../RAFComponents/helpers/utils';
import { RAFEntityBase } from '../../../RAFComponents/models/Common/RAFEntityBase';
import { ILookupRow } from '../../../RAFComponents/models/CompositeTypes/ILookupRow';

//export class ContactRow extends RAFEntityBase implements ILookupRow {
export class IContactDto {
    UID?: string;
    // Id?: number;
    FirstName?: string;
    LastName?: string;

    EmailInfoPersonal?: string;
    EmailInfoWork?: string;
    PhonePersonal?: string;
    PhoneWork?: string;
    PhoneHome?: string;
    PhoneTollFree?: string;
    PhoneFax?: string;

    EmailOptedout?: boolean;
    LinkedIn?: string;
    Description?: string;

    AddressLine1?: string;
    AddressLine2?: string;
    AddressPostalCode?: string;
    AddressCityUID?: string;
    AddressCity?: string;
    AddressStateUID?: string;
    AddressState?: string;
    AddressCountryUID?: string;
    AddressCountry?: string;

    Designation?: string;
    AccountUID?: string;
    Account?: string;
    Type?: string
    ContactName?: string;

    BusinessProcessUID?: string;
    BusinessProcess?: string;
    ProcessStatus?: string;

    LastActivityDate?: Date;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    ContactId?: string;
    ExtraInfoJson?: any;
    TagsListJson?: string[];
    Portal?:string;
    PortalUID?:string;
    BpStep?:string;
    BpStepUID?:string;
}
export class IContactRow {
    UID?: string;
   // Id?: number;
    FirstName?: string;
    LastName?: string;

    EmailInfoPersonal?: string;
    EmailInfoWork?: string;
    PhonePersonal?: string;
    PhoneWork?: string;
    PhoneHome?: string;
    PhoneTollFree?: string;
    PhoneFax?: string;

    EmailOptedout?: boolean;
    LinkedIn?: string;
    Description?: string;

    AddressLine1?: string;
    AddressLine2?: string;
    AddressPostalCode?: string;
    AddressCityUID?: string;
    AddressCity?: string;
    AddressStateUID?: string;
    AddressState?: string;
    AddressCountryUID?: string;
    AddressCountry?: string;

    Designation?: string;
    AccountUID?: string;
    Account?: string;
    Type?: string
    ContactName?: string;

    BusinessProcessUID?: string;
    BusinessProcess?: string;
    ProcessStatus?: string;

    LastActivityDate?: Date;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    ContactId?: string;
    ExtraInfoJson?: any;
    TagsListJson?: string[];
    Portal?:string;
    PortalUID?:string;
    BpStep?:string;
    BpStepUID?:string;
}
export class ContactRow extends IContactRow implements RAFEntityBase, ILookupRow {
    PhoneList?: PhoneRow[];
    EmailList?: EmailRow[];
    AddressList?: AddressRow[];

    getClassName?() {//use entityname
        return 'contact';
    }

    getIdField?(): string {
        return propertyOf<ContactRow>("UID");
    }
    getListUrl?(): string {//use route name(entity displayname)
        return "Contact/List";
    }
    getSaveUrl?(): string {//use route name(entity displayname)
        return "Contact/Save";
    }
    getLookupUrl(): string {//use route name(entity displayname)
        return "Contact/Lookup";
    }
    getGroupByUrl?(): string {
        return "Account/GroupBy";
    }
    isOptionCreatable?(): boolean {
        return true;
    }
    getRelatedListUrl?(): string {//use route name(entity displayname)
        return "Contact/RelatedList";
    }
}
