import { propertyOf } from '../../../RAFComponents/helpers/utils';
import { RAFEntityBase } from '../../../RAFComponents/models/Common/RAFEntityBase';
import { ILookupRow } from '../../../RAFComponents/models/CompositeTypes/ILookupRow';

//export class DealRow extends RAFEntityBase {
export class IDealRow {
    //Id?: number;
    UID?: string;
    DealName?: string;
    DealDate?: Date;
    AccountUID?: string;
    Account?: string;
    ContactUID?: string;
    Contact?: string;
    StageUID?: string;
    Stage?: string;
    Status?: string;
    EstimateClosingDate?: Date;
    ActualClosingDate?: Date;
    Probability?: number;
    DealValue?: number;
    Weighted?: string;
    DealTypeUID?: string;
    DealType?: string;
    BusinessProcessUID?: string;
    BusinessProcess?: string;
    ProcessStatus?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    IsDeleted?: boolean;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;
    DealId?: string;
    ExtraInfoJson?: any;
    TagsListJson?: string[];
    Portal?:string;
    PortalUID?:string;
    BpStep?:string;
    BpStepUID?:string;
}
export class DealRow extends IDealRow implements RAFEntityBase, ILookupRow  {
    getClassName?() {//use entityname
        return 'deal';
    }
    getIdField?(): string {
        return propertyOf<DealRow>("UID");
    }
    getListUrl?(): string {//use route name(entity displayname)
        return "Deal/List";
    }
    getSaveUrl?(): string {//use route name(entity displayname)
        return "Deal/Save";
    }
    getGroupByUrl?(): string {
        return "Deal/GroupBy";
    }
    getLookupUrl(): string {//use route name(entity displayname)
        return "Deal/Lookup";
    }
    isOptionCreatable?(): boolean {
        return false;
    }
    getRelatedListUrl?(): string {//use route name(entity displayname)
        return "Deal/RelatedList";
    }
}
